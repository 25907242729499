import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';

@Component({
  selector: 'card-title',
  templateUrl: './card-title.component.html',
  styleUrls: ['./card-title.component.scss']
})
export class CardTitleComponent implements OnInit {
  @Input() name: string;
  @Input() imageUri: string;
  @Input() type: string;
  constructor(translate: TranslateService, private readonly languageTranslationService: LanguageTranslationService) {
    translate.use(this.languageTranslationService.getSupportedLanguage());
  }

  ngOnInit() {}
}
