<mat-card class="card-body" appearance="outlined">
  <mat-card-title class="card-header">{{ 'bb.frequentlyAskedQuestions.label' | translate }}</mat-card-title>
  <mat-accordion multi="true">
    <mat-expansion-panel [expanded]="false" *ngFor="let faq of frequentlyAskedQuestionsList; let slno = index">
      <mat-expansion-panel-header class="question-label"> {{ slno + 1 }}. {{ faq.question }}</mat-expansion-panel-header>
      <mat-panel-description class="answer-content">
        {{ faq.answer }}
      </mat-panel-description>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>
