import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
// Translate Modules
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
// import { MatCarouselModule } from 'ng-mat-carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LumpsumDashboardComponent } from './lumpsum-dashboard.component';
import { LumpSumDashboardRoutingModule } from './lumpsum-dashboard-routing.module';
import { SharedDashboardModule } from 'src/app/core/shared/shared-dashboard.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatSliderModule } from '@angular/material/slider';
import { MyTasksLumpsumComponent } from './components/my-tasks-lumpsum/my-tasks-lumpsum.component';
import { WelcomeContentComponent } from './components/welcome-content/welcome-content.component';
import { RepaymentAgreementComponent } from './components/repayment-agreement/repayment-agreement.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BankInfoComponent } from './components/bank-info/bank-info.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';

import { FrequentlyAskedQuestionsComponent } from './components/frequently-asked-questions/frequently-asked-questions.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { ConsultantInfoModalComponent } from './components/consultant-info-modal/consultant-info-modal.component';
import { CdkStepper, CdkStepperModule, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PayThroughBankHelpDialogComponent } from './components/pay-through-bank-help-dialog/pay-through-bank-help-dialog.component';
import { LumpsumRequestCompletionComponent } from './components/lumpsum-request-completion/lumpsum-request-completion.component';
import { BankAccountsInfoComponent } from './components/bank-accounts-info/bank-accounts-info.component';
import { CancelAddBankModalComponent } from './components/cancel-add-bank-modal/cancel-add-bank-modal.component';

@NgModule({
  declarations: [
    LumpsumDashboardComponent,
    MyTasksLumpsumComponent,
    WelcomeContentComponent,
    RepaymentAgreementComponent,
    BankInfoComponent,
    FrequentlyAskedQuestionsComponent,
    ConsultantInfoModalComponent,
    PayThroughBankHelpDialogComponent,
    LumpsumRequestCompletionComponent,
    BankAccountsInfoComponent,
    CancelAddBankModalComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    FlexLayoutModule,
    MatDialogModule,
    MatDatepickerModule,
    MatSelectModule,
    TranslateModule,
    NgxSpinnerModule,
    CarouselModule,
    LumpSumDashboardRoutingModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatSliderModule,
    MatTabsModule,
    MatCheckboxModule,
    SharedDashboardModule, // Common components for both BB and lumpsum dashboard are imported here
    ReactiveFormsModule,
    MatExpansionModule,
    MatStepperModule,
    MatSlideToggleModule,
    CdkStepperModule
  ],
  providers: [
    CdkStepper,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class LumpsumDashboardModule {}
