import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { BankInfoService } from 'src/app/public/lumpsum-dashboard/services/bank-info.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-bank-accounts-info',
  templateUrl: './bank-accounts-info.component.html',
  styleUrls: ['./bank-accounts-info.component.scss']
})
export class BankAccountsInfoComponent implements OnInit, OnChanges {
  needAssessmentSubscription: Subscription;
  bankAccounts: any[] = [];
  orderRequestId: any;
  browserLanguage: string;
  @Input() markedPrimary = false;
  @Output() openAccount: any = new EventEmitter();
  selectedAccount: any = {};

  constructor(
    private readonly needsAssessmentShared: NeedsAssessmentSharedService,
    public translate: TranslateService,
    private bankInfoService: BankInfoService,
    private languageTranslationService: LanguageTranslationService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: MatSnackBar
  ) {
    this.browserLanguage = this.languageTranslationService.getSupportedLanguage();
    translate.use(this.browserLanguage);
  }

  ngOnChanges(): void {
    if (this.markedPrimary) {
      this.getBankingInfo();
    }
  }

  ngOnInit(): void {
    this.needAssessmentSubscription = this.needsAssessmentShared.transfereeNeedsAssessmentDetails.subscribe(data => {
      if (!data) {
        return;
      }
      this.orderRequestId = data.orderRequestId;
      this.getBankingInfo();
    });
  }

  getBankingInfo() {
    this.spinner.show();
    return this.bankInfoService.getBankAccounts(this.orderRequestId).subscribe(res => {
      this.spinner.hide();
      if (res) {
        this.bankAccounts = res.body;
        if (this.bankAccounts.length) {
          if (this.bankAccounts.length > 1) {
            this.bankAccounts.sort((a, b) => {
              return a.isPreferred === b.isPreferred ? 0 : a.isPreferred ? -1 : 1;
            });
            this.bankAccounts.forEach(account => {
              if (account?.accountNumber.length > 4)
                account['maskedAccountNumber'] =
                  'X'.repeat(account?.accountNumber.length - 4) + account?.accountNumber.slice(account?.accountNumber.length - 4);
              else
                account['maskedAccountNumber'] =
                  'X'.repeat(account?.accountNumber.length) + account?.accountNumber.slice(account?.accountNumber.length);
            });
            // update selectedAccount
            this.selectedAccount = this.bankAccounts.find(x => x.isPreferred === true);
            if (this.selectedAccount) this.openAccount.emit(this.selectedAccount);
          } else {
            const account = this.bankAccounts[0];
            account['isPreferred'] = true;
            account['maskedAccountNumber'] =
              'X'.repeat(account?.accountNumber.length - 4) + account?.accountNumber.slice(account?.accountNumber.length - 4);
            this.bankAccounts[0] = account;
            // update selectedAccount
            this.selectedAccount = this.bankAccounts[0];
            this.openAccount.emit(this.selectedAccount);
          }
        }
      } else {
        this.openToastMessage('Error in fetching bank accounts.');
        return;
      }
    });
  }

  openToastMessage(toastMsg) {
    this.toastr.open(toastMsg, '', {
      duration: 5000
    });
  }

  openAccountInfo(accData) {
    this.selectedAccount = accData;
    this.openAccount.emit(accData);
  }
}
