<div *ngIf="!showAccInfo" class="accounts-section">
  <app-bank-accounts-info [markedPrimary]="markedPrimary" (openAccount)="openAccountInfo($event)"></app-bank-accounts-info>
</div>
<div class="add-edit-bank-account" *ngIf="showAccInfo || editAccInfo" style="padding: 30px;">
  <div class="bankInfo" *ngIf="!editAccInfo">
    <img class="alert-icon" mat-alert-icon src="../assets/images/alert-triangle.svg" alt="alert" />
    Before starting, please have all your bank information readily available. You must complete all required information in order to save.
    For various compliance reasons, please be advised Cartus cannot make payments directly to credit cards.
  </div>
  <mat-vertical-stepper class="bank-info-stepper" [linear]="isLinear" #stepper>
    <mat-step [stepControl]="bankInfoFormGroup" #findBankStep>
      <ng-template matStepLabel>Bank Details</ng-template>
      <form [formGroup]="bankInfoFormGroup">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="bank-details">
          <mat-form-field fxFlex="50%">
            <mat-label>Country of Bank</mat-label>
            <mat-select
              formControlName="country"
              [(value)]="bankInfoFormGroup.get('country').value"
              (selectionChange)="getRoutingDetails('bankInfo')"
            >
              <mat-option *ngFor="let country of countryList" [value]="country" [disabled]="readOnlyBankForm">
                {{ country }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="50%">
            <mat-label>Currency</mat-label>
            <mat-select
              formControlName="currency"
              [(value)]="bankInfoFormGroup.get('currency').value"
              (selectionChange)="getRoutingDetails('bankInfo')"
            >
              <mat-option *ngFor="let currency of currencyList" [value]="currency" [disabled]="readOnlyBankForm"
                >{{ currency }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="bank-details">
          <mat-form-field fxFlex="50%" *ngIf="showRouting" [hideRequiredMarker]="hideRequiredMarker">
            <mat-label>{{ routingNumberLabel }}</mat-label>
            <input
              matInput
              type="text"
              formControlName="routingNumber"
              [readonly]="readOnlyBankForm"
              (change)="onFormfieldsChange($event.target.value, routingNumberLabel)"
              (keyup)="onFormfieldsChange($event.target.value, routingNumberLabel)"
            />
            <mat-error>{{ errorMessage(routingNumberLabel, 'routingNumber', 'bankInfo') }}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50%" *ngIf="showSwift" [hideRequiredMarker]="hideRequiredMarker">
            <mat-label>{{ swiftBicCodeLabel }}</mat-label>
            <input
              matInput
              type="text"
              formControlName="swiftBicCode"
              [readonly]="readOnlyBankForm"
              (change)="onFormfieldsChange($event.target.value, swiftBicCodeLabel)"
              (keyup)="onFormfieldsChange($event.target.value, swiftBicCodeLabel)"
            />
            <mat-error>{{ errorMessage(swiftBicCodeLabel, 'swiftBicCode', 'bankInfo') }}</mat-error>
          </mat-form-field>
        </div>
        <div class="search-btn">
          <span class="error-msg" *ngIf="inValidBank">
            <img class="alert-icon" mat-alert-icon src="../assets/images/error-icon.svg" alt="alert" />
            Bank Details not found as per the information provided. Please check the information entered; if correct, please contact the
            Customer Banking Team at 1-800-965-5126 or 1-203-205-6007 for further assistance.
          </span>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" fxLayoutAlign="end end">
          <button
            class="mat-button outlined-button primary stepper-btn"
            type="button"
            *ngIf="searching"
            [disabled]="bankInfoFormGroup.invalid || invalidSearch"
            (click)="getBankDetails('bankInfo')"
          >
            Search
          </button>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="bank-details">
          <mat-card appearance="outlined" fxFlex="100%" fxFlex.xs="100%" *ngIf="bankingDetails?.bankLocation" class="bankSummary">
            <mat-card-content>
              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" style="margin: 10px 0 !important;">
                <div fxFlex="50%">
                  <strong>Bank Name:</strong>
                  {{ bankingDetails.bankLocation.bankName }}
                </div>
                <div fxFlex="50%">
                  <strong>Bank Address:</strong>
                  {{ bankingDetails.bankLocation.addressLine1 }}
                  {{ bankingDetails.bankLocation.city }}
                  {{ bankingDetails.bankLocation.state }}
                  {{ bankingDetails.bankLocation.country }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayout.lt-md="column"
                fxLayoutGap="15px"
                fxLayoutGap.lt-md="0px"
                *ngIf="bankingDetails?.foundSwift"
                style="margin: 10px 0 !important;"
              >
                <div fxFlex="50%">
                  <strong>Swift/BIC Code:</strong>
                  {{ bankingDetails.foundSwift }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayout.lt-md="column"
                fxLayoutGap="15px"
                fxLayoutGap.lt-md="0px"
                style="margin: 10px 0 !important;"
                *ngIf="!findBankStep.completed && showAccInfo"
              >
                <span class="bankInfo bank-verify-alert">
                  <img class="alert-icon" mat-alert-icon src="../assets/images/alert-triangle.svg" alt="alert" />
                  Please verify Bank Information above. If incorrect, clear criteria to search again, if correct please Continue.
                </span>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div
          fxLayout="row"
          fxLayout.lt-md="row"
          fxLayoutGap="15px"
          fxLayoutGap.lt-md="0px"
          fxLayoutAlign="end end"
          *ngIf="showBankFormActionButtons"
        >
          <button *ngIf="showBankFormClear" class="mat-button stepper-btn outlined-button reset" type="button" (click)="resetBankDetails()">
            Clear
          </button>
          <button
            class="mat-button stepper-btn outlined-button continue"
            type="button"
            matStepperNext
            (click)="confirmBankInformation()"
            [disabled]="!bankingDetails?.bankLocation"
          >
            Continue
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="accountInfoFormGroup">
      <ng-template matStepLabel>Account Details</ng-template>
      <form [formGroup]="accountInfoFormGroup" *ngIf="isBankStepComplete" (change)="onSelectionChangeAccForm($event)">
        <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="bank-details">
          <div fxFlex="100%" fxFlex.xs="100%">
            <mat-form-field fxFlex="95%" fxLayoutGap="5px">
              <mat-label>Account Number/IBAN</mat-label>
              <input
                matInput
                [ngClass]="isAccNoMasked ? 'key' : ''"
                type="text"
                formControlName="accountNumber"
                maxLength="50"
                (focusout)="validateAccountNumber('accInfo')"
                (cut)="$event.preventDefault()"
                (copy)="$event.preventDefault()"
                (paste)="$event.preventDefault()"
                [readonly]="editAccInfo"
              />
              <mat-error>{{ errorMessage('Account Number/IBAN', 'accountNumber', 'accountInfo') }}</mat-error>
            </mat-form-field>
            <div fxFlex="5%">
              <img
                class="eye-icon"
                *ngIf="!isAccNoMasked && (accountInfoFormGroup?.controls)['accountNumber']?.value && !editAccInfo"
                (click)="maskAccountNumber('isAccNoMasked')"
                mat-eye-icon
                src="../assets/images/eye-off.svg"
                alt="hide text"
              />
              <img
                class="eye-icon"
                *ngIf="isAccNoMasked && (accountInfoFormGroup?.controls)['accountNumber']?.value && !editAccInfo"
                (click)="unmaskAccountNumber('isAccNoMasked')"
                mat-eye-icon
                src="../assets/images/eye-on.svg"
                alt="show text"
              />
            </div>
          </div>

          <div fxFlex="100%" fxFlex.xs="100%">
            <mat-form-field fxFlex="95%" fxLayoutGap="5px">
              <mat-label>Re-Enter Account Number/IBAN</mat-label>
              <input
                matInput
                [ngClass]="isMasked ? 'key' : ''"
                type="text"
                formControlName="matchAccountNumber"
                maxLength="50"
                (focusout)="verifyAccountNumber()"
                (cut)="$event.preventDefault()"
                (copy)="$event.preventDefault()"
                (paste)="$event.preventDefault()"
                [readonly]="editAccInfo"
              />
              <mat-error> {{ errorMessage('Re-Enter Account Number/IBAN', 'matchAccountNumber', 'accountInfo') }}</mat-error>
            </mat-form-field>
            <div fxFlex="5%">
              <img
                class="eye-icon"
                *ngIf="!isMasked && (accountInfoFormGroup?.controls)['matchAccountNumber']?.value && !editAccInfo"
                (click)="maskAccountNumber('isMasked')"
                mat-eye-icon
                src="../assets/images/eye-off.svg"
                alt="hide text"
              />
              <img
                class="eye-icon"
                *ngIf="isMasked && (accountInfoFormGroup?.controls)['matchAccountNumber']?.value && !editAccInfo"
                (click)="unmaskAccountNumber('isMasked')"
                mat-eye-icon
                mat-alert-icon
                src="../assets/images/eye-on.svg"
                alt="show text"
              />
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="bank-details">
          <mat-form-field fxFlex="50%">
            <mat-label>Account Type</mat-label>
            <mat-select
              class="{{ isFirefox ? 'acc-type-f' : 'acc-type-c' }}"
              formControlName="accountType"
              [(value)]="accountInfoFormGroup.get('accountType').value"
              (selectionChange)="onSelectionChangeAccForm($event)"
              (selectionChange)="getAccountType()"
            >
              <mat-option *ngFor="let account of accountTypeList" [value]="account" [disabled]="editAccInfo">
                {{ account }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="50%">
            <mat-label>Account Holder Name</mat-label>
            <input matInput type="text" formControlName="accountHolderName" [readonly]="editAccInfo" />
            <mat-error>{{ errorMessage('Account Holder Name', 'accountHolderName', 'accountInfo') }}</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="bank-details">
          <mat-form-field fxFlex="50%" *ngIf="showPanTanFields">
            <mat-label>PAN</mat-label>
            <input
              matInput
              type="text"
              formControlName="panNumber"
              maxLength="10"
              minLength="10"
              (cut)="$event.preventDefault()"
              (copy)="$event.preventDefault()"
              (paste)="$event.preventDefault()"
              [readonly]="editAccInfo"
            />
            <mat-error>{{ errorMessage('PAN', 'panNumber', 'accountInfo') }}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50%" *ngIf="showPanTanFields">
            <mat-label>TAN</mat-label>
            <input
              matInput
              type="text"
              formControlName="tanNumber"
              maxLength="10"
              minLength="10"
              (cut)="$event.preventDefault()"
              (copy)="$event.preventDefault()"
              (paste)="$event.preventDefault()"
              [readonly]="editAccInfo"
            />
            <mat-error> {{ errorMessage('TAN', 'tanNumber', 'accountInfo') }}</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="bank-details">
          <mat-form-field fxFlex="50%" *ngIf="showFederalTaxId" [readonly]="editAccInfo">
            <mat-label>Tax ID Number</mat-label>
            <input
              matInput
              type="text"
              formControlName="federalTaxNumber"
              minLength="11"
              maxLength="11"
              (cut)="$event.preventDefault()"
              (copy)="$event.preventDefault()"
              (paste)="$event.preventDefault()"
              [readonly]="editAccInfo"
            />
            <mat-error>{{ errorMessage('Tax ID Number', 'federalTaxNumber', 'accountInfo') }}</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="bank-details">
          <mat-form-field fxFlex="100%" *ngIf="showBankInstruction">
            <mat-label>Instruction to Bank</mat-label>
            <textarea
              matInput
              type="text"
              formControlName="bankInstruction"
              maxLength="230"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="10"
              [readonly]="editAccInfo"
            ></textarea>
            <mat-error>{{ errorMessage('Instruction to Bank', 'bankInstruction', 'accountInfo') }}</mat-error>
          </mat-form-field>
        </div>
      </form>
      <div *ngIf="isBankStepComplete" fxLayout="row" class="user-ack-section">
        <mat-checkbox [checked]="acknowledged" (change)="ackCreditCardComplaince($event)" [disabled]="editAccInfo"></mat-checkbox>
        <p class="compliance-reminder">
          {{ 'bb.lumpsumDashboard.addBank.creditCardComplianceReminder' | translate }}
        </p>
      </div>
    </mat-step>
    <mat-step [stepControl]="payThroughBankFormGroup">
      <ng-template matStepLabel>Pay Through Details</ng-template>
      <div class="pay-through-label" *ngIf="isAccInfoStepComplete || editAccInfo">
        Pay Through Bank Needed ?
        <img (click)="openPtbHelpDialog()" class="help-icon" mat-alert-icon src="../assets/images/Help.svg" alt="help" />
        <mat-slide-toggle
          (change)="onPayThoughBankChange($event.checked)"
          [checked]="isPayThroughBankNeeded"
          [disabled]="(bankingDetails?.payThroughReq && isPayThroughBankNeeded && showPtbSummary) || editAccInfo"
        ></mat-slide-toggle>
      </div>
      <form [formGroup]="payThroughBankFormGroup" *ngIf="isAccInfoStepComplete && showPayThorughForm">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="bank-details">
          <mat-form-field fxFlex="50%">
            <mat-label>Country of Bank</mat-label>
            <mat-select
              formControlName="payThroughBankCountry"
              [(value)]="payThroughBankFormGroup.get('payThroughBankCountry').value"
              (selectionChange)="getRoutingDetails('payThrough')"
            >
              <mat-option *ngFor="let country of countryList" [value]="country" [disabled]="readOnlyPTBForm">
                {{ country }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="bank-details">
          <mat-form-field fxFlex="50%" *ngIf="showRoutingPTB" [hideRequiredMarker]="hideRequiredMarkerPTB">
            <mat-label>{{ payThroughRoutingNoLabel }}</mat-label>
            <input
              matInput
              type="text"
              formControlName="payThroughBankRoutingNumber"
              [readonly]="readOnlyPTBForm"
              [(value)]="payThroughBankFormGroup.get('payThroughBankRoutingNumber').value"
              (change)="onPayThroughFormChanges($event.target.value)"
              (keyup)="onPayThroughFormChanges($event.target.value)"
            />
            <mat-error>{{ errorMessage(payThroughRoutingNoLabel, 'payThroughBankRoutingNumber', 'payThrough') }}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50%" *ngIf="showSwiftPTB" [hideRequiredMarker]="hideRequiredMarkerPTB">
            <mat-label>{{ payThroughSwiftBicCodeLabel }}</mat-label>
            <input
              matInput
              type="text"
              formControlName="payThroughBankSwiftBicCode"
              [readonly]="readOnlyPTBForm"
              [(value)]="payThroughBankFormGroup.get('payThroughBankSwiftBicCode').value"
              (change)="onPayThroughFormChanges($event.target.value)"
              (keyup)="onPayThroughFormChanges($event.target.value)"
            />
            <mat-error>{{ errorMessage(payThroughSwiftBicCodeLabel, 'payThroughBankSwiftBicCode', 'payThrough') }}</mat-error>
          </mat-form-field>
        </div>
        <div class="search-btn">
          <span class="error-msg" *ngIf="inValidPayThroughBank">
            <img class="alert-icon" mat-alert-icon src="../assets/images/error-icon.svg" alt="alert" />
            Bank Details not found as per the information provided. Please check the information entered; if correct, please contact the
            Customer Banking Team at 1-800-965-5126 or 1-203-205-6007 for further assistance.
          </span>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" fxLayoutAlign="end end">
          <button
            class="mat-button outlined-button primary stepper-btn"
            type="button"
            *ngIf="searchingPTB"
            [disabled]="payThroughBankFormGroup.invalid || invalidPayThroughSearch"
            (click)="getBankDetails('payThrough')"
          >
            Search
          </button>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" class="bank-details">
          <mat-card appearance="outlined" fxFlex="100%" fxFlex.xs="100%" *ngIf="showPtbSummary" class="bankSummary">
            <mat-card-content>
              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0px" style="margin: 10px 0 !important;">
                <div fxFlex="50%">
                  <strong>Bank Name:</strong>
                  {{ payThroughLoc.bankName }}
                </div>
                <div fxFlex="50%">
                  <strong>Bank Address:</strong>
                  {{ payThroughLoc.addressLine1 }}
                  {{ payThroughLoc.city }}
                  {{ payThroughLoc.state }}
                  {{ payThroughLoc.country }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayout.lt-md="column"
                fxLayoutGap="15px"
                fxLayoutGap.lt-md="0px"
                *ngIf="payThrswiftBicCode"
                style="margin: 10px 0 !important;"
              >
                <div fxFlex="50%">
                  <strong>Swift/BIC Code:</strong>
                  {{ payThrswiftBicCode }}
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayout.lt-md="column"
                fxLayoutGap="15px"
                fxLayoutGap.lt-md="0px"
                style="margin: 10px 0 !important;"
                *ngIf="showAlertMessage"
              >
                <span class="bankInfo bank-verify-alert">
                  <img class="alert-icon" mat-alert-icon src="../assets/images/alert-triangle.svg" alt="alert" />
                  Please verify Bank Information above. If incorrect, clear criteria to search again, if correct please Continue.
                </span>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div
          fxLayout="row"
          fxLayout.lt-md="row"
          fxLayoutGap="15px"
          fxLayoutGap.lt-md="0px"
          fxLayoutAlign="end end"
          *ngIf="showPTBFormActionButtons"
        >
          <button class="mat-button stepper-btn outlined-button reset" type="button" (click)="resetPayThroughDetails()">
            Clear
          </button>
          <button class="mat-button stepper-btn outlined-button continue" type="button" matStepperNext (click)="confirmPTBInfo()">
            Continue
          </button>
        </div>
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutGap="15px"
          fxLayoutGap.lt-md="0px"
          class="bank-details"
          *ngIf="showPTBAccDetails || editAccInfo"
        >
          <div fxFlex="100%" fxFlex.xs="100%">
            <mat-form-field fxFlex="95%" fxLayoutGap="5px" [hideRequiredMarker]="true">
              <mat-label>Account Number/IBAN</mat-label>
              <input
                matInput
                [ngClass]="isPayThrAccNoMasked ? 'key' : ''"
                type="text"
                formControlName="payThroughBankAccNumber"
                maxLength="50"
                (focusout)="validateAccountNumber('payThrough')"
                (cut)="$event.preventDefault()"
                (copy)="$event.preventDefault()"
                (paste)="$event.preventDefault()"
                [readonly]="editAccInfo"
              />
              <mat-error>{{ errorMessage('Account Number/IBAN', 'payThroughBankAccNumber', 'payThrough') }}</mat-error>
            </mat-form-field>
            <div fxFlex="5%">
              <img
                class="eye-icon"
                *ngIf="!isPayThrAccNoMasked && (payThroughBankFormGroup?.controls)['payThroughBankAccNumber']?.value && !editAccInfo"
                (click)="maskPayThroughAccNo('isPayThrAccNoMasked')"
                mat-eye-icon
                src="../assets/images/eye-off.svg"
                alt="hide text"
              />
              <img
                class="eye-icon"
                *ngIf="isPayThrAccNoMasked && (payThroughBankFormGroup?.controls)['payThroughBankAccNumber']?.value && !editAccInfo"
                (click)="unmaskPayThroughAccNo('isPayThrAccNoMasked')"
                mat-eye-icon
                src="../assets/images/eye-on.svg"
                alt="show text"
              />
            </div>
          </div>
          <div fxFlex="100%" fxFlex.xs="100%">
            <mat-form-field fxFlex="95%" fxLayoutGap="5px" [hideRequiredMarker]="true">
              <mat-label>Re-Enter Account Number/IBAN</mat-label>
              <input
                matInput
                [ngClass]="isPayThrReAccMasked ? 'key' : ''"
                type="text"
                formControlName="payThroughBankReEnterAccNumber"
                maxLength="50"
                (focusout)="verifyPayThroughAccNumber()"
                (cut)="$event.preventDefault()"
                (copy)="$event.preventDefault()"
                (paste)="$event.preventDefault()"
                [readonly]="editAccInfo"
              />
              <mat-error> {{ errorMessage('Re-Enter Account Number/IBAN', 'payThroughBankReEnterAccNumber', 'payThrough') }}</mat-error>
            </mat-form-field>
            <div fxFlex="5%">
              <img
                class="eye-icon"
                *ngIf="!isPayThrReAccMasked && (payThroughBankFormGroup?.controls)['payThroughBankReEnterAccNumber']?.value && !editAccInfo"
                (click)="maskPayThroughAccNo('isPayThrReAccMasked')"
                mat-eye-icon
                src="../assets/images/eye-off.svg"
                alt="hide text"
              />
              <img
                class="eye-icon"
                *ngIf="isPayThrReAccMasked && (payThroughBankFormGroup?.controls)['payThroughBankReEnterAccNumber']?.value && !editAccInfo"
                (click)="unmaskPayThroughAccNo('isPayThrReAccMasked')"
                mat-eye-icon
                mat-alert-icon
                src="../assets/images/eye-on.svg"
                alt="show text"
              />
            </div>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-vertical-stepper>
  <div
    *ngIf="!editAccInfo"
    class="add-bank-info-action-buttons"
    fxLayout="row"
    fxLayout.xs="row"
    fxLayout.lt-md="row"
    fxLayoutGap="15px"
    fxLayoutGap.lt-md="0px"
    fxLayoutAlign="end end"
  >
    <button class="mat-button stepper-btn outlined-button reset" type="button" (click)="discardAddbankAccount()">
      Cancel
    </button>
    <button
      class="mat-button stepper-btn outlined-button continue"
      type="button"
      [disabled]="
        disableSave ||
        verifyPayThroughBank ||
        !bankingDetails?.bankLocation ||
        !accountInfoFormGroup.valid ||
        (isPayThroughBankNeeded && !payThroughBankFormGroup.valid)
      "
      (click)="addBankAccount()"
    >
      Save
    </button>
  </div>
  <div
    *ngIf="editAccInfo"
    class="add-bank-info-action-buttons"
    fxLayout="row"
    fxLayout.lt-md="row"
    fxLayoutGap="15px"
    fxLayoutGap.lt-md="0px"
    fxLayoutAlign="end end"
  >
    <button
      *ngIf="!openedAccount?.isPreferred"
      class="mat-button stepper-btn outlined-button continue"
      type="button"
      (click)="markAsPrimary()"
    >
      Use as Primary
    </button>
  </div>
</div>
