<div class="" style="padding: 30px;">
  <mat-card appearance="outlined" class="bank-accounts-container card-body" style="border:1px solid #F2F2F2; border-radius:12px;">
    <mat-card-title class="header-accounts">
      <img src="../../../../assets/images/credit-card.svg" alt="credit-card" role="img" />
      <span class="accounts-title">Bank Accounts</span>
    </mat-card-title>
    <mat-card-content class="card-content" fxLayoutGap="10px" fxLayoutGap.sm="0px" fxLayoutGap.xs="0px">
      <div class="account-info" fxFlex="100%" fxLayoutAlign="start" fxLayoutGap="15px" *ngIf="bankAccounts.length">
        <mat-card
          appearance="outlined"
          class="account-card"
          fxFlex="40%"
          fxFlex.xs="100%"
          [ngClass]="account === selectedAccount ? 'selected-account' : ''"
          *ngFor="let account of bankAccounts; let index"
          (click)="openAccountInfo(index)"
        >
          <mat-card-content>
            <p class="primary-chip-container" *ngIf="account?.isPreferred">
              <span class="primary-chip">Primary</span>
            </p>
            <p>{{ account?.maskedAccountNumber }}</p>
            <p>{{ account?.bankName }}</p>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="account-info" fxFlex="100%" fxLayoutAlign="start" fxLayoutGap="15px" *ngIf="!bankAccounts.length">
        <p>
          {{ 'bb.lumpsumDashboard.addBank.addBankAccount' | translate }}
        </p>
      </div>
    </mat-card-content>
  </mat-card>
</div>
