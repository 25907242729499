import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';

@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: ['./frequently-asked-questions.component.scss']
})
export class FrequentlyAskedQuestionsComponent implements OnInit {
  frequentlyAskedQuestionsList = [];
  browserLanguage: string;
  constructor(private readonly languageTranslationService: LanguageTranslationService, private translate: TranslateService) {
    this.browserLanguage = this.languageTranslationService.getSupportedLanguage();
    this.translate.use(this.browserLanguage);
  }

  ngOnInit(): void {
    /* predefined frequently asked questions list */
    this.frequentlyAskedQuestionsList = [
      {
        question: this.translate.instant('bb.frequentlyAskedQuestions.question1'),
        answer: this.translate.instant('bb.frequentlyAskedQuestions.answer1')
      },
      {
        question: this.translate.instant('bb.frequentlyAskedQuestions.question2'),
        answer: this.translate.instant('bb.frequentlyAskedQuestions.answer2')
      },
      {
        question: this.translate.instant('bb.frequentlyAskedQuestions.question3'),
        answer: this.translate.instant('bb.frequentlyAskedQuestions.answer3')
      },
      {
        question: this.translate.instant('bb.frequentlyAskedQuestions.question4'),
        answer: this.translate.instant('bb.frequentlyAskedQuestions.answer4')
      },
      {
        question: this.translate.instant('bb.frequentlyAskedQuestions.question5'),
        answer: this.translate.instant('bb.frequentlyAskedQuestions.answer5')
      },
      {
        question: this.translate.instant('bb.frequentlyAskedQuestions.question6'),
        answer: this.translate.instant('bb.frequentlyAskedQuestions.answer6')
      },
      {
        question: this.translate.instant('bb.frequentlyAskedQuestions.question7'),
        answer: this.translate.instant('bb.frequentlyAskedQuestions.answer7')
      }
    ];
  }
}
