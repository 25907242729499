<div class="overlay" (click)="onOverlayClicked($event)">
  <div class="dialog" (click)="onDialogClicked($event)" fxLayout="row" fxLayoutAlign="center center">
    <img
      [src]="dialog.notificationType === 'success' ? '../../../../../assets/images/check-circle.svg' : ''"
      class="dialog-img"
      alt="check-circle"
      fxFlex="20%"
    />
    <span fxFlex="70%">{{ dialog.content }}</span>
  </div>
</div>
