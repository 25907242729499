import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PolicyModalComponent } from '../../../../public/dashboard/components/policy-modal/policy-modal.component';
import { DashboardService } from '../../../../public/dashboard/services/dashboard.service.service';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { take } from 'rxjs/operators';
import { TasksInfoService } from 'src/app/core/services/tasks-info.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';

@Component({
  selector: 'app-cartusonline-card',
  templateUrl: './cartusonline.component.html',
  styleUrls: ['./cartusonline.component.scss']
})
export class CartusOnlineComponent implements OnInit {
  @Input() taskObj = {};
  private requestSub: Subscription;
  hasCustFileId = false;
  viewType: 'consultant' | 'family' = 'consultant';
  /** Flag to check if logged in user is consultant or transferee */
  isConsultant = false;

  constructor(
    public dialog: MatDialog,
    private readonly appConfig: AppConfigService,
    private readonly dashboardService: DashboardService,
    private readonly tasksService: TasksInfoService,
    private readonly cookieService: CookieService,
    translate: TranslateService,
    private readonly languageTranslationService: LanguageTranslationService
  ) {
    translate.use(this.languageTranslationService.getSupportedLanguage());
  }

  ngOnInit() {
    this.getTasks();
    if (this.cookieService.get('transferee-context')) {
      this.isConsultant = true;
    }
  }

  /** opens the Cartus Online URL after receiving token.  URL handled via configuration */
  openCartusOnline(): void {
    const colUrl = this.appConfig.getConfig('cartusOnline').toString();
    this.requestSub = this.dashboardService
      .getColToken()
      .pipe(take(1))
      .subscribe(res => {
        this.dashboardService.windowRef = window.open(colUrl, '_blank');
      });
  }

  /**
   * Get tasks to check for the presence of custFileId to enable the GO button for Cartus Online
   */
  getTasks() {
    this.tasksService.taskInformation.subscribe(tasks => {
      if (tasks && tasks.policyCall) {
        this.hasCustFileId = tasks.policyCall.hasOwnProperty('custFileId') && tasks.policyCall.custFileId !== null;
      }
    });
  }
}
