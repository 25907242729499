import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from '../../../../core/services/language-translation.service';
import { DashboardService, TimeLine } from 'src/app/public/dashboard/services/dashboard.service.service';

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnChanges, OnDestroy {
  timelineData: TimeLine[] = [];

  @Input() orderRequestId: string = null;

  isLoading = true;

  private subscriptionList: Subscription;
  browserLanguage: string;

  constructor(
    private dashboardService: DashboardService,
    public translate: TranslateService,
    private languageTranslationService: LanguageTranslationService
  ) {
    this.browserLanguage = this.languageTranslationService.getSupportedLanguage();
    translate.use(this.browserLanguage);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.orderRequestId && changes.orderRequestId.currentValue) {
      this.loadTimeLine(changes.orderRequestId.currentValue);
    }
  }

  /**
   * Load Timeline Data for Dashboard
   */
  loadTimeLine(orderRequestId: string) {
    this.subscriptionList = this.dashboardService.timelineDetails.subscribe(data => {
      if (!data) {
        return;
        this.dashboardService.getUpdatedTimeline(orderRequestId, this.browserLanguage);
      }
      if (data && data.timeLines) {
        this.timelineData = data.timeLines;
        this.formatDateForJobStart();
        this.isLoading = false;
      }
    });
  }

  formatDateForJobStart() {
    this.timelineData.forEach((element, index) => {
      if (element.date && this.timelineData[index].date) {
        this.timelineData[index].date = element.date.split('T')[0];
        return;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscriptionList) {
      this.subscriptionList.unsubscribe();
    }
  }
}
