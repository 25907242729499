import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CronofySlotPickerComponent } from './cronofy-slot-picker.component';

@NgModule({
  declarations: [CronofySlotPickerComponent],
  exports: [CronofySlotPickerComponent],
  imports: [CommonModule]
})
export class CronofySlotPickerModule {}
