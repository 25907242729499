<div class="terms-theme">
  <div class="page-header">
    <img class="logo" src="../../../../assets/images/logos/logo_cartus.svg" alt="Cartus" />
  </div>
  <div class="main-body">
    <div class="main-content">
      <div class="heading">
        Contact Us: How May We Help You?
      </div>
      <div class="text">
        The Cartus Help Desk team can assist with any technical support issues.
      </div>
      <div class="text">
        Cartus’ Help Desk is here to help you with all of your technical needs from Sunday 8:00 p.m. through Friday 8:00 p.m. Eastern U.S.
        time.
      </div>
      <div class="text">
        For assistance with other questions or issues, please contact your Cartus Representative.
      </div>
      <div class="text no-margin">
        <strong>Contact the Cartus Help Desk team</strong>
      </div>
      <div class="text no-margin"><strong>By Phone: </strong>1.800.316.8070 (within the U.S.) or +1.203.205.1726</div>
      <div class="text">
        <strong> By Email: </strong
        ><span class="link-style"> <a href="mailto: helpdesk-cartus@cartus.com">helpdesk-cartus&#64;cartus.com</a></span>
      </div>
      <div class="text">
        Please include your name, phone number and a description of your issue.
      </div>
    </div>
    <div class="page-footer" [innerHTML]="copyright"></div>
  </div>
</div>
