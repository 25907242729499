<mat-toolbar>
  <div class="tasks-toolbar" (click)="sidenav.open()">MY TASKS</div>
</mat-toolbar>
<mat-sidenav-container class="tasks-container">
  <mat-sidenav #sidenav [opened]="true" [mode]="screenWidth > 840 ? 'side' : 'over'">
    <mat-nav-list>
      <mat-action-list>
        <a
          *ngIf="lumpsumDetails && lumpsumDetails?.repay && lumpsumDetails.repay?.taskRequired"
          class="task-menu-item"
          [ngClass]="!showContent ? 'is-active' : ''"
          mat-list-item
          (click)="openRepayAgreePage($event)"
          [routerLink]="['./repaymentAgreement']"
          [routerLinkActive]="['is-active']"
        >
          <span class="menu-bar">
            Repayment Agreement
            <img *ngIf="repayCompleted" matListItemIcon src="../assets/images/check.svg" alt="complete" />
          </span>
        </a>
        <a
          *ngIf="lumpsumDetails && lumpsumDetails?.bankTaskRequired && !isCoBrowsing"
          class="task-menu-item"
          [ngClass]="showContent ? 'is-active' : ''"
          mat-list-item
          (click)="openBankInfoPage($event)"
          [routerLink]="['./bankInfo']"
        >
          <span class="menu-bar">
            Bank Information<img *ngIf="bankInfoCompleted" matListItemIcon src="../assets/images/check.svg" alt="complete" />
          </span>
        </a>
      </mat-action-list>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="screenWidth > 840 ? '' : 'mob-sidenav-content'">
    <div fxLayout="row" fxLayout.xs="column" class="action-buttons" fxLayoutAlign="space-between">
      <div fxLayout="column">
        <p class="menu-content-label">
          <span class="menu-bar"
            ><img
              (click)="goBack()"
              *ngIf="(lumpsumDetails && lumpsumDetails?.repay) || (lumpsumDetails && lumpsumDetails?.bankTaskRequired)"
              src="../assets/images/ArrowBackFilled.svg"
              alt="back"
            />{{ headingTitle }}</span
          >
        </p>
      </div>
      <div fxLayout="column" class="p-r-20 download-section" *ngIf="router.url.includes('repaymentAgreement')">
        <button class="mat-button outlined-button download-btn" type="button" (click)="downloadRepayAgreement()">
          <span>
            <img alt="download" class="download-icon" title="Download" src="../../../../../assets/images/download/download-icon.svg" />
            Download
          </span>
        </button>
      </div>
      <div fxLayout="column" [ngClass]="screenWidth > 840 ? 'p-r-20' : 'mobile-btn-align'" *ngIf="router.url.includes('bankInfo')">
        <button [disabled]="showAccForm" class="mat-button outlined-button add-account" type="button" (click)="showBankInfo()">
          Add Account
        </button>
      </div>
    </div>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
