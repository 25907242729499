import { Component, Type, OnDestroy, AfterViewInit, ComponentRef, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogService } from './service/dialog.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements AfterViewInit, OnDestroy {
  private readonly _onClose = new Subject<any>();

  public componentRef: ComponentRef<any>;
  public childComponentType: Type<any>;
  public onClose = this._onClose.asObservable();

  // and this:
  constructor(private cd: ChangeDetectorRef, public dialog: DialogService) {}

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  onOverlayClicked(evt: MouseEvent) {
    this.dialog.removeDialogComponentFromBody();
  }

  onDialogClicked(evt: MouseEvent) {
    evt.stopPropagation();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
