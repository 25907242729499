<div class="alpha-static-theme">
  <div class="page-header">
    <img class="logo" src="../../../../assets/images/logos/BB-Logo.svg" alt="Benefits Builder Logo" />
  </div>
  <div class="main-body">
    <div class="main-content">
      <h1 class="heading">Unsupported Browser</h1>
      <p class="text">
        Please consider installing a supported browser to get the most out of the Mobilify&#8480; experience.
      </p>
      <ul class="browsers">
        <li>
          <img class="browser-icon" aria-hidden="true" src="../../../../assets/images/icon_chrome.svg" alt="Chrome browser" />
          <span class="label">Chrome</span>
        </li>
        <li>
          <img class="browser-icon" aria-hidden="true" src="../../../../assets/images/icon_firefox.svg" alt="Firefox browser" />
          <span class="label">Firefox</span>
        </li>
        <li>
          <img class="browser-icon" aria-hidden="true" src="../../../../assets/images/icon_edge.svg" alt="Edge browser" />
          <span class="label">Edge</span>
        </li>
        <li>
          <img class="browser-icon" aria-hidden="true" src="../../../../assets/images/icon_safari.png" alt="Safari browser" />
          <span class="label">Safari</span>
        </li>
      </ul>
    </div>
    <div class="page-footer">
      <div class="copyright" [innerHTML]="copyright"></div>
      <div class="footer-link">
        <span class="link"><a routerLink="/terms-of-use" target="_blank">Terms of Use</a></span>
        <span class="link"><a routerLink="/privacy-statement" target="_blank">Privacy Statement</a></span>
        <span class="link"><a routerLink="/contact-us" target="_blank">Contact Us</a></span>
      </div>
    </div>
  </div>
</div>
