<mat-card
  appearance="outlined"
  class="destination-card"
  fxFlex="100%"
  tabindex="0"
  (click)="loadDestination()"
  (keypress)="loadDestination()"
  (keyup.enter)="loadDestination()"
>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <card-title [name]="destintionCardTitle" type="destination" imageUri="explore"></card-title>
    </div>
    <!-- <button class="cartus-online-icon" tabindex="-1" role="img"></button> -->
    <img src="../../../../../assets/images/cartus-online-icon.svg" alt="cartus-online" />
  </mat-card-title>
  <mat-card-content *ngIf="destinationCardImage" class="scroll-content" fxLayout="row" fxLayoutGap="12px">
    <div fxLayout="column" fxFlex="50%" *ngFor="let content of destinationCardImage">
      <p class="title">
        {{ 'bb.destinationCard.' + content.imageUri | translate }}
      </p>
      <img src="../../../../../assets/images/{{ content.imageUri }}.png" alt="neighbour" />
    </div>
  </mat-card-content>
</mat-card>
