import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { Tasks, CalenderInvite } from '../models/tasks.model';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';

@Injectable({
  providedIn: 'root'
})
export class TasksInfoService {
  /** Behaviour subject for tasks information */
  taskInformation = new BehaviorSubject<Tasks>(null);
  // taskInfo = this.taskInformation.asObservable();

  /** Instance of BehaviorSubject to store notificationKey type*/
  notificationKey = new BehaviorSubject<string>('');
  /**
   * @param baseClientService Inject base client service
   */
  constructor(private readonly baseClientService: BaseClientService, private readonly needsAssessment: NeedsAssessmentSharedService) {
    this.getTaskInformation();
  }

  /**
   * To get Tasks information
   */
  getTasks(): Observable<Tasks> {
    return this.baseClientService.get<Tasks>(`/v1/tasks`).pipe(
      map(r => r.body),
      catchError(err => {
        console.log('Failed to get tasks', err);
        const emptyResp = null;
        return of(emptyResp);
      })
    );
  }

  /**
   * To update tasks behaviour subject with updated value
   * @param data : tasks information
   */
  updateTaskInfo(data: Tasks) {
    this.taskInformation.next(data);
  }

  /**
   * Function to Get family member information and update Shared-Service
   */
  getTaskInformation() {
    if (!this.taskInformation.getValue()) {
      this.getTasks().subscribe(data => {
        if (data) {
          this.updateTaskInfo(data);
        }
      });
    } else {
      return this.taskInformation.getValue();
    }
  }

  getCronofyToken(token: string): Observable<{ token: string }> {
    return this.baseClientService.get(`/v1/cronofy/token?id=${token}`).pipe(
      map(r => r.body),
      catchError(err => {
        console.log('Failed to get token', err);
        const emptyResp = null;
        return of(emptyResp);
      })
    );
  }

  schedulePolicyCall(start: Date, end: Date) {
    const taskInfo = this.taskInformation.getValue();
    let transferee;
    this.needsAssessment.transfereeNeedsAssessmentDetails.subscribe(res => {
      transferee = res;
    });
    const emailList = taskInfo.policyCall.consultantDetails.emailDetailsList.map(item => {
      return { email: item.emailAddress };
    });

    const findPrimaryEmail = transferee.emailDetailsList.find(item => item.primary);
    if (findPrimaryEmail === undefined) {
      emailList.push({
        email: transferee.emailDetailsList[0].emailAddress
      });
    } else {
      emailList.push({ email: findPrimaryEmail.emailAddress });
    }

    const phoneNumber = transferee.phoneDetailsList.find(phone => phone.primary);
    const calendarInvite: CalenderInvite = {
      options: {
        callback_url: 'https://example.yourapp.com/cronofy/smart_invite/notifications',
        event: {
          description: 'Benefits Builder - Policy call',
          summary: 'Benefits Builder - Policy call',
          tzid: taskInfo.policyCall.consultantDetails.timeZone,
          start: start,
          end: end,
          location: {
            description: ''
          }
        },
        organizer: {
          name: 'Benefits Builder - Policy Call'
        },
        recipients: emailList,
        smart_invite_id: `MY_POLICY_CALL_${Date.now()}`
      },
      action: 'schedule',
      orderId: transferee.orderRequestId
    };

    return this.baseClientService.post('/v1/cronofy/calendarInvite', calendarInvite).pipe(
      map(r => r.body),
      catchError(err => {
        console.log('Failed to send invite', err);
        const emptyResp = null;
        return of(emptyResp);
      })
    );
  }
}
