import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { HttpParams } from '@angular/common/http';
import { map, catchError, of } from 'rxjs';
import { urlType } from '../models/urlType';

@Injectable({
  providedIn: 'root'
})
export class UploadDocumentService {
  constructor(private readonly baseClientService: BaseClientService) {}

  /** Func */
  postDMSDetails(orderRequestId, docDetails) {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('orderRequestId', orderRequestId);
    httpParams = httpParams.append('calledFrom', 'UI');
    const baseURL = `/v1/admin/document-management/guid?` + httpParams.toString();
    return this.baseClientService.post<any>(baseURL, docDetails, urlType.integrationApi).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to get DMS file: ', err);
        return of(empty);
      })
    );
  }

  /** DMS download file related function */
  uploadDMSFile(formData) {
    const baseURL = `/v2.0/onbase/hsdocument`;
    return this.baseClientService.dmsPost(baseURL, formData).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to get DMS download file: ', err);
        return of(empty);
      })
    );
  }

  updateRepaymentDocMilestone(requestBody) {
    requestBody.Detail.appContext = sessionStorage.getItem('car-ses-con');
    return this.baseClientService.post<any>(`/v1/admin/event`, requestBody, urlType.integrationApi).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to update milestone for repayment aggrement document', err);
        return of(empty);
      })
    );
  }
}
