<mat-card appearance="outlined" class="timeline-div" [ngClass]="{ 'skeleton-box': isLoading == true }">
  <mat-card-title>
    <card-title name="Timeline" imageUri="Timeline" type="timeline" role="heading" aria-label="Timeline" aria-level="2"></card-title>
  </mat-card-title>
  <mat-card-content class="timeline-content">
    <timeline-div
      *ngFor="let item of timelineData; let last = last"
      [ngClass]="last || item.length == 1 ? 'last-div card_list_back' : 'card_list_back'"
      [timelineData]="item"
    ></timeline-div>
  </mat-card-content>
</mat-card>
