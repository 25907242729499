import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { BenefitService, Benefit /*CategoryMapping*/ } from '../../services/benefit.service';

@Component({
  selector: 'app-benefit-donut-chart',
  templateUrl: './benefit-donut-chart.component.html',
  styleUrls: ['./benefit-donut-chart.component.scss']
})
export class BenefitDonutChartComponent implements OnInit, OnDestroy {
  /*
    'Before Move': '#00EBB3',
    'Shipping': '#FFAE4E',
    'Travel & Transport': '#A564FF',
    'After Arrival': '#FFDE2B',
    'Ongoing': #27E33D
    'Allowance': '#FF6767'
  */
  oberveSubscription: Subscription;

  angleOffset = -90;
  chartData = [];
  colors = [];
  cx = 80;
  cy = 80;
  radius = 60;
  strokeWidth = 5;
  sortedValues = [];
  amount: string;

  isVisible: boolean;
  transfereeDetails: any;
  constructor(
    private benefitService: BenefitService,
    private readonly needsAssessmentService: NeedsAssessmentSharedService,
    private readonly spinner: NgxSpinnerService
  ) {
    this.transfereeDetails = this.needsAssessmentService.transfereeNeedsAssessmentDetails.getValue();
  }

  ngOnInit() {
    this.observeBenefits();
  }

  observeBenefits() {
    // this.spinner.show();
    this.oberveSubscription = this.benefitService.benefitsData.subscribe(data => {
      if (!data) {
        return;
      }
      this.colors = this.benefitService.colors;
      this.isVisible = false;
      this.chartData = [];
      this.sortedValues = [];

      const selectedBenefits: Benefit[] = JSON.parse(JSON.stringify(data.selectedBenefits ? data.selectedBenefits : [])) || [];
      selectedBenefits.forEach(benefit => {
        if (benefit.maxMul) {
          benefit.points = (benefit.points as number) * benefit.selectedCount;
        }
        //cashout card
        else if (benefit.tierConfigs) {
          benefit.points = benefit.advCashOutSelectedPoints;
        }
        //tiervalues
        else if (benefit.tierValues) {
          benefit.tierValues.forEach(val => {
            if (val.isSelected) {
              benefit.points = val.value;
            }
          });
        } else if (benefit.isCashoutSpecialized) {
          benefit.points = data.points;
        }
        //MMUser
        else if (benefit && benefit.budget && benefit.budget.total) {
          benefit.points = benefit.budget.total.estimatedAmount.amount;
        } else if (benefit.cashOut) {
          benefit.points = data.amount;
        }

        // removing the following properties from Selected Benefits list as this causes
        // the formulae of sorted values in categorizeBenefits with mis-calculation which leads to donut-chart
        // issue because of duplication values

        delete benefit.confirmedDollarIncrementCount;
        delete benefit.confirmedIncrementCount;
        delete benefit.confirmedCashOutPoints;
      });

      const confirmedBenefits: Benefit[] = JSON.parse(JSON.stringify(data.confirmedBenefits ? data.confirmedBenefits : [])) || [];
      confirmedBenefits.forEach(benefit => {
        if (benefit.maxMul) {
          benefit.points = (benefit.points as number) * benefit.confirmedCount;
        }
        //cashout card
        else if (benefit.tierConfigs) {
          benefit.points = benefit.confirmedAdvCashOutPoints;
        } else if (benefit.tierValues) {
          benefit.tierValues.forEach(val => {
            if (val.isSelected) {
              benefit.points = val.value;
            }
          });
        } else if (benefit.isCashoutSpecialized) {
          benefit.points = data.points;
        }
        //MMUser
        else if (benefit && benefit.budget && benefit.budget.total) {
          benefit.points = benefit.budget.total.estimatedAmount.amount;
        } else if (benefit.cashOut) {
          benefit.points = data.amount;
        }
        // removing the following properties from Confirmed Benefits list as this causes
        // the formulae of sorted values in categorizeBenefits with mis-calculation which leads to donut-chart
        // issue because of duplication values

        delete benefit.selectedDollarIncrementCount;
        delete benefit.selectedIncrementCount;
        delete benefit.selectedCashOutPoints;
      });

      // Convert values to percentage...
      if (data.amount) {
        this.amount = this.benefitService.amountRedeemed;
        this.sortedValues = this.categorizeBenefits(selectedBenefits, confirmedBenefits, data.amount, 'amount');
      } else {
        this.sortedValues = this.categorizeBenefits(selectedBenefits, confirmedBenefits, data.points, 'points');
      }
      setTimeout(() => {
        this.calculateChartData();
        this.isVisible = true;
      }, 0);
      this.spinner.hide();
    });
  }

  categorizeBenefits(selectedBenefits: Benefit[], confirmedBenefits: Benefit[], totalPoints: number, type: string): number[] {
    //const categories = Object.values(CategoryMapping);
    const categories = this.benefitService.categoryNames;
    const benefitByGroup = {};
    let sortedValues;
    categories.forEach(category => {
      benefitByGroup[category.en] = confirmedBenefits.filter(benefit => benefit.category === category.en) || [];
    });

    if (type === 'points') {
      // Percentage value of each section...
      sortedValues = Object.values(benefitByGroup).map(
        (arr: any[]) =>
          (arr
            .map(b =>
              b.pointValue
                ? b.confirmedCashOutPoints || 0
                : b.pointsPerIncrement
                ? b.pointsPerIncrement * (b.confirmedIncrementCount || 0)
                : b.points === 0 && b.perPointCurrency
                ? b.confirmedDollarIncrementCount || 0
                : b.points
            )
            .reduce((a, b) => {
              typeof a === 'string' ? (a = 0) : (a = a);
              typeof b === 'string' ? (b = 0) : (b = b);
              return a + b;
            }, 0) /
            totalPoints) *
          100
      );

      const totalSelectedBenefits =
        (Number(
          selectedBenefits
            .map(b =>
              b.pointValue
                ? b.selectedCashOutPoints || 0
                : b.pointsPerIncrement
                ? b.pointsPerIncrement * (b.selectedIncrementCount || 0)
                : b.points === 0 && b.perPointCurrency
                ? b.selectedDollarIncrementCount || 0
                : b.points
            )
            .reduce((a, b) => {
              typeof a === 'string' ? (a = 0) : (a = a);
              typeof b === 'string' ? (b = 0) : (b = b);
              return a + b;
            }, 0)
        ) /
          totalPoints) *
        100;

      sortedValues.push(totalSelectedBenefits);
    } else {
      let cashOutAmount = 0;
      if (
        this.transfereeDetails &&
        this.transfereeDetails.hasOwnProperty('budgetDetails') &&
        this.transfereeDetails.budgetDetails !== null
      ) {
        cashOutAmount = this.transfereeDetails.budgetDetails.totalAmount - (this.transfereeDetails.budgetDetails.confirmedAmount || 0);
      }
      // Percentage value of each section...
      sortedValues = Object.values(benefitByGroup).map(
        (arr: any[]) =>
          (arr
            .map(b =>
              b.budget
                ? b.maxMul
                  ? (b.confirmedCount || 0) * b.budget.total.estimatedAmount.amount
                  : b.cashOut
                  ? cashOutAmount
                  : b.budget.total.estimatedAmount.amount
                : b.cashOut
                ? cashOutAmount
                : 0
            )
            .reduce((a, b) => {
              typeof a === 'string' ? (a = 0) : (a = a);
              typeof b === 'string' ? (b = 0) : (b = b);
              return a + b;
            }, 0) /
            totalPoints) *
          100
      );

      const totalSelectedBenefits =
        (Number(
          selectedBenefits
            .map(b =>
              b.budget
                ? b.maxMul
                  ? (b.selectedCount || 0) * b.budget.total.estimatedAmount.amount
                  : b.cashOut
                  ? cashOutAmount
                  : b.budget.total.estimatedAmount.amount
                : b.cashOut
                ? cashOutAmount
                : 0
            )
            .reduce((a, b) => {
              typeof a === 'string' ? (a = 0) : (a = a);
              typeof b === 'string' ? (b = 0) : (b = b);
              return a + b;
            }, 0)
        ) /
          totalPoints) *
        100;

      sortedValues.push(totalSelectedBenefits);
    }

    let remainingPercent = sortedValues.reduce((a, b) => a + b) - 100;

    if (remainingPercent < 0) {
      remainingPercent = remainingPercent * -1;
    }
    sortedValues.push(remainingPercent);
    return sortedValues;
  }

  adjustedCircumference() {
    return this.circumference();
  }

  circumference() {
    return 2 * Math.PI * this.radius;
  }

  dataTotal() {
    return this.sortedValues.reduce((acc, val) => acc + val);
  }

  calculateChartData() {
    this.sortedValues.forEach((dataVal, index) => {
      const { x, y } = this.calculateTextCoords(dataVal, this.angleOffset);
      // start at -90deg so that the largest segment is perpendicular to top
      const data = {
        degrees: this.angleOffset,
        textX: x,
        textY: y
      };
      this.chartData.push(data);
      this.angleOffset = this.dataPercentage(dataVal) * 360 + this.angleOffset;
    });
  }

  sortInitialValues() {
    return (this.sortedValues = this.sortedValues.sort((a, b) => b - a));
  }

  calculateStrokeDashOffset(dataVal) {
    const strokeDiff = this.dataPercentage(dataVal) * this.circumference();
    return this.circumference() - strokeDiff;
  }

  calculateTextCoords(dataVal, angleOffset) {
    // t must be radians
    // x(t) = r cos(t) + j
    // y(t) = r sin(t) + j

    const angle = (this.dataPercentage(dataVal) * 360) / 2 + angleOffset;
    const radians = this.degreesToRadians(angle);

    const textCoords = {
      x: this.radius * Math.cos(radians) + this.cx,
      y: this.radius * Math.sin(radians) + this.cy
    };
    return textCoords;
  }

  degreesToRadians(angle) {
    return angle * (Math.PI / 180);
  }

  dataPercentage(dataVal) {
    return dataVal / this.dataTotal();
  }

  percentageString(dataVal) {
    return `${Math.round(this.dataPercentage(dataVal) * 100)}%`;
  }

  returnCircleTransformValue(index) {
    return `rotate(${this.chartData[index].degrees}, ${this.cx}, ${this.cy})`;
  }

  segmentBigEnough(dataVal) {
    return Math.round(this.dataPercentage(dataVal) * 100) > 6;
  }

  getTextData(index, type) {
    setTimeout(() => {
      return this.chartData[index][type];
    }, 20);
  }

  ngOnDestroy() {
    if (this.oberveSubscription) {
      this.oberveSubscription.unsubscribe();
    }
  }
}
