import { Component, OnInit, Input } from '@angular/core';
import { destinationCardImage, destinationCardTitle, exploreDestURL } from '../../../models/constants';
import { TransfereeNeedsAssessment } from 'src/app/core/models/candidateneeds-assessment.model';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';

@Component({
  selector: 'app-explore-destination',
  templateUrl: './explore-destination.component.html',
  styleUrls: ['./explore-destination.component.scss']
})
export class ExploreDestinationComponent implements OnInit {
  /** title of card to be displayed */
  destintionCardTitle = destinationCardTitle;
  /** array of image titles to be displyed */
  destinationCardImage = destinationCardImage;
  /** to store velues retrieved from needs assessment */
  needsAssessment: TransfereeNeedsAssessment;
  exploreDestURL = exploreDestURL;
  /**
   * @param needsAssessmentShared Inject needs assessment shared service
   */
  constructor(
    private readonly needsAssessmentShared: NeedsAssessmentSharedService,
    translate: TranslateService,
    private languageTranslationService: LanguageTranslationService
  ) {
    translate.use(this.languageTranslationService.getSupportedLanguage());
  }

  ngOnInit() {}
  /**
   * To redirect user to respective links by destination address
   */
  loadDestination() {
    this.needsAssessment = this.needsAssessmentShared.transfereeNeedsAssessmentDetails.getValue();
    if (!this.needsAssessment) {
      return;
    }
    if (this.needsAssessment.destinationAddr.country.includes('United States')) {
      window.open(exploreDestURL.destinationUS);
    } else {
      window.open(exploreDestURL.destinationNonUS);
    }
  }
}
