import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PropertyDetailsPdfService } from '../../services/property-details-pdf.service';
import pdfMake from 'pdfmake/build/pdfmake';
import { BenefitService } from 'src/app/public/benefits/services/benefit.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TasksInfoService } from 'src/app/core/services/tasks-info.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';

@Component({
  selector: 'app-property-details-modal',
  templateUrl: './property-details-modal.component.html',
  styleUrls: ['./property-details-modal.component.scss']
})
export class PropertyDetailsModalComponent implements OnInit {
  browserLanguage: string;
  constructor(
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<PropertyDetailsModalComponent>,
    private injector: Injector,
    private readonly pdfgenerator: PropertyDetailsPdfService,
    private readonly toaster: MatSnackBar,
    private benefitService: BenefitService,
    private readonly spinner: NgxSpinnerService,
    private taskService: TasksInfoService,
    public translate: TranslateService,
    languageTranslationService: LanguageTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.browserLanguage = languageTranslationService.getSupportedLanguage();
    translate.use(this.browserLanguage);
  }

  ngOnInit() {
    this.updateStatus();
  }

  updateStatus() {
    this.spinner.show();
    let status: boolean;
    if (!this.data.viewStatus) {
      status = true;
      this.benefitService.contactCardInformation.next(this.data.id);
      this.benefitService.updateViewStatus(status, this.data.id).subscribe(data => {
        if (data) {
          return;
        }
      });
    }
    this.spinner.hide();
  }

  onCancel(evt) {
    evt.preventDefault();
    this.dialogRef.close(null);
  }

  onClose(evt) {
    evt.preventDefault();
    this.dialogRef.close(null);
  }
  /**download toaster */
  openDownloadToastr() {
    const message = this.translate.instant('bb.alertMessages.downloadTempHousingPropertyDetails');
    const toaster = this.toaster.open(message, this.translate.instant('bb.contacts.download'), {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toaster']
    });
    toaster.onAction().subscribe(_ => this.downloadPdf());
  }

  /**pdf download service call */
  downloadPdf() {
    // const currentDate = new Date();
    // const formattedDate = formatDate(currentDate, 'MMMM_dd_yyyy_HH_mm_ss', 'en-US');
    // open, pending, cancelled, in-progress, paused, complete, stopped, closed
    // const name = `${this.data.supID}`;
    const pdfName = `Property Details-${this.data.orderReference}`;

    const downloadData = {
      propertyDetails: this.data
    };
    pdfMake.createPdf(this.pdfgenerator.generateDocumentDefinition(downloadData)).download(`${pdfName}`);
  }
}
