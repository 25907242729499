<div class="dashboard-wrapper lumpsum-dashboard">
  <!--Welcome component starts-->
  <div fxLayoutAlign="space-evenly start" fxLayout="row" class="welcome-container">
    <div
      fxFlex="75%"
      fxFlex.md="80%"
      fxLayoutGap="36px"
      fxLayoutGap.md="24px"
      fxFlex.sm="100%"
      fxLayoutGap.sm="0px"
      fxFlex.xs="100%"
      fxLayoutGap.xs="0px"
    >
      <app-welcome-content></app-welcome-content>
    </div>
  </div>
  <div fxLayoutAlign="space-evenly start" fxLayout="row">
    <div
      fxFlex="80%"
      fxLayout="column"
      fxFlex.md="70%"
      fxLayoutGap="36px"
      fxLayoutGap.md="24px"
      fxFlex.sm="100%"
      fxLayoutGap.sm="0px"
      fxFlex.xs="100%"
      fxLayoutGap.xs="0px"
      style="margin: auto !important;"
    >
      <div fxLayout="row" fxLayoutAlign="space-evenly stretch" fxLayout.sm="column" fxLayout.xs="column" fxLayout.md="column">
        <div fxFlex="60%" class="m-b-20 full-width" fxLayout="column" fxLayout.sm="column" fxFlex.xs="100%" fxLayoutGap="1%">
          <app-multiple-moves [orderRequestId]="orderRequestId" [needsAssessmentDetails]="needsAssessment"></app-multiple-moves>
        </div>
        <div fxFlex="30%" fxLayout="row" fxFlex.md="100%" fxLayoutAlign="start flex-start" fxFlex.sm="100%" fxFlex.xs="100%" class="m-b-20">
          <relocation-dates
            [orderRequestId]="orderRequestId"
            [estimatedArrivalDate]="estimatedArrivalDate"
            [jobStartDateValue]="jobStartDate"
          ></relocation-dates>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-evenly stretch" class="flex-direction">
        <div fxFlex="44%" fxLayout="row" fxFlex.md="100%" fxLayoutAlign="start flex-start" fxFlex.sm="100%" fxFlex.xs="100%" class="m-b-20">
          <family-card [familyData]="familyDetails" fxFlex="100%"></family-card>
        </div>
        <div fxFlex="44%" fxLayout="row" fxFlex.md="100%" fxLayoutAlign="end flex-end" fxFlex.sm="100%" fxFlex.xs="100%" class="m-b-20">
          <app-explore-destination></app-explore-destination>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-evenly">
        <div
          fxFlex="44%"
          fxLayout="column"
          fxFlex.md="100%"
          fxLayoutAlign="start flex-start"
          fxFlex.sm="100%"
          fxFlex.xs="100%"
          class="m-b-20"
        >
          <bank-info-widget
            *ngIf="lumpsumDetails?.bankTaskRequired"
            [orderRequestId]="orderRequestId"
            [lumpsumDetails]="lumpsumDetails"
          ></bank-info-widget>
        </div>
        <div
          fxFlex="44%"
          fxLayout="column"
          fxFlex.md="100%"
          fxLayoutAlign="start flex-start"
          fxFlex.sm="100%"
          fxFlex.xs="100%"
          class="m-b-20"
        >
          <live-easy fxFlex="100%"></live-easy>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-evenly stretch" class="flex-direction">
        <div fxLayout="column" fxFlex="92%" fxFlex.md="100%" fxFlex.sm="100%" fxFlex.xs="100%">
          <ng-container>
            <ng-container *ngTemplateOutlet="multiDocumentLibrary"></ng-container>
          </ng-container>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-evenly stretch" class="flex-direction">
        <div fxLayout="column" fxFlex="92%" fxFlex.md="100%" fxFlex.sm="100%" fxFlex.xs="100%">
          <app-frequently-asked-questions></app-frequently-asked-questions>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #multiDocumentLibrary>
  <app-document-library-card
    *ngIf="documentCapability === 'transferee' || documentCapability === 'read'"
    [orderRequest]="orderRequestId"
  ></app-document-library-card>
</ng-template>
