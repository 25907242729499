import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { PrivacyNoticeComponent } from './core/components/privacy-notice/privacy-notice.component';
import { NonCompatibleComponent } from './core/components/non-compatible/non-compatible.component';
import { ExternRouteComponent } from './core/components/extern-route/extern-route.component';
import { LoginComponent } from './core/components/login/login.component';
import { LogoutComponent } from './core/components/logout/logout.component';
// import { AuthGuard } from './public/authGuard';
import { ConsultantGuardService } from './public/consultant-guard.service';
import { DocumentLibraryPageComponent } from './public/document-library-page/document-library-page.component';
import { TermsOfUseComponent } from './core/components/terms-of-use/terms-of-use.component';
import { ContactUsComponent } from './core/components/contact-us/contact-us.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'shared',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'privacy-statement',
    component: PrivacyNoticeComponent
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'not-supported',
    component: NonCompatibleComponent
  },
  {
    path: 'needs-assessment',
    loadChildren: () => import('./public/needs-assessment/needs-assessment.module').then(m => m.NeedsAssessmentModule),
    canActivate: [ConsultantGuardService]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./public/dashboard/dashboard.module').then(m => m.DashboardModule)
    //canActivate: [ConsultantGuardService]
  },
  {
    path: 'profile-info/:id',
    loadChildren: () => import('./public/profile-info/profile-info.module').then(m => m.ProfileInfoModule)
    //canActivate: [ConsultantGuardService]
  },
  {
    path: 'benefits',
    loadChildren: () => import('./public/benefits/benefits.module').then(m => m.BenefitsModule)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'document-library',
    component: DocumentLibraryPageComponent
  },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider
    },
    component: ExternRouteComponent
  }
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalURL = route.paramMap.get('externalUrl');
        window.location.replace(externalURL);
      }
    }
  ],
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
