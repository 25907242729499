<mat-dialog-content>
  <ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-atomic="true" size="medium">
    <span class="cdk-visually-hidden">Loading, please wait...</span>
  </ngx-spinner>

  <div
    mat-dialog-title
    disableTypography
    className="{styles.dialogTitle}"
    fxLayout="row"
    fxLayoutAlign="space-between"
    fxLayoutGap="10px"
    fxLayoutGap.xs="0em"
    role="heading"
    aria-level="2"
  >
    <span class="popup-heading">{{ 'bb.contacts.propertyDetails' | translate }}</span>
    <span class="close-icon" (click)="onClose($event)" title="close">
      <mat-icon>close</mat-icon>
    </span>
  </div>

  <mat-dialog-content>
    <div fxLayout="row" fxLayoutGap="20%">
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>{{ 'bb.contacts.providerName' | translate }}</mat-label>
          <input disabled matInput type="text" />{{ data.bookingInfo.providerName ? data.bookingInfo.providerName : '' }}
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>{{ 'bb.contacts.propertyName' | translate }}</mat-label>
          <input disabled matInput />{{ data.bookingInfo.propertyName ? data.bookingInfo.propertyName : '' }}
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="20%">
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>{{ 'bb.contacts.apartmentType' | translate }}</mat-label>
          <input disabled matInput type="text" />{{ data.bookingInfo.apartmentType ? data.bookingInfo.apartmentType : '' }}
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>{{ 'bb.contacts.rentPaidBy' | translate }}</mat-label>
          <input disabled matInput type="text" />{{ data.bookingInfo.rentPaidBy ? data.bookingInfo.rentPaidBy : '' }}
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="20%">
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>{{ 'bb.contacts.country' | translate }}</mat-label>
          <input disabled matInput type="text" />{{ data.bookingInfo.address.country ? data.bookingInfo.address.country : '' }}
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="100%">
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>{{ 'bb.contacts.address' | translate }}</mat-label>
          <input disabled matInput type="text" />{{ data.bookingInfo.address.streetLine1 ? data.bookingInfo.address.streetLine1 : '' }}
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between none">
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>{{ 'bb.contacts.city' | translate }}</mat-label>
          <input disabled matInput type="text" />{{ data.bookingInfo.address.city ? data.bookingInfo.address.city : '' }}
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>{{ 'bb.contacts.state' | translate }}</mat-label>
          <input disabled matInput type="text" />{{ data.bookingInfo.address.state ? data.bookingInfo.address.state : '' }}
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>{{ 'bb.contacts.postalCode' | translate }}</mat-label>
          <input disabled matInput type="text" />{{ data.bookingInfo.address.postalCode ? data.bookingInfo.address.postalCode : '' }}
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <div fxLayout="row" fxLayoutAlign="space-between end">
    <div fxFlex="30%">
      <button mat-button class="download-btn" (click)="openDownloadToastr()">
        <mat-icon class="download-icon">print</mat-icon><span class="btn-txt">{{ 'bb.contacts.printDetails' | translate }}</span>
      </button>
    </div>
    <div>
      <button mat-button class="cancel-btn" (click)="onCancel($event)">
        {{ 'bb.contacts.ok' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>
