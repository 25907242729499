import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NeedsAssessmentSharedService } from '../../../../public/needs-assessment/services/needs-assessment-shared.service';
import { UntypedFormControl } from '@angular/forms';
import { BenefitService } from 'src/app/public/benefits/services/benefit.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'relocation-dates',
  templateUrl: './relocation-dates.component.html',
  styleUrls: ['./relocation-dates.component.scss']
})
export class RelocationDatesComponent implements OnInit, OnDestroy {
  @Input() orderRequestId: string;
  @Input() estimatedArrivalDate: string;
  @Input() jobStartDateValue: string;

  estimatedArrivalDateValue = new UntypedFormControl();
  jobStartDate = new UntypedFormControl();
  needsAssessment: any;
  needsAssessmentSubscription: Subscription;
  isCoBrowsing = false;

  constructor(
    private readonly benefitService: BenefitService,
    private readonly spinner: NgxSpinnerService,
    private readonly needsAssessmentShared: NeedsAssessmentSharedService,
    public cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.needsAssessmentSubscription = this.needsAssessmentShared.transfereeNeedsAssessmentDetails.subscribe(data => {
      if (!data) {
        return;
      }
      this.needsAssessment = data;
      this.orderRequestId = data.orderRequestId;
      this.setRelocationDatesData();
    });
    this.isCoBrowsing = this.cookieService.get('transferee-context') ? true : false;
  }

  ngOnDestroy(): void {
    if (this.needsAssessmentSubscription) {
      this.needsAssessmentSubscription.unsubscribe();
    }
  }

  estimatedArrivalDateChange(e) {
    this.spinner.show();
    this.benefitService
      .updateEstimatedArrivalDate(this.orderRequestId, {
        estimatedArrivalDate: `${
          moment(e.target.value)
            .format()
            .split('T')[0]
        }T00:00:00.000Z`
      })
      .subscribe(
        res => {
          this.spinner.hide();
          const data = {
            orderRequestId: this.orderRequestId,
            estimatedArrivalDate: moment(res.estimatedArrivalDate).format('YYYY-MM-DD')
          };
          this.needsAssessmentShared.updateRelocationDate.next(data);
          if (!res.code || res.code !== 'UPDATE_SUCCESS') {
            console.log('estimated arrival date updation error');
          }
        },
        err => {
          this.spinner.hide();
        }
      );
  }

  setRelocationDatesData() {
    if (this.needsAssessment.estimatedArrivalDate) {
      this.estimatedArrivalDateValue.setValue(new Date(this.needsAssessment.estimatedArrivalDate));
    } else {
      this.estimatedArrivalDateValue.setValue(null);
    }
    if (this.needsAssessment.jobStartDate) {
      this.jobStartDate.setValue(moment(this.needsAssessment.jobStartDate).format('MM/DD/YYYY'));
    } else {
      this.jobStartDate.setValue('');
    }
  }
}
