import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';
import { DashboardService } from 'src/app/public/dashboard/services/dashboard.service.service';
import { DataResponseModel, NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BankInfoService } from '../../services/bank-info.service';
import { MatDialog } from '@angular/material/dialog';
import { LumpsumRequestCompletionComponent } from '../lumpsum-request-completion/lumpsum-request-completion.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { CollaborationService } from 'src/app/core/services/collaboration.service';
import { Subscription } from 'rxjs';
import { CollabEvents, CollabMessage } from 'src/app/core/services/collaboration-events.service';
import { PdfGenerationService } from '../../services/pdf-generation.service';
@Component({
  selector: 'app-welcome-content',
  templateUrl: './welcome-content.component.html',
  styleUrls: ['./welcome-content.component.scss']
})
export class WelcomeContentComponent implements OnInit, OnDestroy {
  orderRequestId: string;
  browserLanguage: string;
  transfereeNameDetails: any;
  repayComplete = false;
  bankInfoComplete = false;
  bankInfoDetails = new BehaviorSubject<any>(null);
  lumpsumDetails: any = {};
  noTasks = false;
  clientName: string;
  paymentRequestInitiatedDate: string;
  requestLumpsum = false;
  isCoBrowsing = false;
  collabSubscription: Subscription;
  needAssessmentSubscription: Subscription;
  bankAccountsDetails = [];

  constructor(
    private readonly needsAssessmentShared: NeedsAssessmentSharedService,
    private readonly languageTranslationService: LanguageTranslationService,
    private readonly route: Router,
    private dashboardService: DashboardService,
    private bankInfoService: BankInfoService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: MatSnackBar,
    public translate: TranslateService,
    public dialog: MatDialog,
    public cookieService: CookieService,
    public readonly collaboration: CollaborationService,
    public readonly pdfgenerator: PdfGenerationService
  ) {
    this.browserLanguage = this.languageTranslationService.getSupportedLanguage();
    translate.use(this.browserLanguage);
  }

  ngOnInit(): void {
    this.loadOrderRequestId();
    this.isCoBrowsing = this.cookieService.get('transferee-context') ? true : false;
    this.observeCollab();
  }

  observeCollab() {
    this.collabSubscription = this.collaboration.socketSubscriber$.subscribe((message: CollabMessage) => {
      if (!message) {
        return;
      }
      if (message.data && message.data.event && message.data.event === CollabEvents.REPAYMENT_AGREEMENT_STATUS_COMPLETE) {
        if (message.data.data == true) this.loadOrderRequestId(true, true);
        else this.loadOrderRequestId(true);
      } else if (message.data && message.data.event && message.data.event === CollabEvents.BANK_INFORMATION_STATUS_COMPLETE) {
        this.loadOrderRequestId(true);
      } else if (message.data.event === CollabEvents.REQUEST_LUMPSUM) {
        this.updateLumpsumDetailsCollab(message.data.data, false);
      }
    });
  }

  loadOrderRequestId(isCollab = false, repayAgreementStatus?: boolean) {
    this.needAssessmentSubscription = this.needsAssessmentShared.transfereeNeedsAssessmentDetails.subscribe(data => {
      if (!data) {
        return;
      }
      this.orderRequestId = data.orderRequestId;
      this.transfereeNameDetails = data.nameDetails;
      this.clientName = data.clientEntityName;
      this.spinner.show();
      this.repayComplete = false;
      this.bankInfoComplete = false;
      this.spinner.show();
      if (!isCollab) this.getBankInfo();
      else this.getBankInfo(true);

      this.dashboardService.getLSPaymentData().subscribe(response => {
        if (response) {
          // response['payAsOfDate'] = new Date();
          this.lumpsumDetails = response;
          if (repayAgreementStatus) this.repayComplete = true;
          else this.repayComplete = this.lumpsumDetails?.repay?.repayAcceptedDate ? true : false;
          if (response.payAsOfDate) {
            const formattedDate = response.payAsOfDate?.split('T')[0];
            this.lumpsumDetails['payAsOfDate'] = moment(formattedDate).format('DD MMM YYYY');
          }
          if (this.lumpsumDetails.paymentRequestInitiatedDate) {
            this.paymentRequestInitiatedDate = moment(response.paymentRequestInitiatedDate).format('DD MMM YYYY');
          }
          if (response.amount) this.lumpsumDetails['amount'] = this.numberWithCommas(response.amount);
          if (!isCollab) this.needsAssessmentShared.transfereeRepaymentLumpSumDetails.next(this.lumpsumDetails);
          !this.lumpsumDetails?.repay?.taskRequired && !this.lumpsumDetails?.bankTaskRequired
            ? (this.noTasks = true)
            : (this.noTasks = false);
          this.bankInfoDetails.subscribe(res => {
            if (res) {
              this.bankInfoComplete = res;
              this.lumpsumDetails['bankInfoComplete'] = res;
              if (
                this.lumpsumDetails?.auditCompleted &&
                moment(this.lumpsumDetails?.payAsOfDate?.split('T')[0]).isBefore(new Date()) &&
                !this.paymentRequestInitiatedDate
              ) {
                if (this.lumpsumDetails?.bankTaskRequired && this.lumpsumDetails.repay?.taskRequired) {
                  if (this.lumpsumDetails.repay?.repayAcceptedDate && this.bankInfoComplete) {
                    this.requestLumpsum = true;
                  } else {
                    this.requestLumpsum = false;
                  }
                } else if (this.lumpsumDetails?.bankTaskRequired && this.bankInfoComplete) {
                  this.requestLumpsum = true;
                } else if (this.lumpsumDetails.repay?.repayAcceptedDate && this.lumpsumDetails?.repay?.isPaymentRequestDependent) {
                  this.requestLumpsum = true;
                } else if (
                  !this.lumpsumDetails?.bankTaskRequired &&
                  !this.lumpsumDetails.repay?.taskRequired &&
                  !this.lumpsumDetails?.repay?.isPaymentRequestDependent
                ) {
                  this.requestLumpsum = true;
                } else {
                  this.requestLumpsum = false;
                }
              }
            }
          });
          this.spinner.hide();
        } else {
          this.openToastMessage('Error in fetching lumpsum details.');
          this.spinner.hide();
          return;
        }
      });
    });
  }

  getBankInfo(isCollab = false) {
    this.bankInfoService.getBankAccounts(this.orderRequestId).subscribe(res => {
      this.spinner.hide();
      if (res) {
        if (res?.body?.length) {
          this.bankAccountsDetails = [];
          const bankAccounts = res?.body;
          this.bankAccountsDetails = res?.body;
          this.bankInfoComplete = true;
          this.lumpsumDetails['bankInfoComplete'] = true;
          this.bankInfoDetails.next(this.bankInfoComplete);
        }
      } else {
        this.openToastMessage('Error in fetching bank accounts.');
        return;
      }
    });
  }

  goToBankInfoPage() {
    if (this.isCoBrowsing) return;
    else this.route.navigate(['/lumpsum-dashboard/mytasks-lumpsum/bankInfo'], { state: this.lumpsumDetails });
  }

  goToRepaymentAgreement() {
    this.route.navigate(['/lumpsum-dashboard/mytasks-lumpsum/repaymentAgreement'], { state: this.lumpsumDetails });
  }

  openToastMessage(toastMsg) {
    this.toastr.open(toastMsg, '', {
      duration: 5000
    });
  }

  numberWithCommas(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  initiateLumpsumPaymentRequest() {
    this.spinner.show();
    this.bankInfoService.initiatePaymentRequest(this.orderRequestId).subscribe(res => {
      if (res) {
        this.spinner.hide();
        const dialogRef = this.dialog.open(LumpsumRequestCompletionComponent, {
          data: {},
          width: '32vw',
          disableClose: false
        });
        sessionStorage.setItem('car-ses-oid', this.orderRequestId);
        dialogRef.afterClosed().subscribe(data => {
          this.spinner.show();
          this.dashboardService.getLSPaymentData().subscribe(response => {
            if (response) {
              this.lumpsumDetails = response;
              this.requestLumpsum = false;
              if (this.lumpsumDetails.paymentRequestInitiatedDate) {
                this.paymentRequestInitiatedDate = moment(this.lumpsumDetails.paymentRequestInitiatedDate).format('DD MMM YYYY');
              }
              this.spinner.hide();
              const lumpsumCollabData = {
                paymentRequestInitiatedDate: this.paymentRequestInitiatedDate,
                requestLumpsum: this.requestLumpsum,
                lumpsumDetails: this.lumpsumDetails,
                orderRequestId: this.orderRequestId
              };
              if (!this.isCoBrowsing) {
                this.collaboration.sendMessage({
                  data: lumpsumCollabData,
                  event: CollabEvents.REQUEST_LUMPSUM
                });
              }
            } else {
              this.spinner.hide();
            }
          });
        });
        this.requestLumpsum = false;
      } else {
        this.openToastMessage('Error while requesting payment.');
        this.spinner.hide();
        return;
      }
    });
  }

  /** to update lumpsum details in co-browsing mode*/
  updateLumpsumDetailsCollab(lumpsumData, isTaskAvailable) {
    if (this.cookieService.get('transferee-context')) {
      this.needAssessmentSubscription = this.needsAssessmentShared.transfereeNeedsAssessmentDetails.subscribe(data => {
        if (!data) {
          return;
        }
        this.orderRequestId = data.orderRequestId;
        this.transfereeNameDetails = data.nameDetails;
        this.clientName = data.clientEntityName;
        this.lumpsumDetails = lumpsumData?.lumpsumDetails;
        this.repayComplete = this.lumpsumDetails?.repay?.repayAcceptedDate ? true : false;
        if (this.lumpsumDetails?.payAsOfDate) {
          const formattedDate = this.lumpsumDetails?.payAsOfDate?.split('T')[0];
          this.lumpsumDetails['payAsOfDate'] = moment(formattedDate).format('DD MMM YYYY');
        }
        if (this.lumpsumDetails?.paymentRequestInitiatedDate) {
          this.paymentRequestInitiatedDate = moment(this.lumpsumDetails.paymentRequestInitiatedDate).format('DD MMM YYYY');
        }
        if (this.lumpsumDetails?.amount) this.lumpsumDetails['amount'] = this.numberWithCommas(this.lumpsumDetails.amount);
        this.needsAssessmentShared.transfereeRepaymentLumpSumDetails.next(this.lumpsumDetails);
        if (isTaskAvailable) {
          !this.lumpsumDetails?.repay?.taskRequired && !this.lumpsumDetails?.bankTaskRequired
            ? (this.noTasks = true)
            : (this.noTasks = false);
        } else {
          this.noTasks = true;
        }
        if (lumpsumData?.bankInfo?.length) {
          this.bankInfoComplete = true;
        } else {
          this.bankInfoDetails.subscribe(step => {
            this.bankInfoComplete = step;
          });
        }
        this.requestLumpsum = lumpsumData?.requestLumpsum;
      });
    }
  }

  ngOnDestroy() {
    if (this.collabSubscription) this.collabSubscription.unsubscribe();

    if (this.needAssessmentSubscription) {
      this.needAssessmentSubscription.unsubscribe();
    }
    if (this.bankInfoDetails) {
      this.bankInfoDetails.unsubscribe();
    }
  }
}
