<mat-card appearance="outlined" [class]="cardInfo.mainClass" tabindex="0" fxFlex="100%">
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <card-title [name]="cardInfo.title" imageUri="video-blue" type="video"></card-title>
    </div>
    <img *ngIf="cardInfo.icon" src="../../../../../assets/images/cartus-online-icon.svg" alt="cartus online" class="cartus-online-icon" />
  </mat-card-title>
  <mat-card-content class="scroll-content">
    <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
      <div
        fxLayout="column"
        fxFlex="100%"
        *ngFor="let content of cardInfo.content; let i = index"
        (click)="openVideoPopup(i)"
        (keypress)="openVideoPopup(i)"
        (keyup.enter)="openVideoPopup(i)"
      >
        <p class="title">{{ content.translated }}</p>
        <img class="video-img" src="../../../../../assets/images/{{ content.imageUri }}.png" alt="neighbour" />
      </div>
    </div>
  </mat-card-content>
</mat-card>
