import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'formatPhoneNumber'
})
export class FormatPhoneNumberPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const reg = new RegExp('^[0-9]*$');
    if (value !== '' && (value as string).length === 10 && reg.test(value)) {
      return `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6, 10)}`;
    } else {
      let newStr = '';
      let i = 0;
      for (; i < Math.floor(value.length / 3) - 1; i++) {
        newStr = newStr + value.substr(i * 3, 3) + '-';
      }
      return newStr + value.substr(i * 3);
    }
  }
}
