import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseClientService } from './base-client.service';
import { FeatureFlag, FeatureFlags } from './../models/feature-flag.model';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  /**To store all feature flags */
  featureFlagInformation = new BehaviorSubject<FeatureFlag[]>(null);
  /**
   * @param baseClientService Inject Base Client Service
   */
  constructor(private readonly baseClientService: BaseClientService) {}

  /**
   * To get feature flag information from behaviour subject
   * @returns Feature Flag Information
   */
  getFeatureFlagsInformation() {
    const featureFlagInformation = this.featureFlagInformation.getValue();
    if (!featureFlagInformation) {
      this.getFeatureFlags().subscribe(data => {
        if (data) {
          this.updateFeatureFlagsInformation(data);
        }
      });
    }
    return featureFlagInformation;
  }
  /**
   * To get feature flag data from API
   * @returns feature flag array
   */
  getFeatureFlags(): Observable<FeatureFlags> {
    return this.baseClientService.getById<FeatureFlags>(`/v1/featureFlag`).pipe(
      map(r => r.body),
      catchError(err => {
        console.log('Failed to get feature flag details', err);
        const emptyResp: FeatureFlags = null;
        return of(emptyResp);
      })
    );
  }
  /**
   * To update feature flag information to behaviour subject
   * @param featureFlags Feature flag array
   */
  updateFeatureFlagsInformation(featureFlags: FeatureFlags) {
    this.featureFlagInformation.next(featureFlags.items);
  }
  /**
   * To check if feature flag is enabled or disabled
   * @param featureFlag flag name
   */
  isFeatureFlagEnabled(featureFlag: string) {
    const featureFlags: FeatureFlag[] = this.getFeatureFlagsInformation();
    return featureFlags && featureFlags.find(featureFlagelem => featureFlagelem.key === featureFlag).on;
  }
}
