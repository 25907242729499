import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilityService {
  constructor() {}

  /**
   * Checks for Value or will return 'empty'
   * @param value any
   */
  isNullOrUndefinedSafeCheck(obj: object, key: string) {
    try {
      return (obj[key] || obj[key] == false) && obj[key] !== null ? obj[key] : '';
    } catch (error) {
      return '';
    }
  }
}
