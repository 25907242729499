import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { BaseClientService } from '../../../core/services/base-client.service';
import { urlType } from '../../../core/models/urlType';

@Injectable({
  providedIn: 'root'
})
export class BankInfoService {
  /**
   * @param baseClientService Inject base client service
   */
  constructor(private readonly baseClientService: BaseClientService) {}

  /**
   * To get currency of the country by passing order request ID
   */
  getCurrencyList(orderRequestId) {
    return this.baseClientService.get(`/v1/order-request/${orderRequestId}/bank/currency`, '', urlType.integrationApi).pipe(
      map(r => r.body),
      catchError(err => {
        console.log('Failed to get currency list', err);
        const emptyResp = null;
        return of(emptyResp);
      })
    );
  }

  /**
   * To get routing details by passing order request ID, country and its currency
   */
  getRoutingDetails(reqParams) {
    return this.baseClientService
      .get(
        `/v1/order-request/${reqParams.orderRequestId}/bank/routing-swiftCode?country=${reqParams.country}&disbursementCurrency=${reqParams.currency}`,
        '',
        urlType.integrationApi
      )
      .pipe(
        map(r => r.body),
        catchError(err => {
          console.log('Failed to get routing details', err);
          const emptyResp = null;
          return of(emptyResp);
        })
      );
  }

  /**
   * To get bank details by passing country, currency and routing number/ swift BIC code
   */
  getBankDetails(reqParams) {
    let routingSwiftPayload = '';
    const routingNumber = encodeURIComponent(reqParams?.routingNumber);
    if (reqParams.routingNumber && reqParams.swiftBicCode) {
      routingSwiftPayload = `&routingNumber=${routingNumber}&swiftBicCode=${reqParams.swiftBicCode}`;
    } else if (reqParams.routingNumber) {
      routingSwiftPayload = `&routingNumber=${routingNumber}`;
    } else if (reqParams.swiftBicCode) {
      routingSwiftPayload = `&swiftBicCode=${reqParams.swiftBicCode}`;
    }
    return this.baseClientService
      .get(
        `/v1/order-request/${reqParams.orderRequestId}/bank/routing-swift/search?country=${reqParams.country}&currency=${reqParams.currency}${routingSwiftPayload}`,
        '',
        urlType.integrationApi
      )
      .pipe(
        map(r => r.body),
        catchError(err => {
          console.log('Failed to get Bank details', err);
          const emptyResp = null;
          return of(emptyResp);
        })
      );
  }

  /**
   * To get account type list
   */
  getAccountTypes() {
    return this.baseClientService.get(`/v1/value-list?key=BankAccountType`).pipe(
      map(r => r.body),
      catchError(err => {
        console.log('Failed to get account type details', err);
        const emptyResp = null;
        return of(emptyResp);
      })
    );
  }

  /**
   * To validate account name by passing orderrequestID, country, currency & account number
   */
  getAccountNumberValidations(reqParams) {
    return this.baseClientService
      .get(
        `/v1/order-request/${reqParams.orderRequestId}/bank/accountno-validate?country=${reqParams.country}&currency=${reqParams.currency}&accountNumber=${reqParams.accountNumber}`,
        '',
        urlType.integrationApi
      )
      .pipe(
        map(r => r.body),
        catchError(err => {
          console.log('Failed to get account number validations', err);
          const emptyResp = null;
          return of(emptyResp);
        })
      );
  }
  /**
   * To initiate lumpsum payment request
   */
  initiatePaymentRequest(orderRequestId) {
    return this.baseClientService.put(`/v1/order-request/${orderRequestId}/bank/payment-request`, '', '', urlType.integrationApi).pipe(
      map(r => r.body),
      catchError(err => {
        console.log('Failed to initiate payment request', err);
        const emptyResp = null;
        return of(emptyResp);
      })
    );
  }

  /* This is required for Lump Sum - Synchronize Legacy Bank information with Mongo */
  syncLumpsumLegacyBankInfo(orderRequestId) {
    return this.baseClientService.post(`/v1/order-request/${orderRequestId}/bank/synchronize`, '', urlType.integrationApi).pipe(
      map(r => r.body),
      catchError(err => {
        console.log('Failed to sync lumpsum legacy bank info', err);
        const emptyResp = null;
        return of(emptyResp);
      })
    );
  }

  /**
   * To add bank
   */
  addBankAccount(payload, orderRequestId) {
    return this.baseClientService.post(`/v1/order-request/${orderRequestId}/bank`, payload, urlType.integrationApi).pipe(
      map(r => r.body),
      catchError(err => {
        console.log('Failed to add bank account', err);
        const emptyResp = null;
        return of(emptyResp);
      })
    );
  }

  getBankAccounts(requestId) {
    return this.baseClientService.get<any>(`/v1/order-request/${requestId}/bank`, '', urlType.integrationApi, true).pipe(
      map(r => r),
      catchError((err, source) => {
        const empty: any = null;
        return of(empty);
      })
    );
  }

  /**
   * To mark banck account as primary
   */
  markBankAsPrimary(accountId, orderRequestId) {
    return this.baseClientService.put(`/v1/order-request/${orderRequestId}/bank/${accountId}/primary`, {}, '', urlType.integrationApi).pipe(
      map(r => r.body),
      catchError(err => {
        console.log('Failed to mark bank account as primary', err);
        const emptyResp = null;
        return of(emptyResp);
      })
    );
  }
}
