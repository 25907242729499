import { Component, OnInit } from '@angular/core';
import { liveEasyCardTitle, liveEasyURL } from '../../../models/constants';
import { TranslateService } from '@ngx-translate/core';
import { NeedsAssessmentSharedService } from '../../../../public/needs-assessment/services/needs-assessment-shared.service';
import { LanguageTranslationService } from '../../../services/language-translation.service';
import { TransfereeNeedsAssessment } from '../../../models/candidateneeds-assessment.model';

@Component({
  selector: 'live-easy',
  templateUrl: './live-easy.component.html',
  styleUrls: ['./live-easy.component.scss']
})
export class LiveEasyComponent implements OnInit {
  liveEasyCardTitle = liveEasyCardTitle;
  /** to store values retrieved from needs assessment */
  needsAssessment: TransfereeNeedsAssessment;
  /** to store values of liveEasy url */
  liveEasyURL = liveEasyURL;

  constructor(
    private readonly needsAssessmentShared: NeedsAssessmentSharedService,
    translate: TranslateService,
    private languageTranslationService: LanguageTranslationService
  ) {
    translate.use(this.languageTranslationService.getSupportedLanguage());
  }

  ngOnInit(): void {}

  loadUrl() {
    this.needsAssessment = this.needsAssessmentShared.transfereeNeedsAssessmentDetails.getValue();
    if (!this.needsAssessment) {
      return;
    }
    if (this.needsAssessment.destinationAddr.country.includes('United States')) {
      window.open(liveEasyURL.go);
    } else {
      window.open(liveEasyURL.global);
    }
  }
}
