import { Component, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from '../../../../public/dashboard/services/dashboard.service.service';

@Component({
  selector: 'app-document-library-card',
  templateUrl: './document-library-card.component.html',
  styleUrls: ['./document-library-card.component.scss']
})
export class DocumentLibraryCardComponent implements OnInit {
  documentCardTitle = 'Track your documents';
  @Input() docCount: any;
  @Input() orderRequest: any;
  @Output() docData: any;
  docs: any[] = [];
  docWidth: any;
  isLoading = true;
  docVersions = [
    { version: 2, date: '2020-07-28', value: 2 },
    { version: 1, date: '2020-07-14', value: 1 }
  ];
  constructor(
    private router: Router,
    private dashboardservice: DashboardService,
    private readonly toastr: MatSnackBar,
    private readonly spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.getDocumentData();
  }

  getDocumentData() {
    this.dashboardservice.getDocument(this.orderRequest).subscribe(
      res => {
        if (res) {
          this.docData = res;
          this.docs = res.documentList;
          this.docWidth = res.count;
          this.isLoading = false;
          this.dashboardservice.getDoc.next(res);
        } else {
          this.openTaostMessage(`Error in fetching Document Data`);
        }
      },
      error => {
        this.openTaostMessage(`Error in fetching Document Data`);
      }
    );
  }
  openTaostMessage(message) {
    this.toastr.open(message, '', {
      duration: 3000
    });
  }
  goToDocumentsPage() {
    this.router.navigate(['/document-library'], {
      queryParams: { orderRequest: this.orderRequest }
    });
  }

  openDownloadToastr(dmsData) {
    const message = 'Downloading files with sensitive information is a security risk, please clear your cache afterwards';
    const toaster = this.toastr.open(message, 'DOWNLOAD', {
      duration: 100000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toaster']
    });
    toaster.onAction().subscribe(_ => this.dashboardservice.downloadDMSFile(this.orderRequest, dmsData));
  }
}
