import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ConsultantGuardService {
  constructor(private cookieService: CookieService) {}

  canActivate(): boolean {
    const transfereeContext = this.cookieService.get('transferee-context');
    if (transfereeContext) {
      return false;
    } else {
      return true;
    }
  }
}
