import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { PdfGenerationService } from '../../services/pdf-generation.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CancelAddBankModalComponent } from '../cancel-add-bank-modal/cancel-add-bank-modal.component';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-my-tasks-lumpsum',
  templateUrl: './my-tasks-lumpsum.component.html',
  styleUrls: ['./my-tasks-lumpsum.component.scss']
})
export class MyTasksLumpsumComponent implements OnInit {
  public addAccoutInfo = new BehaviorSubject<boolean>(false);
  showContent = false;
  repayCompleted = false;
  bankInfoCompleted = false;
  lumpsumDetails: any = {};
  headingTitle = null;
  formattedDate: string;
  showAccForm = false;
  isUploadRequired = false;
  addAccountInitiated = false;
  screenWidth: number;
  isCoBrowsing = false;

  constructor(
    private location: Location,
    public readonly router: Router,
    private readonly pdfgenerator: PdfGenerationService,
    public readonly toastr: MatSnackBar,
    public translate: TranslateService,
    public dialog: MatDialog,
    public cookieService: CookieService
  ) {
    this.lumpsumDetails =
      this.router.getCurrentNavigation()?.extras && this.router.getCurrentNavigation().extras.state
        ? this.router.getCurrentNavigation().extras.state
        : {};
    this.repayCompleted = this.lumpsumDetails?.repay?.repayAcceptedDate ?? false;
    this.bankInfoCompleted = this.lumpsumDetails?.bankInfoComplete ?? false;
    if (this.router.url.includes('repaymentAgreement')) {
      this.showContent = false;
      this.headingTitle = 'Repayment Agreement';
    } else {
      this.showContent = true;
      this.headingTitle = 'Bank Information';
    }
    // Adjust the side navigation
    // set screenWidth on page load
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
  }

  ngOnInit(): void {
    this.addAccoutInfo.subscribe(data => {
      if (data) this.addAccountInitiated = data;
      else this.addAccountInitiated = data;
    });
    this.isCoBrowsing = this.cookieService.get('transferee-context') ? true : false;
  }

  openRepayAgreePage() {
    this.showContent = false;
    this.headingTitle = 'Repayment Agreement';
  }

  openBankInfoPage() {
    this.showContent = true;
    this.headingTitle = 'Bank Information';
    this.showAccForm = false;
    this.addAccoutInfo.next(false);
  }

  goBack() {
    if (this.router?.url?.includes('repaymentAgreement')) {
      this.headingTitle = 'Repayment Agreement';
      this.addAccoutInfo.next(false);
      this.router.navigate(['/lumpsum-dashboard']);
    } else {
      this.headingTitle = 'Bank Information';
      if (this.addAccountInitiated) {
        this.discardAddbankAccount();
      } else {
        this.router.navigate(['/lumpsum-dashboard']);
        this.addAccoutInfo.next(false);
      }
    }
    // this.location.back();
    this.showContent = false;
    this.showAccForm = false;
  }

  discardAddbankAccount() {
    const dialogRef = this.dialog
      .open(CancelAddBankModalComponent, {
        panelClass: 'dialogMainContainer',
        autoFocus: false,
        disableClose: true,
        width: '40%',
        data: {}
      })
      .afterClosed()
      .subscribe(data => {
        if (data) {
          this.router.navigate(['/lumpsum-dashboard']);
          this.addAccoutInfo.next(false);
        }
      });
  }

  downloadRepayAgreement() {
    this.pdfgenerator.openDownloadToastr(this.lumpsumDetails);
  }

  showBankInfo() {
    this.showAccForm = true;
    this.addAccoutInfo.next(true);
  }
}
