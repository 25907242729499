import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';
import { PropertyDetailsModalComponent } from '../../../../public/dashboard/components/property-details-modal/property-details-modal.component';
import { BenefitService } from 'src/app/public/benefits/services/benefit.service';
import { DashboardService } from '../../../../public/dashboard/services/dashboard.service.service';
import { TasksInfoService } from 'src/app/core/services/tasks-info.service';
import { MatDialog } from '@angular/material/dialog';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {
  @Input() contactData: any;
  @Input() consultantDetails: any;
  @Input() orderRefNum: string;
  isLoading = true;
  accordinData: any = [];
  iconLink: any;
  iconName: any;

  prevIcon = '<img src="../../../../../assets/imgs/arrow-left-solid.svg" alt="prev">';
  nextIcon = '<img src="../../../../../assets/imgs/arrow-right-solid.svg" alt="prev">';

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: [this.prevIcon, this.nextIcon],
    responsive: {
      0: {
        items: 1
      }
    },
    nav: true
  };

  constructor(
    translate: TranslateService,
    private benefitService: BenefitService,
    public dialog: MatDialog,
    public tasksService: TasksInfoService,
    private languageTranslationService: LanguageTranslationService
  ) {
    translate.use(this.languageTranslationService.getSupportedLanguage());
  }

  ngOnInit() {
    this.getContactDetails();
  }

  getContactDetails() {
    this.benefitService.getMyContacts().subscribe(response => {
      if (Object.keys(response).length === 0) {
        return;
      }
      if (response) {
        response.body.benefits.forEach(item => {
          this.iconLink = item.iconLink;
          this.iconName = item.iconName;
          item.suplierOrders.forEach(ele => {
            this.accordinData.push(ele);
          });
        });
      }
      this.isLoading = false;
    });
  }

  propertyDetailsModal(index) {
    const dialogRef = this.dialog.open(PropertyDetailsModalComponent, {
      width: '52vw',
      disableClose: true,
      data: this.accordinData[index]
    });
  }

  slides = [{ image: 'https://gsr.dev/material2-carousel/assets/demo.png' }];
}
