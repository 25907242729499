import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BenefitDonutChartComponent } from './benefit-donut-chart.component';
import { BenefitService } from '../../services/benefit.service';

@NgModule({
  declarations: [BenefitDonutChartComponent],
  exports: [BenefitDonutChartComponent],
  imports: [CommonModule]
})
export class BenefitDonutChartModule {}
