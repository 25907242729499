<div class="policy-modal">
  <mat-dialog-content>
    <button class="close-btn" (click)="closeDialog()">
      <img class="close" src="../../../../../assets/images/close-icon.svg" alt="close" />
    </button>
    <h1>{{ 'bb.policyCall.schedulePolicyCall' | translate }}</h1>
    <p class="policy-para">
      {{ 'bb.policyCall.reviewConsultantCalendar' | translate }}
    </p>
    <ng-container *ngIf="data.timezone === localTimeZone">
      <p class="times">{{ 'bb.policyCall.sameTimeZone' | translate }}</p>
    </ng-container>

    <ng-container *ngIf="data.timezone !== localTimeZone">
      <p class="times">
        {{ 'bb.policyCall.consultantTimeZone' | translate }}
        {{ data.timezone }}
      </p>
      <p class="times">
        {{ 'bb.policyCall.yourTimeZone' | translate }}
        {{ localTimeZone }} ({{ hourOffset }} hrs)
      </p>
    </ng-container>

    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-around center" role="region">
      <div fxFlex="5" role="none"></div>

      <div
        class="time-slot"
        fxFlex="70"
        fxFlex.xs="100"
        fxLayout="column"
        fxLayoutAlign="center center"
        role="group"
        *ngFor="let element of counter; let i = index"
      >
        <div class="time-slot-card" fxLayout="column" fxFlex.xs="100" aria-label="Time Slot 1" role="none">
          <!-- Cronofy Slot -->
          <app-cronofy-slot-picker
            [token]="data.token"
            [cronofyId]="data.cronofyId"
            [timezone]="data.timezone"
            [counter]="i"
            [policyCallDuration]="data.policyCallDuration"
            (slotSelected)="slotSelectedHandler($event)"
          ></app-cronofy-slot-picker>
        </div>
      </div>

      <div fxFlex="5" role="none"></div>
    </div>
  </mat-dialog-content>
</div>
