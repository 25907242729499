import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TimelineDivComponent } from './components/timeline-div/timeline-div.component';
import { FormatPhoneNumberPipe } from '../../public/dashboard/pipes/format-phone-number.pipe';
import { CronofySlotPickerModule } from '../modules/cronofy-slot-picker/cronofy-slot-picker.module';
import { DialerModule } from '../modules/dialer/dialer.module';
import { CardTitleComponent } from './components/card-title/card-title.component';
import { CartusOnlineComponent } from './components/cartusonline/cartusonline.component';
import { CompleteFamilyViewComponent } from './components/complete-family-view/complete-family-view.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { DocumentLibraryCardComponent } from './components/document-library-card/document-library-card.component';
import { ExploreDestinationComponent } from './components/explore-destination/explore-destination.component';
import { FamilyCardComponent } from './components/family-card/family-card.component';
import { MultipleMovesComponent } from './components/multiple-moves/multiple-moves.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { VideoDestinationCardComponent, VideoPopupComponent } from './components/video-destination-card/video-destination-card.component';
import { DashboardService } from 'src/app/public/dashboard/services/dashboard.service.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LiveEasyComponent } from './components/live-easy/live-easy.component';
import { RelocationDatesComponent } from './components/relocation-dates/relocation-dates.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BankInfoWidgetComponent } from './components/bank-info-widget/bank-info-widget.component';

@NgModule({
  declarations: [
    CartusOnlineComponent,
    ContactCardComponent,
    VideoDestinationCardComponent,
    FamilyCardComponent,
    ExploreDestinationComponent,
    CompleteFamilyViewComponent,
    VideoPopupComponent,
    FormatPhoneNumberPipe,
    DocumentLibraryCardComponent,
    MultipleMovesComponent,
    CardTitleComponent,
    TimelineComponent,
    TimelineDivComponent,
    LiveEasyComponent,
    RelocationDatesComponent,
    BankInfoWidgetComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    FlexLayoutModule,
    DialerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatSelectModule,
    CronofySlotPickerModule,
    TranslateModule,
    NgxSpinnerModule,
    CarouselModule,
    MatSidenavModule,
    ReactiveFormsModule
  ],
  exports: [
    CartusOnlineComponent,
    ContactCardComponent,
    VideoDestinationCardComponent,
    FamilyCardComponent,
    ExploreDestinationComponent,
    CompleteFamilyViewComponent,
    VideoPopupComponent,
    FormatPhoneNumberPipe,
    DocumentLibraryCardComponent,
    MultipleMovesComponent,
    CardTitleComponent,
    TimelineComponent,
    TimelineDivComponent,
    LiveEasyComponent,
    RelocationDatesComponent,
    BankInfoWidgetComponent
  ]
})
export class SharedDashboardModule {}
