import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-cronofy-slot-picker',
  templateUrl: './cronofy-slot-picker.component.html',
  styleUrls: ['./cronofy-slot-picker.component.scss']
})
export class CronofySlotPickerComponent implements OnInit {
  @Input() token: string;
  @Input() cronofyId: string;
  @Input() timezone: string;
  @Input() counter: number;
  @Input() policyCallDuration: number;

  @Output() slotSelected = new EventEmitter();

  readonly formatType = 'YYYY-MM-DDTHH:mm:ssZ';

  hourOffset: string;
  countval: boolean;

  constructor() {}

  ngOnInit() {
    this.hourOffset = this.getHourOffset();
    this.loadSlotPicker();
  }

  loadSlotPicker() {
    // Creating list of dates for 20 days as the element takes 5 days at a time
    let counter = 1;
    let queryPeriods = new Array(28).fill(0).map((v, index) => {
      const today = moment().add(index + 1, 'day');
      if (today.isoWeekday() !== 6 && today.isoWeekday() !== 7) {
        const obj = {
          start: moment(today)
            .hour(9)
            .minute(0)
            .second(0)
            .tz(this.timezone)
            .utc()
            .format(this.formatType),
          end: moment(today)
            .hour(17)
            .minute(0)
            .second(0)
            .tz(this.timezone)
            .utc()
            .format(this.formatType)
        };
        counter = counter + 1;
        return obj;
      }
    });

    queryPeriods = queryPeriods.filter(function(element) {
      return element !== undefined;
    });
    const queryPeriodForMangeAvailability = [
      {
        start: queryPeriods[0].start,
        end: queryPeriods[queryPeriods.length - 1].end
      }
    ];
    const avaliableSlots = {
      element_token: this.token,
      target_id: 'cronofy-slot-picker',
      availability_query: {
        participants: [
          {
            required: 'all',
            members: [
              {
                sub: this.cronofyId,
                managed_availability: true
              }
            ]
          }
        ],
        required_duration: { minutes: this.policyCallDuration },
        start_interval: { minutes: 30 },
        query_periods: queryPeriodForMangeAvailability
      },
      styles: {
        prefix: 'bb'
      },
      translations: {
        en: {
          slot_picker: {
            confirm: 'Schedule'
          }
        }
      },
      callback: res => {
        console.log('callback', res);
        if (res && res.notification && res.notification.type === 'slot_selected') {
          this.slotSelected.emit({
            slot: res.notification.slot,
            counter: this.counter
          });
        } else {
          slotpicker && slotpicker.update(nonAvailableSlots);
        }
      }
    };
    const nonAvailableSlots = {
      element_token: this.token,
      target_id: 'cronofy-slot-picker',
      availability_query: {
        participants: [
          {
            required: 'all',
            members: [
              {
                sub: this.cronofyId
              }
            ]
          }
        ],
        required_duration: { minutes: this.policyCallDuration },
        start_interval: { minutes: 30 },
        query_periods: queryPeriods
      },
      styles: {
        prefix: 'bb'
      },
      translations: {
        en: {
          slot_picker: {
            confirm: 'Schedule'
          }
        }
      },
      callback: res => {
        console.log('callback', res);
        if (res && res.notification && res.notification.type === 'slot_selected') {
          this.slotSelected.emit({
            slot: res.notification.slot,
            counter: this.counter
          });
        }
      }
    };
    const slotpicker = window['CronofyElements'].SlotPicker(avaliableSlots);
    // slotpicker.load();
  }

  getHourOffset(): any {
    const now = moment();
    const localOffset = now.utcOffset();
    now.tz(this.timezone);
    const centralOffset = now.utcOffset();
    const diffInMinutes = (localOffset - centralOffset) / 60;
    return diffInMinutes > 0 ? `+${diffInMinutes}` : diffInMinutes;
  }
}
