import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';

@Component({
  selector: 'app-cancel-add-bank-modal',
  templateUrl: './cancel-add-bank-modal.component.html',
  styleUrls: ['./cancel-add-bank-modal.component.scss']
})
export class CancelAddBankModalComponent implements OnInit {
  browserLanguage: string;

  constructor(
    public dialogRef: MatDialogRef<CancelAddBankModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly languageTranslationService: LanguageTranslationService,
    public translate: TranslateService
  ) {
    this.browserLanguage = this.languageTranslationService.getSupportedLanguage();
    translate.use(this.browserLanguage);
  }

  ngOnInit(): void {}

  resume() {
    this.dialogRef.close(false);
  }

  close() {
    this.dialogRef.close(true);
  }
}
