<mat-dialog-content>
  <div class="container" *ngIf="!data.isDashBoard">
    <span
      >{{ data.userType === 'Consultant' ? 'Transferee' : 'Consultant' }} has logged In <br />
      You will be Redirected to Dashboard in &nbsp;
      <span class="counter">{{ counter }}</span></span
    >
  </div>
  <div class="container" *ngIf="data.isDashBoard">
    <span
      >Co-browsing session is initiating<br />
      Please wait while we sync your data &nbsp;
      <span class="counter">{{ counter }}</span></span
    >
  </div>
</mat-dialog-content>
