import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { DialerComponent } from './dialer.component';
import { CountUpModule } from 'ngx-countup';
import { BenefitDonutChartModule } from './../../../public/benefits/modules/benefit-donut-chart/benefit-donut-chart.module';
// Translate Modules
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [DialerComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule, CountUpModule, BenefitDonutChartModule, TranslateModule],
  exports: [DialerComponent]
})
export class DialerModule {}
