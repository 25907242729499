export const REPAYMENT_AGREEMENT_CONTENT = {
    paragraph1: (clientName: string, totalNumberOfRelocatingMembers: number) => `Prior to receiving relocation benefits from ${clientName} for me${totalNumberOfRelocatingMembers > 1 ? ` and my family` : ''} in for the form of a lump sum allowance, I acknowledge and agree to the following conditions:`,
    paragraph2: (clientName: string) => `I agree that it is my intention to remain with ${clientName} for the defined period after my relocation.`,
    paragraph3: (clientName: string, minimumDuration: number) => `The relocation benefits that have been and/or will be extended to me by ${clientName}, or one of its subsidiaries and affiliated entities (collectively, the “Company”), are conditional upon my continued employment with ${clientName} for a minimum of ${minimumDuration} months from the “Relocation Date” as defined in the Company’s relocation policy.`,
    paragraph4: (clientName: string) => `If I voluntarily terminate my employment or if my employment is terminated by the Company for cause within the defined period(s) noted in the Repayment Schedule, I will repay to ${clientName} the portion of the lump sum relocation allowance provided to me or on my behalf plus any applicable taxes withheld based on the minimum employment period completed. This Repayment Amount must be paid within the period specified in the relocation policy.`,
    paragraph5: () => `This repayment agreement is considered satisfied at the earlier of completing the defined retention period or entrance into a new repayment agreement as a result of a subsequent move. Only one repayment agreement, identified as the most recently electrically confirmed or signed, will be in effect at any time.`,
    paragraph6: () => `I agree that the Company may make any deduction necessary from monies owing by the Company to me including any salary, wages, incentive compensation, bonuses or severance pay to satisfy this repayment obligation to the Company and hereby (a) consent to any such deduction and any other action that may be taken by the Company and as permitted by law, and (b) agree that I will sign any additional agreement or document that the Company deems necessary with respect to making this deduction or otherwise enforcing its right to receive the repayment amount.`,
    paragraph7: (clientName: string) => `${clientName} may ask an outside party to attempt the collection of the initial repayment amount or may use an outside collection agency to collect the repayment amount.`,
    paragraph8: (clientName: string) => `I also understand and agree to reimburse ${clientName} for any costs incurred as a result of enforcing this Repayment Agreement including but not limited to collection agency fees, court costs and or attorney’s fees.`,
    paragraph9: () => `The Company and I consent to conducting business via electronic transactions and recognize the validity, enforceability and admissibility of any electronic record or any electronic signature created in connection with this Lump Sum Relocation Allowance Repayment Agreement. An electronic record of this Agreement and any electronic signature made in connection with this Agreement shall be deemed to have been signed by hand by the Company and me.`,
    paragraph10: (clientName: string) => `This repayment does not constitute a contract of employment or a guarantee of employment. Employment is “at-will” at all times for ${clientName} and me, including the period defined in the relocation schedule.`,
    paragraph11: () => `I acknowledge that I have read, understand and agree to the repayment terms and conditions as outlined. By entering my Legal Name and selecting, I confirm, I am agreeing to the terms and conditions contained in this document.`
};

export const getMinimumDuration = (repaymentSchedules) => {
    return repaymentSchedules.reduce((max, obj) => {
        return obj.months > max ? obj.months : max;
    }, 0);
}