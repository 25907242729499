<mat-card class="card-body" appearance="outlined" fxFlex="100%" tabindex="0">
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <card-title type="relocation" imageUri="map-pin"></card-title>
    </div>
  </mat-card-title>
  <mat-card-content class="card-content" fxLayout="row" fxLayoutGap="12px" style="margin-top: 20px;">
    <div fxLayout="row" fxFlex="100%">
      <div fxFlex="100%" fxFlex.xs="100%" fxLayoutGap="20px">
        <mat-form-field class="first-name" fxFlex="45%" fxFlex.xs="50%">
          <mat-label>Estimated Arrival Date</mat-label>
          <input
            matInput
            [readonly]="isCoBrowsing"
            [formControl]="estimatedArrivalDateValue"
            [matDatepicker]="estimatedArrivalDate"
            (dateChange)="estimatedArrivalDateChange($event)"
          />
          <mat-datepicker-toggle matSuffix [for]="estimatedArrivalDate"></mat-datepicker-toggle>
          <mat-datepicker #estimatedArrivalDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="first-name" fxFlex="45%" fxFlex.xs="50%">
          <mat-label>Job Start Date</mat-label>
          <input matInput readonly [formControl]="jobStartDate" />
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
</mat-card>
