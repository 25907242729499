import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TransfereeNeedsAssessment } from 'src/app/core/models/candidateneeds-assessment.model';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { PdfGenerationService } from '../../services/pdf-generation.service';
import { REPAYMENT_AGREEMENT_CONTENT, getMinimumDuration } from '../../../../../assets/repayment-agreement-content';
import { CookieService } from 'ngx-cookie-service';
import { CollaborationService } from 'src/app/core/services/collaboration.service';
import { CollabEvents, CollabMessage } from 'src/app/core/services/collaboration-events.service';
import { DashboardService } from 'src/app/public/dashboard/services/dashboard.service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-repayment-agreement',
  templateUrl: './repayment-agreement.component.html',
  styleUrls: ['./repayment-agreement.component.scss']
})
export class RepaymentAgreementComponent implements OnInit, OnDestroy {
  clientName = null;
  firstName = null;
  lastName = null;
  moveOrderID = null;
  departureFrom = null;
  destinationTo = null;
  familyMembersCount = 0;
  lumpsumDetails: any;
  lumpsumDetailsSubscription: Subscription;
  needsAssessmentSubscription: Subscription;
  repayFormGroup: FormGroup;
  isUploadRequired = true;
  isReapyCompleted: boolean;
  updatedRepayContent: any;
  custFileID: string;
  isCoBrowsing = false;
  collabSubscription: Subscription;
  orderRequestId: string;
  repaymentMilestoneSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private needsAssessment: NeedsAssessmentSharedService,
    public readonly pdfgenerator: PdfGenerationService,
    private formBuilder: FormBuilder,
    public cookieService: CookieService,
    public readonly collaboration: CollaborationService,
    private dashboardService: DashboardService,
    private readonly spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.checkForNeedAssessmentData();
    this.getLumpSumData();
    this.createForm();
    this.repaymentMilestoneSubscription = this.pdfgenerator.repaymentMilestone$.subscribe(isReapyCompleted => {
      this.isReapyCompleted = isReapyCompleted;
      if (!this.isCoBrowsing && isReapyCompleted) {
        this.collaboration.sendMessage({
          data: isReapyCompleted,
          event: CollabEvents.REPAYMENT_AGREEMENT_STATUS_COMPLETE
        });
      }
    });
    this.updateContent();
    this.isCoBrowsing = this.cookieService.get('transferee-context') ? true : false;
    this.observeCollab();
  }

  createForm() {
    this.repayFormGroup = this.formBuilder.group({
      legalName: ['', Validators.required],
      dateOfSignature: [moment(new Date()).format('MM/DD/YYYY'), Validators.required],
      conscentChecked: [false, this.consentCheckBoxValidator()]
    });
  }

  consentCheckBoxValidator(): ValidatorFn {
    return control => {
      return control.value ? null : { required: true };
    };
  }

  ngOnDestroy() {
    if (this.needsAssessmentSubscription) {
      this.needsAssessmentSubscription.unsubscribe();
    }

    if (this.lumpsumDetailsSubscription) {
      this.lumpsumDetailsSubscription.unsubscribe();
    }

    if (this.repaymentMilestoneSubscription) this.repaymentMilestoneSubscription.unsubscribe();
  }

  checkForNeedAssessmentData() {
    this.needsAssessmentSubscription = this.needsAssessment.transfereeNeedsAssessmentDetails.subscribe(data => {
      if (!data) {
        return;
      }
      const needsAssessment: TransfereeNeedsAssessment = data;
      this.firstName = needsAssessment.nameDetails.firstName;
      this.lastName = needsAssessment.nameDetails.lastName;
      this.clientName = needsAssessment.clientEntityName;
      this.moveOrderID = needsAssessment.orderRequestId;
      this.familyMembersCount = needsAssessment.totalNumberOfRelocatingMembers;
      this.departureFrom =
        needsAssessment.departureAddr.city + '/' + needsAssessment.departureAddr.state + '/' + needsAssessment.departureAddr.country;
      this.destinationTo =
        needsAssessment.destinationAddr.city + '/' + needsAssessment.destinationAddr.state + '/' + needsAssessment.destinationAddr.country;
      this.custFileID = needsAssessment.custFileID;
      this.orderRequestId = data.orderRequestId;
    });
  }

  getLumpSumData(collabData?: boolean) {
    this.lumpsumDetailsSubscription = this.needsAssessment.transfereeRepaymentLumpSumDetails.subscribe(data => {
      if (!data) {
        return;
      }
      this.lumpsumDetails = data;
      this.isReapyCompleted = !!this.lumpsumDetails?.repay?.repayAcceptedDate;
      if (this.isCoBrowsing && collabData) this.isReapyCompleted = collabData;
      if (!this.isCoBrowsing && this.isReapyCompleted) this.pdfgenerator.isRepaymentMilestoneAdded.next(this.isReapyCompleted);
    });
  }

  onRepayAgrementAcceptance() {
    this.pdfgenerator.generatePdfAndUpload(this.lumpsumDetails, this.repayFormGroup.value);
  }

  goToDocumentsPage() {
    this.router.navigate(['/document-library'], { queryParams: { orderRequest: this.moveOrderID } });
  }

  updateContent() {
    this.updatedRepayContent = {
      paragraph1: REPAYMENT_AGREEMENT_CONTENT.paragraph1(this.clientName, this.familyMembersCount),
      paragraph2: REPAYMENT_AGREEMENT_CONTENT.paragraph2(this.clientName),
      paragraph3: REPAYMENT_AGREEMENT_CONTENT.paragraph3(
        this.clientName,
        getMinimumDuration(this.lumpsumDetails?.repay?.repaymentSchedules)
      ),
      paragraph4: REPAYMENT_AGREEMENT_CONTENT.paragraph4(this.clientName),
      paragraph5: REPAYMENT_AGREEMENT_CONTENT.paragraph5(),
      paragraph6: REPAYMENT_AGREEMENT_CONTENT.paragraph6(),
      paragraph7: REPAYMENT_AGREEMENT_CONTENT.paragraph7(this.clientName),
      paragraph8: REPAYMENT_AGREEMENT_CONTENT.paragraph8(this.clientName),
      paragraph9: REPAYMENT_AGREEMENT_CONTENT.paragraph9(),
      paragraph10: REPAYMENT_AGREEMENT_CONTENT.paragraph10(this.clientName),
      paragraph11: REPAYMENT_AGREEMENT_CONTENT.paragraph11()
    };
  }

  observeCollab() {
    this.collabSubscription = this.collaboration.socketSubscriber$.subscribe((message: CollabMessage) => {
      if (!message) {
        return;
      }
      if (message.data && message.data.event && message.data.event === CollabEvents.REPAYMENT_AGREEMENT_STATUS_COMPLETE) {
        if (message.data.data) this.getLumpSumData(message.data.data);
      }
    });
  }
}
