export enum CollabEvents {
  USER_JOINED = 'USER_JOINED',
  BENEFIT_ACTION_ADD = 'BENEFIT_ACTION_ADD',
  BENEFIT_ACTION_DELETE = 'BENEFIT_ACTION_DELETE',
  BENEFIT_ACTION_BULK_DELETE = 'BENEFIT_ACTION_BULK_DELETE',
  BENEFIT_ACTION_VIEW = 'BENEFIT_ACTION_VIEW',
  BENEFIT_ACTION_VIEW_CLOSE = 'BENEFIT_ACTION_VIEW_CLOSE',
  BENEFIT_ACTION_ACTIVATE = 'BENEFIT_ACTION_ACTIVATE',
  BENEFIT_ACTION_DEACTIVATE = 'BENEFIT_ACTION_DEACTIVATE',
  BENEFIT_ACTION_ACTIVATE_SELECT = 'BENEFIT_ACTION_ACTIVATE_SELECT',
  BENEFIT_ACTION_DEACTIVATE_REMOVE = 'BENEFIT_ACTION_DEACTIVATE_REMOVE',
  BENEFIT_ACTION_CATEGORY = 'BENEFIT_ACTION_CATEGORY',
  BENEFIT_ACTION_MOBILE_OPEN = 'BENEFIT_ACTION_MOBILE_OPEN',
  BENEFIT_ACTION_MOBILE_CLOSE = 'BENEFIT_ACTION_MOBILE_CLOSE',
  BENEFIT_NAV_AVAILABLE = 'BENEFIT_NAV_AVAILABLE',
  BENEFIT_NAV_CONFIRMED = 'BENEFIT_NAV_CONFIRMED',
  BENEFIT_NAV_REVIEW = 'BENEFIT_NAV_REVIEW',
  BENEFIT_NAV_DASHBOARD = 'BENEFIT_NAV_DASHBOARD',
  BENEFIT_ACTION_REVIEW_DOWNLOAD = 'BENEFIT_ACTION_REVIEW_DOWNLOAD',
  BENEFIT_ACTION_CONFIRMED_ACCORDION = 'BENEFIT_ACTION_CONFIRMED_ACCORDION',
  BENEFIT_ACTION_CONFIRMED_TOGGLE_CARD = 'BENEFIT_ACTION_CONFIRMED_TOGGLE_CARD',
  BENEFIT_ACTION_CONFIRMED_DOWNLOAD = 'BENEFIT_ACTION_CONFIRMED_DOWNLOAD',
  BENEFIT_ACTION_CONFIRMED_DELETE = 'BENEFIT_ACTION_CONFIRMED_DELETE',
  USER_LOGGED_OUT = 'USER_LOGGED_OUT',
  SELECT_BENEFIT_ACTION_VIEW = 'SELECT_BENEFIT_ACTION_VIEW',
  SELECT_BENEFIT_ACTION_VIEW_CLOSE = 'SELECT_BENEFIT_ACTION_VIEW_CLOSE',
  SELECT_BENEFIT_ACTION_ADD = 'SELECT_BENEFIT_ACTION_ADD',
  BENEFIT_ACTION_CONFIRMED_TASK_UPDATE = 'BENEFIT_ACTION_CONFIRMED_TASK_UPDATE',
  BENEFIT_ACTION_COMPLETED_ACCORDION = 'BENEFIT_ACTION_COMPLETED_ACCORDION',
  BENEFIT_ACTION_COMPLETED_TOGGLE_CARD = 'BENEFIT_ACTION_COMPLETED_TOGGLE_CARD',
  BENEFIT_ACTION_HIGH_VALUE_GOODS_DOWNLOAD = 'BENEFIT_ACTION_HIGH_VALUE_GOODS_DOWNLOAD',
  MOVE_ORDER_CHANGE = 'MOVE_ORDER_CHANGE',
  MMU_SIMPLE_BENEFIT_CURRENCY_SELECTION = 'MMU_SIMPLE_BENEFIT_CURRENCY_SELECTION',
  MMU_SIMPLE_BENEFIT_AMOUNT_SELECTION = 'MMU_SIMPLE_BENEFIT_AMOUNT_SELECTION',
  MMU_COMPLEX_BENEFIT_ADD_COUNT_BUTTON = 'MMU_COMPLEX_BENEFIT_ADD_COUNT_BUTTON',
  MMU_COMPLEX_BENEFIT_SUBSTRACT_COUNT_BUTTON = 'MMU_COMPLEX_BENEFIT_SUBSTRACT_COUNT_BUTTON',
  MMU_COMPLEX_BENEFIT_CALCULATE_COST_ESTIMATE_BUTTON = 'MMU_COMPLEX_BENEFIT_CALCULATE_COST_ESTIMATE_BUTTON',
  MMU_COMPLEX_BENEFIT_WITH_SUBPRODUCT_ADD_COUNT_BUTTON = 'MMU_COMPLEX_BENEFIT_WITH_SUBPRODUCT_ADD_COUNT_BUTTON',
  MMU_COMPLEX_BENEFIT_WITH_SUBPRODUCT_SUBSTRACT_COUNT_BUTTON = 'MMU_COMPLEX_BENEFIT_WITH_SUBPRODUCT_SUBSTRACT_COUNT_BUTTON',
  MMU_COMPLEX_BENEFIT_WITH_SUBPRODUCT_CALCULATE_COST_ESTIMATE_BUTTON = 'MMU_COMPLEX_BENEFIT_WITH_SUBPRODUCT_CALCULATE_COST_ESTIMATE_BUTTON',
  MMU_COMPLEX_BENEFIT_WITH_SUBPRODUCT_CALCULATE_COST_ESTIMATE_NODATA = 'MMU_COMPLEX_BENEFIT_WITH_SUBPRODUCT_CALCULATE_COST_ESTIMATE_NODATA',
  MMU_COMPLEX_BENEFIT_WITH_SUBPRODUCT_CALCULATE_COST_ESTIMATE_ERROR = 'MMU_COMPLEX_BENEFIT_WITH_SUBPRODUCT_CALCULATE_COST_ESTIMATE_ERROR',
  REPAYMENT_AGREEMENT_STATUS_COMPLETE = 'REPAYMENT_AGREEMENT_STATUS_COMPLETE',
  BANK_INFORMATION_STATUS_COMPLETE = 'BANK_INFORMATION_STATUS_COMPLETE',
  REQUEST_LUMPSUM = 'REQUEST_LUMPSUM',
  BANK_ACCOUNT_INFO_WIDGET_UPDATE = 'BANK_ACCOUNT_INFO_WIDGET_UPDATE'
}

export interface CollabMessage {
  data: {
    event: CollabEvents;
    data: any;
  };
  roomId: string;
  from: 'consultant' | 'transferee';
}
