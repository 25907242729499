<div
  fxLayout="row"
  fxFlex="100%"
  class="benefits-dialer-wrapper"
  fxLayoutAlign="space-evenly center"
  fxShow="true"
  fxShow.sm="false"
  fxShow.xs="false"
  role="group"
  tabindex="0"
  *ngIf="!isDashBoard"
>
  <div fxLayout="column" fxFlex="60%" fxLayoutAlign="flex-start flex-start" role="group" class="total-points-category-address-div">
    <div class="total-points" role="note">
      <p class="move-details">
        {{ 'bb.dialer.moveLocations' | translate }}
      </p>
      <p class="move-location">
        {{ moveData?.departureAddr.state }} , {{ moveData?.departureAddr.countryCode }} > {{ moveData?.destinationAddr.state }} ,
        {{ moveData?.destinationAddr.countryCode }}
        <span class="tooltiptext"
          >{{ 'bb.dialer.moveLocations' | translate }}: <br />{{ moveData?.departureAddr.streetLine1 }},<br />
          {{ moveData?.departureAddr.city }}, {{ moveData?.departureAddr.state }}, {{ moveData?.departureAddr.countryCode }},
          {{ moveData?.departureAddr.postalCode }} > <br />
          {{ moveData?.destinationAddr.streetLine1 }},<br />
          {{ moveData?.destinationAddr.city }}, {{ moveData?.destinationAddr.state }}, {{ moveData?.destinationAddr.countryCode }},
          {{ moveData?.destinationAddr.postalCode }}</span
        >
      </p>
    </div>
    <div fxLayout="row">
      <div class="total-points" [attr.aria-label]="'Total Points ' + totalPoints" role="note">
        <span class="rem-points-span" *ngIf="!isBudgetModel"
          ><p class="f-s-44">
            {{ totalPoints - totalPointsSelected }}
          </p>
          <p class="txtOfPoints">{{ 'bb.dialer.of' | translate }} {{ totalPoints }}</p></span
        >
        <p *ngIf="!isBudgetModel">
          {{ 'bb.dialer.totalPoints' | translate }}
        </p>
        <span class="rem-amount-span" *ngIf="isBudgetModel">
          <span fxLayout="row">
            <p
              [ngClass]="{
                'f-s-22': lengthOfTotalAmount <= 6,
                'f-s-15': lengthOfTotalAmount > 6
              }"
              [countUp]="totalAmount - totalAmountSelected"
              [options]="dialerCountOptions"
            ></p>
            <p
              [ngClass]="{
                'f-s-22': lengthOfTotalAmount <= 6,
                'f-s-15': lengthOfTotalAmount > 6
              }"
            >
              &nbsp;{{ currency }}
            </p>
          </span>
          <span fxLayout="row" fxLayoutAlign="center center">
            <p
              [ngClass]="{
                txtOfAmount: lengthOfTotalAmount <= 6,
                'txtOfAmount-small': lengthOfTotalAmount > 6
              }"
            >
              {{ 'bb.dialer.of' | translate }}&nbsp;
            </p>
            <p
              [ngClass]="{
                txtOfAmount: lengthOfTotalAmount <= 6,
                'txtOfAmount-small': lengthOfTotalAmount > 6
              }"
              [countUp]="totalAmount"
              [options]="dialerCountOptions"
            ></p>
            <p
              [ngClass]="{
                txtOfAmount: lengthOfTotalAmount <= 6,
                'txtOfAmount-small': lengthOfTotalAmount > 6
              }"
            >
              &nbsp;{{ currency }}
            </p>
          </span>
        </span>
        <p *ngIf="isBudgetModel">
          {{ 'bb.dialer.totalAmount' | translate }}
        </p>
        <!-- <p class="f-s-44" *ngIf="isBudgetModel"[countUp]="totalAmount" [options]="dialerCountOptions"></p> -->
        <!-- <p *ngIf="isBudgetModel">{{'bb.dialer.totalBudget' | translate}} {{currency}}</p> -->
      </div>
      <div class="legends" role="group">
        <!-- <div class=" scroll-bar"> -->
        <div *ngFor="let filter of filters; let i = index">
          <p>
            <span class="categories" [style.background-color]="benefitByColors[filter.en]"></span
            ><span>{{ filters[i].tr && filters[i].tr !== undefined ? filters[i].tr : filters[i].en }}</span>
          </p>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
  <div class="chart-wrapper" fxLayout="row" fxLayoutAlign="center center" role="group" *ngIf="!isDashBoard">
    <app-benefit-donut-chart *ngIf="!isDashBoard"></app-benefit-donut-chart>
    <div class="chart-text" fxLayout="row" fxLayoutAlign="center center" role="note">
      <p [attr.aria-label]="'Total Points Selected ' + totalPointsConfirmed" role="note">
        <span class="no-of-benefits" *ngIf="!isBudgetModel" [countUp]="totalPointsConfirmed" [options]="dialerCountOptions"></span>
        <span
          [ngClass]="{
            'no-of-benefits': lengthOfBudget <= 5,
            'selected-amount': lengthOfBudget > 5 && lengthOfBudget < 7,
            'small-selected-amount': lengthOfBudget > 7
          }"
          *ngIf="isBudgetModel"
          [countUp]="totalAmountConfirmed"
          [options]="dialerCountOptions"
        ></span>
        <span class="points-selected" *ngIf="!isBudgetModel">{{ 'bb.dialer.points' | translate }}</span>
        <span class="points-selected" *ngIf="!isBudgetModel">{{ 'bb.dialer.Redeemed' | translate }}</span>
        <span class="points-selected" *ngIf="isBudgetModel">{{ currency }}</span>
        <span class="points-selected" *ngIf="isBudgetModel">{{ 'bb.dialer.Redeemed' | translate }}</span>
      </p>
    </div>
  </div>
</div>

<div
  fxFlex="100%"
  [ngClass]="showDialer ? 'dialer height180' : 'dialer height69'"
  fxShow="false"
  fxShow.sm="true"
  fxShow.xs="true"
  fxLayout="column"
  *ngIf="!isDashBoard"
>
  <div>
    <p class="move-details">
      {{ 'bb.dialer.moveLocations' | translate }}: {{ moveData?.departureAddr.state }} , {{ moveData?.departureAddr.countryCode }} >
      {{ moveData?.destinationAddr.state }} ,
      {{ moveData?.destinationAddr.countryCode }}
    </p>
  </div>
  <div class="pos-rel" fxLayoutAlign="space-between center" [fxShow]="!showDialer" role="group">
    <div class="total-points" [attr.aria-label]="'Total Points ' + totalPoints" role="note">
      <span class="rem-points-span"
        ><p class="f-s-30" *ngIf="!isBudgetModel">
          {{ totalPoints - totalPointsSelected }}
        </p>
        <p class="txtOfPoints">of {{ totalPoints }}</p></span
      >
      <p *ngIf="!isBudgetModel">
        {{ 'bb.dialer.totalPoints' | translate }}
      </p>
      <p class="f-s-44" *ngIf="isBudgetModel" [countUp]="totalAmount" [options]="dialerCountOptions"></p>
      <p *ngIf="isBudgetModel">{{ 'bb.dialer.totalBudget' | translate }} {{ currency }}</p>
    </div>
    <div *ngIf="!isBudgetModel" class="progress-bar-wrapper" fxFlex="50%" fxLayoutAlign="start center">
      <div class="progress-bar" [style.width]="(totalPointsSelected / totalPoints) * 100 + '%'"></div>
      <span class="progress-percent" [countUp]="totalPointsConfirmed" [options]="dialerCountOptions"></span>
    </div>
    <div *ngIf="isBudgetModel" class="progress-bar-wrapper" fxFlex="50%" fxLayoutAlign="start center">
      <div class="progress-bar" [style.width]="(totalAmountSelected / totalAmount) * 100 + '%'"></div>
      <span class="progress-percent" [countUp]="totalAmountSelected" [options]="dialerCountOptions"></span>
    </div>
    <button class="mat-icon-wrapper" (click)="showDialer = !showDialer" aria-label="Toggle Dialer height">
      <mat-icon class="arrow-down" aria-hidden="false" aria-label="arrow icon">keyboard_arrow_down</mat-icon>
    </button>
    <p *ngIf="!isBudgetModel" class="points-selected-text">
      {{ 'bb.dialer.pointsSelected' | translate }}
    </p>
    <p *ngIf="isBudgetModel" class="points-selected-text">{{ 'bb.dialer.totalRemaining' | translate }} {{ currency }}</p>
  </div>
  <div
    fxLayout="row"
    fxFlex="100%"
    class="benefits-dialer-wrapper"
    fxLayoutAlign.sm="space-between center"
    fxLayoutAlign.xs="start end"
    [fxShow]="showDialer"
    *ngIf="!isDashBoard"
  >
    <button class="mat-icon-wrapper up-icon" (click)="showDialer = !showDialer">
      <mat-icon class="arrow-up" aria-hidden="false" aria-label="arrow icon">keyboard_arrow_up</mat-icon>
    </button>
    <div fxFlex="50%" role="group" [attr.aria-label]="'Total Points ' + totalPoints" role="none">
      <div class="legends">
        <p fxLayout="row" fxLayoutAlign="start center" *ngIf="!isBudgetModel">
          <span class="total-points-text" fxFlex="20%" fxFlex.sm="10%" fxFlex.xs="10%">{{ 'bb.dialer.totalPoints' | translate }}</span>
          <span class="total-points" fxFlex="15%" [countUp]="totalPoints" [options]="dialerCountOptions"></span>
        </p>
        <p fxLayout="row" fxLayoutAlign="start center" *ngIf="isBudgetModel">
          <span class="total-points-text" fxFlex="20%" fxFlex.sm="10%" fxFlex.xs="10%"
            >{{ 'bb.dialer.totalBudget' | translate }}
            {{ currency }}
          </span>
          <span class="total-points-mobile" fxFlex="15%" [countUp]="totalAmount" [options]="dialerCountOptions"></span>
        </p>
        <div *ngFor="let filter of filters; let i = index">
          <p>
            <span class="categories" [style.background-color]="benefitByColors[filter.en]"></span
            ><span>{{ filters[i].tr && filters[i].tr !== undefined ? filters[i].tr : filters[i].en }}</span>
          </p>
        </div>
      </div>
    </div>
    <div
      class="chart-wrapper"
      fxLayout="row"
      fxLayoutAlign="center center"
      role="group"
      [attr.aria-label]="'Total Points Selected ' + totalPointsSelected"
      *ngIf="!isDashBoard"
    >
      <app-benefit-donut-chart></app-benefit-donut-chart>
      <div class="chart-text" fxLayout="row" fxLayoutAlign="center center" role="none">
        <p *ngIf="!isBudgetModel">
          <span class="no-of-benefits" [countUp]="totalPointsSelected" [options]="dialerCountOptions"></span>
          <span class="points-selected">{{ 'bb.dialer.points' | translate }}</span>
          <span class="points-selected">{{ 'bb.dialer.Redeemed' | translate }}</span>
        </p>
        <p *ngIf="isBudgetModel">
          <span class="no-of-benefits" [countUp]="totalAmountSelected" [options]="dialerCountOptions"></span>
          <span class="points-selected">{{ currency }}</span>
          <span class="points-selected">{{ 'bb.dialer.Redeemed' | translate }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isDashBoard && isBudgetModel">
  <div
    fxLayout="row"
    class="benefits-dialer-wrapper mm-dialer"
    fxShow="true"
    fxShow.sm="false"
    fxShow.xs="false"
    tabindex="0"
    *ngIf="isDashBoard && isBudgetModel"
  >
    <div role="group">
      <div class="total-points mrg-l-10" [attr.aria-label]="'Total Points ' + totalPoints" role="note">
        <p class="move-details">
          {{ 'bb.dialer.moveLocations' | translate }}
        </p>
        <p class="move-location">
          {{ moveData?.departureAddr.state }} , {{ moveData?.departureAddr.countryCode }} > {{ moveData?.destinationAddr.state }} ,
          {{ moveData?.destinationAddr.countryCode }}
          <span class="tooltiptext"
            >{{ 'bb.dialer.moveLocations' | translate }}: <br />{{ moveData?.departureAddr.streetLine1 }},<br />
            {{ moveData?.departureAddr.city }}, {{ moveData?.departureAddr.state }}, {{ moveData?.departureAddr.countryCode }},
            {{ moveData?.departureAddr.postalCode }} > <br />
            {{ moveData?.destinationAddr.streetLine1 }},<br />
            {{ moveData?.destinationAddr.city }}, {{ moveData?.destinationAddr.state }}, {{ moveData?.destinationAddr.countryCode }},
            {{ moveData?.destinationAddr.postalCode }}</span
          >
        </p>
      </div>
    </div>
  </div>
  <div fxLayout="column" role="group">
    <div class="total-amount mrg-l-10">
      <div style="padding-top: 16px;">
        <p class="f-s-30" *ngIf="!isBudgetModel" [countUp]="totalPoints" [options]="dialerCountOptions"></p>
        <p *ngIf="!isBudgetModel">
          {{ 'bb.dialer.totalPoints' | translate }}
        </p>
        <p class="f-s-30" *ngIf="isBudgetModel" [countUp]="totalAmount" [options]="dialerCountOptions" fxHide.xs fxShow></p>
        <p *ngIf="isBudgetModel" fxHide.xs fxShow>
          {{ 'bb.dialer.totalBudget' | translate }}
        </p>
        <p *ngIf="isBudgetModel" fxHide.xs fxShow>{{ currency }}</p>
      </div>
    </div>
    <div class="actual-cost-estimate" fxHide.xs fxShow>
      <div class="actual-display">
        {{ 'bb.dialer.actualBudgetSpent' | translate }} <br /><span [countUp]="actualSpend" [options]="actualDialerCountOptions"></span>
        <span style="padding-left: 6px;">{{ currency }}</span>
      </div>
      <div class="actual-display">
        {{ 'bb.dialer.actualBudgetRemaining' | translate }} <br /><span
          [countUp]="actualRemaining"
          [options]="actualDialerCountOptions"
        ></span>
        <span style="padding-left: 6px;">{{ currency }}</span>
      </div>
    </div>
  </div>
</div>
