<mat-card appearance="outlined" class="contact-card" *ngIf="!isLoading">
  <mat-card-title fxLayout.sm="row" fxLayout.xs="row">
    <card-title name="My Contacts" type="contacts" imageUri="Contact" aria-label="My Contacts" role="heading" aria-level="2"></card-title>
    <p
      class="m-0 margin0"
      fxHide="false"
      fxHide.xs="true"
      fxHide.sm="true"
      [attr.aria-label]="'Order reference ' + orderRefNum"
      role="note"
    >
      <span class="contact-count">({{ accordinData.length + 1 }})</span>
      <span class="order">{{ 'bb.contacts.order' | translate }} </span>
      <span class="order-no">{{ orderRefNum }}</span>
    </p>
  </mat-card-title>
  <mat-card-content class="card-content" fxLayoutGap="10px" fxLayoutGap.sm="0px" fxLayoutGap.xs="0px">
    <p class="order-no" fxHide="true" fxHide.sm="false" fxHide.xs="false" [attr.aria-label]="'Order reference' + orderRefNum" role="note">
      <span class="order">{{ 'bb.contacts.order' | translate }} </span>
      <span class="order-no">{{ orderRefNum }}</span>
    </p>
    <!-- Individual Contact Card -->
    <!-- <div class="card">
            <div fxLayout="row">
                <div fxFlex="27%" class="contact-card-left-content" fxLayout="column" fxLayoutAlign="center center">
                    <img src="../../../../../assets/images/user-icon.svg" alt="bus" />
                    <span aria-label="Consultant Details" class="consultantDetails" role="note">{{
                        'bb.contacts.consultantDetails' | translate}}</span>
                </div>
                <div class="contact-card-right-content" fxFlex="73%">
                    <div class="content">
                        <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                            <mat-icon class="person-icon" aria-hidden="false" aria-label="person icon">person</mat-icon>
                            <span [attr.aria-label]=" consultantDetails.firstName + ' '+ consultantDetails.lastName" role="note">{{consultantDetails.firstName}} {{consultantDetails.lastName}}</span>
                            <span><a (click)="propertyDetailsModal()">View Property Details</a></span> 
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                            <mat-icon class="call-icon" aria-hidden="false" aria-label="call icon">call</mat-icon>
                            <span>(+{{consultantDetails.phoneDetailsList[0].phoneDialingCode}})
                                {{consultantDetails.phoneDetailsList[0].phoneNumber | formatPhoneNumber}}</span>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                            <mat-icon class="email-icon" aria-hidden="false" aria-label="email icon">alternate_email
                            </mat-icon>
                            <span>{{consultantDetails.emailDetailsList[0].emailAddress}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div> -->

    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <mat-card-content fxFlex="98%" fxLayoutAlign="center start">
          <div class="card">
            <div fxLayout="row">
              <div fxFlex="27%" class="contact-card-left-content" fxLayout="column" fxLayoutAlign="center center">
                <img src="../../../../../assets/images/user-icon.svg" alt="bus" />
                <span aria-label="Consultant Details" class="consultantDetails" role="note">{{
                  'bb.contacts.consultantDetails' | translate
                }}</span>
              </div>
              <div class="contact-card-right-content" fxFlex="73%">
                <div class="content consultant-card">
                  <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                    <mat-icon aria-hidden="false" aria-label="person icon">person</mat-icon>
                    <span [attr.aria-label]="consultantDetails.firstName + ' ' + consultantDetails.lastName" role="note"
                      >{{ consultantDetails.firstName }} {{ consultantDetails.lastName }}</span
                    >
                  </p>
                  <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                    <mat-icon class="call-icon" aria-hidden="false" aria-label="call icon">call</mat-icon>
                    <span *ngIf="consultantDetails.phoneDetailsList.length > 0">
                      (+{{
                        consultantDetails.phoneDetailsList.length > 0 && consultantDetails.phoneDetailsList[0].phoneDialingCode
                          ? consultantDetails.phoneDetailsList[0].phoneDialingCode
                          : ''
                      }})
                      {{
                        consultantDetails.phoneDetailsList.length > 0 && consultantDetails.phoneDetailsList[0].phoneNumber
                          ? (consultantDetails.phoneDetailsList[0].phoneNumber | formatPhoneNumber)
                          : ''
                      }}</span
                    >
                  </p>
                  <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                    <mat-icon class="email-icon" aria-hidden="false" aria-label="email icon">alternate_email </mat-icon>
                    <span>{{
                      consultantDetails.emailDetailsList.length > 0 && consultantDetails.emailDetailsList[0].emailAddress
                        ? consultantDetails.emailDetailsList[0].emailAddress
                        : ''
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </ng-template>
      <!-- Individual Contact Card -->
      <ng-container *ngFor="let task of accordinData; let j = index">
        <ng-template carouselSlide>
          <mat-card-content fxFlex="98%" fxLayoutAlign="center start">
            <div class="card my-card">
              <div fxLayout="row">
                <div fxFlex="27%" class="contact-card-left-content" fxLayout="column" fxLayoutAlign="center center">
                  <img [src]="iconLink" class="card-image" alt="bus" />
                  <span aria-label="Consultant Details" class="tempLivingDetails" role="note">{{
                    'bb.contacts.tempHousing' | translate
                  }}</span>
                </div>
                <div class="contact-card-right-content consultant-card-temp" fxFlex="93%">
                  <div class="my-card-details">
                    <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                      {{ task?.bookingInfo?.propertyName ? task?.bookingInfo?.propertyName : 'NA' }}
                    </p>
                    <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                      <mat-icon aria-hidden="false" aria-label="location icon" class="location-icon">location_on </mat-icon>
                      <span role="note">{{ task?.bookingInfo?.apartmentType ? task?.bookingInfo?.apartmentType : 'NA' }} </span>
                    </p>
                    <p fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="8px" class="property-details">
                      <a (click)="propertyDetailsModal(j)">{{ 'bb.contacts.viewPropertyDetails' | translate }}</a>
                    </p>
                    <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
                      <img class="calender-icon" src="../../../../../assets/images/calendar-day-blue.svg" alt="calender" />
                      <span class="move-Dates"
                        >{{ task?.bookingInfo?.moveDates?.moveIn ? (task?.bookingInfo?.moveDates?.moveIn.split('T'))[0] : 'NA' }}
                        {{ 'bb.contacts.to' | translate }}
                        {{ task?.bookingInfo?.moveDates?.moveOut ? (task?.bookingInfo?.moveDates?.moveOut.split('T'))[0] : 'NA' }}</span
                      >
                    </p>
                    <p fxLayout="row" fxLayoutAlign="center start">
                      {{ 'bb.contacts.dateEstimates' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </ng-template>
      </ng-container>
      <!-- </mat-card-content> -->
    </owl-carousel-o>
  </mat-card-content>
</mat-card>
