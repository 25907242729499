import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FamilyMember } from './../models/family-member.model';
import { BaseClientService } from './base-client.service';
import { catchError, map, delay } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class FamilyInfoService {
  /** Instance of BehaviorSubject of Array of Family member for family information*/
  familyInformation = new BehaviorSubject<FamilyMember[]>(null);
  familyInfo = this.familyInformation.asObservable();

  /** Instance of BehaviorSubject of type Family member for family information*/
  familyMemberInformation = new BehaviorSubject<FamilyMember>(null);

  /**
   * @param baseClientService Inject base client service
   */
  constructor(private readonly baseClientService: BaseClientService) {
    this.getFamilyMemberInformation();
  }

  /**
   * Function to Update the familyinformation subject
   * @param data updated familyinformation
   */
  updateFamilyInfo(data: FamilyMember[]) {
    this.familyInformation.next(data);
  }

  /**
   * Function to Get family member information and update Shared-Service
   */
  getFamilyMemberInformation() {
    if (!this.familyInformation.getValue()) {
      this.getFamilyMemberInfo().subscribe(data => {
        if (data) {
          this.updateFamilyInfo(data);
        }
      });
    } else {
      return this.familyInformation.getValue();
    }
  }

  /**
   * Get Family information using endpoint
   */
  getFamilyMemberInfo(): Observable<FamilyMember[]> {
    return this.baseClientService.getById<FamilyMember[]>(`/v1/profile/family`).pipe(
      map(r => r.body),
      catchError(err => {
        console.log('Failed to get family details', err);
        const emptyResp: FamilyMember[] = null;
        return of(emptyResp);
      })
    );
  }
  /**
   * Add Family Member information
   */
  adddFamilyMemberDetails(familyMemberDetail: FamilyMember): Observable<any> {
    return this.baseClientService.post<FamilyMember>(`/v1/profile/family/`, familyMemberDetail).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: FamilyMember = null;
        console.log('Failed to add family details', err);
        return of(empty);
      })
    );
  }
  /**
   * Update Family Member information
   */
  updateFamilyMemberDetails(familyMemberId, familyMemberDetail: FamilyMember): Observable<any> {
    return this.baseClientService.put<FamilyMember>(`/v1/profile/family/${familyMemberId}`, familyMemberDetail).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: FamilyMember = null;
        console.log('Failed to update family details', err);
        return of(empty);
      })
    );
  }
  /**
   * Delete Family information using endpoint
   */
  deleteFamilyMemberInfo(familyMemberId) {
    return this.baseClientService.delete(`/v1/profile/family/${familyMemberId}`).pipe(
      map(r => r.status),
      catchError(err => {
        // console.log('Failed to delete family details', err);
        const emptyResp: FamilyMember = null;
        return of(emptyResp);
      })
    );
  }
}
