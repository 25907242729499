<mat-dialog-content>
  <div class="cancel-addd-bank-modal-wrap">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mat-dialog-title">
      <h2>Unsaved Changes</h2>
    </div>
    <div class="mat-dialog-content">
      <div fxLayout="row" fxFlex="100%">
        <p class="info-message">
          {{ 'bb.lumpsumDashboard.addBank.cancelAddBank' | translate }}
        </p>
      </div>
    </div>
    <div class="mat-dialog-actions">
      <div
        class="filter-buttons"
        fxLayout="row"
        fxLayout.lt-md="row"
        fxLayoutGap="15px"
        fxLayoutGap.lt-md="0px"
        fxLayoutAlign="center center"
      >
        <button class="mat-button action-btn outlined-button resume" type="button" (click)="resume()">
          No
        </button>
        <button class="mat-button action-btn outlined-button continue" type="button" matStepperNext (click)="close()">
          Continue
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
