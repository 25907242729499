import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BankInfoService } from 'src/app/public/lumpsum-dashboard/services/bank-info.service';
import { LocationsService } from 'src/app/public/needs-assessment/services/locations.service';
import { MyTasksLumpsumComponent } from '../my-tasks-lumpsum/my-tasks-lumpsum.component';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { Subscription } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { PayThroughBankHelpDialogComponent } from '../pay-through-bank-help-dialog/pay-through-bank-help-dialog.component';
import { DialogService } from 'src/app/public/dialog/service/dialog.service';
import { CancelAddBankModalComponent } from '../cancel-add-bank-modal/cancel-add-bank-modal.component';
import { CookieService } from 'ngx-cookie-service';
import { CollaborationService } from 'src/app/core/services/collaboration.service';
import { CollabEvents } from 'src/app/core/services/collaboration-events.service';

@Component({
  selector: 'app-bank-info',
  templateUrl: './bank-info.component.html',
  styleUrls: ['./bank-info.component.scss']
})
export class BankInfoComponent implements OnInit, OnDestroy {
  showAccInfo = false;
  countryList: any;
  orderRequestId: any;
  searching = false;
  isLinear = true;
  bankInfoFormGroup: UntypedFormGroup;
  accountInfoFormGroup: UntypedFormGroup;
  payThroughBankFormGroup: UntypedFormGroup;
  routingDetails: any;
  routingNumberLabel = 'Routing Number';
  swiftBicCodeLabel = 'Swift/BIC Code';
  bankingDetails: any;
  showSwift = false;
  showRouting = false;
  invalidSearch = false;
  hideRequiredMarker = true;
  inValidBank = false;
  showBankInstruction = false;
  currencyList = [];
  needAssessmentSubscription: Subscription;
  accountTypeList = [];
  showBankFormActionButtons = false;
  readOnlyBankForm = false;
  showBankFormClear = true;
  showPanTanFields = false;
  showFederalTaxId = false;
  /* flag used for masking account number*/
  isAccNoMasked = true;
  /* flag used for masking re-enter account number*/
  isMasked = true;

  disableSave = true;
  isFirefox = false;
  isBankStepComplete = false;
  isPayThroughBankNeeded = true;
  isAccInfoStepComplete = false;
  inValidPayThroughBank = false;
  invalidPayThroughSearch = false;
  routingPayThroughDetails: any;
  payThroughRoutingNoLabel = 'Routing Number';
  payThroughSwiftBicCodeLabel = 'Swift/BIC Code';
  showSwiftPTB = false;
  showRoutingPTB = false;
  hideRequiredMarkerPTB = true;
  searchingPTB = false;
  payThroughLoc: any = {};
  showPtbSummary = false;
  readOnlyPTBForm = false;
  showPTBFormActionButtons = false;
  showAlertMessage = false;
  showPTBAccDetails = false;
  ptbankDetails: any;
  isPayThrAccNoMasked = true;
  isPayThrReAccMasked = true;
  payThrswiftBicCode = '';
  showPayThorughForm = true;
  acknowledged = false;
  currencyValueList: any = [];

  /*storing routing label from API response*/
  defaultRoutingNumberLabel: string;
  defaultPayThroughRoutingNoLabel: string;

  /* read-only form to mark as primary */
  editAccInfo = false;
  /** storing the opened account */
  openedAccount: any = {};
  markedPrimary = false;
  /* used for disabling save button until all steps are verified in pay through details */
  verifyPayThroughBank = true;
  isCoBrowsing = false;

  @ViewChild(MatStepper) stepper: MatStepper;
  constructor(
    private formBuilder: FormBuilder,
    private readonly locationService: LocationsService,
    private readonly bankInfoService: BankInfoService,
    private readonly mytaskComponent: MyTasksLumpsumComponent,
    private readonly router: Router,
    public spinner: NgxSpinnerService,
    private readonly needsAssessmentShared: NeedsAssessmentSharedService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private viewContainerRef: ViewContainerRef,
    public cookieService: CookieService,
    public readonly collaboration: CollaborationService
  ) {
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf('Firefox') !== -1) {
      this.isFirefox = userAgent.indexOf('Firefox') !== -1 ? true : false;
    }
  }

  ngOnInit(): void {
    this.isCoBrowsing = this.cookieService.get('transferee-context') ? true : false;
    this.mytaskComponent.addAccoutInfo.subscribe(res => {
      if (res) {
        this.showAccInfo = res;
        this.resetStepper();
      }
    });
    this.needAssessmentSubscription = this.needsAssessmentShared.transfereeNeedsAssessmentDetails.subscribe(data => {
      if (!data) {
        return;
      }
      this.orderRequestId = data.orderRequestId;
      this.getCurrencies();
    });
    this.bankInfoFormGroup = this.formBuilder.group({
      country: new FormControl('', Validators.required),
      currency: new FormControl('', Validators.required)
    });
    this.accountInfoFormGroup = this.formBuilder.group({
      accountNumber: new FormControl('', Validators.required),
      matchAccountNumber: new FormControl('', Validators.required),
      accountType: new FormControl('', Validators.required),
      accountHolderName: new FormControl('', Validators.required),
      bankInstruction: new FormControl(''),
      panNumber: new FormControl(''),
      tanNumber: new FormControl(''),
      federalTaxNumber: new FormControl('')
    });
    this.payThroughBankFormGroup = this.formBuilder.group({
      payThroughBankCountry: new FormControl('', Validators.required),
      payThroughBankRoutingNumber: new FormControl(''),
      payThroughBankSwiftBicCode: new FormControl(''),
      payThroughBankAccNumber: new FormControl(''),
      payThroughBankReEnterAccNumber: new FormControl('')
    });
    this.getCountryList();
    this.getAccountType();
  }

  getCountryList() {
    this.locationService.countryList.subscribe(countryList => {
      const bankcountryList = countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
      this.countryList = bankcountryList.map(ele => {
        return ele.countryName;
      });
    });
  }
  getCurrencies() {
    this.bankInfoService.getCurrencyList(this.orderRequestId).subscribe(res => {
      if (res) {
        this.currencyValueList = res;
        this.currencyList = res.map(ele => {
          return ele.currency;
        });
      }
    });
  }

  getRoutingDetails(step: string) {
    let requestParam = {};
    let isValid = false;
    if (step === 'bankInfo') {
      isValid = this.bankInfoFormGroup.get('country').value && this.bankInfoFormGroup.get('currency').value ? true : false;
    } else {
      isValid = this.payThroughBankFormGroup.get('payThroughBankCountry').value ? true : false;
    }
    if (isValid) {
      this.spinner.show();
      if (step === 'bankInfo') {
        this.showPtbSummary = false;
        this.bankingDetails = {};
        this.payThroughLoc = {};
        this.inValidBank = false;
        this.invalidSearch = false;
        this.bankInfoFormGroup?.addControl('routingNumber', this.formBuilder.control(''));
        this.bankInfoFormGroup?.addControl('swiftBicCode', this.formBuilder.control(''));
        this.bankInfoFormGroup?.get('routingNumber')?.setValue(null);
        this.bankInfoFormGroup?.get('swiftBicCode')?.setValue(null);
        this.bankInfoFormGroup?.get('routingNumber')?.setErrors(null);
        this.bankInfoFormGroup?.get('swiftBicCode')?.setErrors(null);
        requestParam = {
          orderRequestId: this.orderRequestId,
          currency: this.bankInfoFormGroup.controls['currency'].value,
          country: this.bankInfoFormGroup.controls['country'].value
        };
      } else {
        this.showPtbSummary = false;
        this.payThroughLoc = {};
        this.inValidPayThroughBank = false;
        this.invalidPayThroughSearch = false;
        this.payThroughBankFormGroup?.addControl('payThroughBankRoutingNumber', this.formBuilder.control(''));
        this.payThroughBankFormGroup?.addControl('payThroughBankSwiftBicCode', this.formBuilder.control(''));
        this.payThroughBankFormGroup?.get('payThroughBankRoutingNumber')?.setValue(null);
        this.payThroughBankFormGroup?.get('payThroughBankSwiftBicCode')?.setValue(null);
        this.payThroughBankFormGroup?.get('payThroughBankRoutingNumber')?.setErrors(null);
        this.payThroughBankFormGroup?.get('payThroughBankSwiftBicCode')?.setErrors(null);
        requestParam = {
          orderRequestId: this.orderRequestId,
          currency: this.bankInfoFormGroup.controls['currency'].value,
          country: this.payThroughBankFormGroup.controls['payThroughBankCountry'].value
        };
      }
      this.bankInfoService.getRoutingDetails(requestParam).subscribe(res => {
        if (res) {
          if (step === 'bankInfo') {
            this.routingDetails = res;
            this.defaultRoutingNumberLabel = this.routingDetails?.routing?.routingLabel;
            this.routingNumberLabel = this.defaultRoutingNumberLabel?.includes('Canadian')
              ? this.defaultRoutingNumberLabel.split('(')?.[0]
              : this.defaultRoutingNumberLabel;
            this.swiftBicCodeLabel = this.routingDetails?.swift?.swiftLabel;
            this.showRouting = this.routingDetails?.routing?.routingShow ? this.routingDetails.routing.routingShow : false;
            this.showSwift = this.routingDetails?.swift?.swiftShow ? this.routingDetails.swift.swiftShow : false;
            const validSwiftPattern = '^[A-Za-z][0-9A-Za-z]+$';
            if (this.showRouting && this.showSwift) {
              this.bankInfoFormGroup
                ?.get('swiftBicCode')
                ?.setValidators([Validators.pattern(validSwiftPattern), Validators.minLength(8), Validators.maxLength(11)]);
              this.hideRequiredMarker = true;
              if (!this.bankInfoFormGroup?.get('routingNumber')?.value && !this.bankInfoFormGroup?.get('swiftBicCode')?.value) {
                this.invalidSearch = true;
              }
            } else if (this.showRouting) {
              this.validateRoutingNumber(this.routingNumberLabel, 'bankInfo');
              this.bankInfoFormGroup?.removeControl('swiftBicCode');
              this.hideRequiredMarker = false;
            } else if (this.showSwift) {
              this.bankInfoFormGroup
                ?.get('swiftBicCode')
                ?.setValidators([
                  Validators.required,
                  Validators.pattern(validSwiftPattern),
                  Validators.minLength(8),
                  Validators.maxLength(11)
                ]);
              this.bankInfoFormGroup?.removeControl('routingNumber');
              this.hideRequiredMarker = false;
            } else {
              this.showRouting = true;
              this.showSwift = true;
              this.hideRequiredMarker = false;
            }
            this.spinner.hide();
            this.searching = true;
          } else {
            this.routingPayThroughDetails = res;
            this.defaultPayThroughRoutingNoLabel = this.routingPayThroughDetails?.routing?.routingLabel;
            this.payThroughRoutingNoLabel = this.defaultPayThroughRoutingNoLabel?.includes('Canadian')
              ? this.defaultPayThroughRoutingNoLabel.split('(')?.[0]
              : this.defaultPayThroughRoutingNoLabel;
            this.payThroughSwiftBicCodeLabel = this.routingPayThroughDetails?.swift?.swiftLabel;
            this.showRoutingPTB = this.routingPayThroughDetails?.routing?.routingShow
              ? this.routingPayThroughDetails.routing.routingShow
              : false;
            this.showSwiftPTB = this.routingPayThroughDetails?.swift?.swiftShow ? this.routingPayThroughDetails.swift.swiftShow : false;
            const validSwiftPattern = '^[A-Za-z][0-9A-Za-z]+$';
            if (this.showRoutingPTB && this.showSwiftPTB) {
              this.payThroughBankFormGroup
                ?.get('payThroughBankSwiftBicCode')
                ?.setValidators([Validators.pattern(validSwiftPattern), Validators.minLength(8), Validators.maxLength(11)]);
              this.hideRequiredMarker = true;
              if (
                !this.payThroughBankFormGroup?.get('payThroughBankRoutingNumber')?.value &&
                !this.payThroughBankFormGroup?.get('payThroughBankSwiftBicCode')?.value
              ) {
                this.invalidPayThroughSearch = true;
              }
            } else if (this.showRoutingPTB) {
              this.validateRoutingNumber(this.payThroughRoutingNoLabel, 'payThrough');
              this.payThroughBankFormGroup?.removeControl('payThroughBankSwiftBicCode');
              this.hideRequiredMarkerPTB = false;
            } else if (this.showSwiftPTB) {
              this.payThroughBankFormGroup
                ?.get('payThroughBankSwiftBicCode')
                ?.setValidators([
                  Validators.required,
                  Validators.pattern(validSwiftPattern),
                  Validators.minLength(8),
                  Validators.maxLength(11)
                ]);
              this.payThroughBankFormGroup?.removeControl('payThroughBankRoutingNumber');
              this.hideRequiredMarkerPTB = false;
            } else {
              this.showRoutingPTB = true;
              this.showSwiftPTB = true;
              this.hideRequiredMarkerPTB = false;
            }
            this.spinner.hide();
            this.searchingPTB = true;
          }
        } else {
          this.spinner.hide();
        }
      });
    }
  }

  /* To validate via pattern for routing number based on label & country selected */
  validateRoutingNumber(routingLabel: string, step: string) {
    switch (routingLabel) {
      case 'ABA Number (Routing Number)':
      case 'Branch Number and Transit Number': {
        const regexPattern = '^[0-9]{9}$';
        if (step === 'bankInfo') {
          this.bankInfoFormGroup.get('routingNumber').setValidators([Validators.required, Validators.pattern(regexPattern)]);
        } else {
          this.payThroughBankFormGroup
            .get('payThroughBankRoutingNumber')
            .setValidators([Validators.required, Validators.pattern(regexPattern)]);
        }
        break;
      }
      case 'BSB Number': {
        if (step === 'bankInfo') {
          this.bankInfoFormGroup
            .get('routingNumber')
            .setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
        } else {
          this.payThroughBankFormGroup
            .get('payThroughBankRoutingNumber')
            .setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
        }
        break;
      }
      case 'SORT code for GBP': {
        const regexPattern = '^.{0,6}$|.{8}$';
        if (step === 'bankInfo') {
          this.bankInfoFormGroup
            .get('routingNumber')
            .setValidators([Validators.required, Validators.pattern(regexPattern), Validators.minLength(6), Validators.maxLength(8)]);
        } else {
          this.payThroughBankFormGroup
            .get('payThroughBankRoutingNumber')
            .setValidators([Validators.required, Validators.pattern(regexPattern), Validators.minLength(6), Validators.maxLength(8)]);
        }
        break;
      }
      default: {
        if (step === 'bankInfo') {
          this.bankInfoFormGroup?.get('routingNumber')?.setValidators([Validators.required]);
        } else {
          this.payThroughBankFormGroup?.get('payThroughBankRoutingNumber')?.setValidators([Validators.required]);
        }
      }
    }
  }

  getBankDetails(step) {
    this.spinner.show();
    let requestParam = {};
    if (step === 'bankInfo') {
      requestParam = {
        orderRequestId: this.orderRequestId,
        currency: this.bankInfoFormGroup.controls['currency'].value,
        country: this.bankInfoFormGroup.controls['country'].value
      };
      if (this.bankInfoFormGroup?.controls['routingNumber']?.value) {
        requestParam['routingNumber'] = this.bankInfoFormGroup?.controls['routingNumber']?.value;
      }
      if (this.bankInfoFormGroup?.controls['swiftBicCode']?.value) {
        requestParam['swiftBicCode'] = this.bankInfoFormGroup?.controls['swiftBicCode']?.value;
      }
    } else {
      requestParam = {
        orderRequestId: this.orderRequestId,
        currency: this.bankInfoFormGroup.controls['currency'].value,
        country: this.payThroughBankFormGroup.controls['payThroughBankCountry'].value
      };
      if (this.payThroughBankFormGroup?.controls['payThroughBankRoutingNumber']?.value) {
        requestParam['routingNumber'] = this.payThroughBankFormGroup?.controls['payThroughBankRoutingNumber']?.value;
      }
      if (this.payThroughBankFormGroup?.controls['payThroughBankSwiftBicCode']?.value) {
        requestParam['swiftBicCode'] = this.payThroughBankFormGroup?.controls['payThroughBankSwiftBicCode']?.value;
      }
    }
    const validPattern = '^[a-zA-Z0-9]+$';
    this.bankInfoService.getBankDetails(requestParam).subscribe(res => {
      if (res) {
        if (step === 'bankInfo') {
          this.bankingDetails = res;
          if (this.bankingDetails.hasOwnProperty('payThroughLoc')) {
            this.verifyPayThroughBank = false;
            this.payThroughLoc = this.bankingDetails?.payThroughLoc;
            this.showPtbSummary = true;
            this.readOnlyPTBForm = true;
            this.showPTBFormActionButtons = false;
            this.showAlertMessage = false;
            this.showPTBAccDetails = true;
            this.isPayThroughBankNeeded = true;
            this.readOnlyPTBForm = true;
            this.payThrswiftBicCode = this.bankingDetails?.payThroughLoc.swiftBICCode;
            if (this.bankingDetails.payThroughLoc.swiftBICCode) {
              this.payThroughBankFormGroup.get('payThroughBankSwiftBicCode').setValue(this.bankingDetails.payThroughLoc.swiftBICCode);
            } else {
              this.payThroughBankFormGroup.get('payThroughBankRoutingNumber').setValue(this.bankingDetails?.payThroughLoc?.routingNumber);
            }
            if (this.bankingDetails?.payThroughLoc?.country) {
              this.payThroughBankFormGroup.get('payThroughBankCountry').setValue(this.bankingDetails?.payThroughLoc?.country);
            }
            this.payThroughBankFormGroup.updateValueAndValidity();
          } else {
            this.showPtbSummary = false;
            this.readOnlyPTBForm = false;
            this.showPTBAccDetails = false;
            this.isPayThroughBankNeeded = this.bankingDetails?.payThroughReq;
            this.payThroughLoc = {};
            this.showAlertMessage = false;
            this.showPTBFormActionButtons = false;
            if (!this?.bankingDetails?.payThroughReq) {
              this.verifyPayThroughBank = false;
              this.isPayThroughBankNeeded = false;
              this.showPayThorughForm = false;
              this.payThroughBankFormGroup?.get('payThroughBankCountry').removeValidators(Validators.required);
              this.payThroughBankFormGroup?.get('payThroughBankCountry').updateValueAndValidity();
            }
          }
          if (this.bankingDetails.hasOwnProperty('bankLocation')) {
            this.searching = false;
            this.inValidBank = false;
            this.showBankFormActionButtons = true;
            this.readOnlyBankForm = true;
          } else {
            this.searching = true;
            this.inValidBank = true;
            this.showBankFormActionButtons = false;
            this.readOnlyBankForm = false;
          }
          this.showBankInstruction =
            this.bankingDetails?.bankInstructions === 'show' || this.bankingDetails?.bankInstructions === 'required' ? true : false;
          if (this.bankingDetails?.bankInstructions === 'required') {
            this.accountInfoFormGroup?.get('bankInstruction')?.setValidators(Validators.required);
          }
          if (this.bankingDetails.hasOwnProperty('panTanFields')) {
            this.accountInfoFormGroup
              ?.get('panNumber')
              ?.setValidators([Validators.pattern(validPattern), Validators.minLength(10), Validators.maxLength(10)]);
            this.accountInfoFormGroup
              ?.get('tanNumber')
              ?.setValidators([Validators.pattern(validPattern), Validators.minLength(10), Validators.maxLength(10)]);
            this.showPanTanFields =
              this.bankingDetails.panTanFields === 'show' || this.bankingDetails.panTanFields === 'required' ? true : false;
            if (this.bankingDetails?.panTanFields === 'required') {
              this.accountInfoFormGroup
                ?.get('panNumber')
                ?.setValidators([
                  Validators.required,
                  Validators.pattern(validPattern),
                  Validators.minLength(10),
                  Validators.maxLength(10)
                ]);
              this.accountInfoFormGroup
                ?.get('tanNumber')
                ?.setValidators([
                  Validators.required,
                  Validators.pattern(validPattern),
                  Validators.minLength(10),
                  Validators.maxLength(10)
                ]);
            }
          }
          if (this.bankingDetails.hasOwnProperty('fedTaxIdField')) {
            this.accountInfoFormGroup
              ?.get('federalTaxNumber')
              ?.setValidators([Validators.pattern(validPattern), Validators.minLength(11), Validators.maxLength(11)]);
            this.showFederalTaxId =
              this.bankingDetails.fedTaxIdField === 'show' || this.bankingDetails.fedTaxIdField === 'required' ? true : false;
            if (this.bankingDetails?.fedTaxIdField === 'required') {
              this.accountInfoFormGroup
                ?.get('federalTaxNumber')
                ?.setValidators([
                  Validators.required,
                  Validators.pattern(validPattern),
                  Validators.minLength(11),
                  Validators.maxLength(11)
                ]);
              this.accountInfoFormGroup.updateValueAndValidity();
            }
          }
        } else {
          this.ptbankDetails = res;
          this.searchingPTB = false;
          this.payThrswiftBicCode = this.ptbankDetails.foundSwift;
          if (this.ptbankDetails.hasOwnProperty('bankLocation')) {
            this.searchingPTB = false;
            this.inValidPayThroughBank = false;
            this.showPTBFormActionButtons = true;
            this.readOnlyPTBForm = true;
            this.payThroughLoc = this.ptbankDetails.bankLocation;
            if (this.ptbankDetails?.foundSwift) this.payThroughLoc['foundSwift'] = this.ptbankDetails.foundSwift;
            this.showPtbSummary = true;
            this.showAlertMessage = true;
          } else {
            this.searchingPTB = true;
            this.inValidPayThroughBank = true;
            this.showPTBFormActionButtons = false;
            this.readOnlyPTBForm = false;
            this.payThroughLoc = {};
            this.showPtbSummary = false;
            this.showAlertMessage = false;
            this.verifyPayThroughBank = true;
          }
        }
        this.spinner.hide();
      } else {
        this.inValidBank = false;
        this.inValidPayThroughBank = false;
        this.spinner.hide();
      }
    });
  }

  onFormfieldsChange(eve, routingLabel) {
    if (this.showRouting && this.showSwift) {
      if (eve && this.bankInfoFormGroup?.get('routingNumber')?.value && !this.bankInfoFormGroup?.get('swiftBicCode')?.value) {
        // only routingNumber is provided
        this.invalidSearch = false;
        this.validateRoutingNumber(routingLabel, 'bankInfo');
        this.hideRequiredMarker = true;
        this.bankInfoFormGroup?.get('swiftBicCode')?.removeValidators(Validators.required);
        this.bankInfoFormGroup.updateValueAndValidity();
      } else if (eve && this.bankInfoFormGroup?.get('swiftBicCode')?.value && !this.bankInfoFormGroup?.get('routingNumber')?.value) {
        // only swiftBicCode is provided
        const validSwiftPattern = '^[A-Za-z][0-9A-Za-z]+$';
        this.invalidSearch = false;
        this.bankInfoFormGroup
          ?.get('swiftBicCode')
          ?.setValidators([Validators.required, Validators.pattern(validSwiftPattern), Validators.minLength(8), Validators.maxLength(11)]);
        this.hideRequiredMarker = true;
        this.bankInfoFormGroup?.get('routingNumber')?.removeValidators(Validators.required);
        this.bankInfoFormGroup.updateValueAndValidity();
      } else if (!this.bankInfoFormGroup?.get('swiftBicCode')?.value && !this.bankInfoFormGroup?.get('routingNumber')?.value) {
        // both routingNumber and swiftBicCode are not provided
        this.invalidSearch = true;
        this.bankInfoFormGroup?.get('routingNumber')?.setErrors(null);
        this.bankInfoFormGroup?.get('swiftBicCode')?.setErrors(null);
        this.bankInfoFormGroup?.updateValueAndValidity();
      } else {
        // both routingNumber and swiftBicCode are provided
        this.invalidSearch = false;
        this.bankInfoFormGroup?.get('swiftBicCode')?.removeValidators(Validators.required);
        this.bankInfoFormGroup?.get('routingNumber')?.removeValidators(Validators.required);
        this.bankInfoFormGroup?.updateValueAndValidity();
      }
    }
  }

  errorMessage(formFieldLabel, formField, formGroupType) {
    const formGroupTypes =
      formGroupType === 'accountInfo'
        ? (this.accountInfoFormGroup as FormGroup)
        : formGroupType === 'payThrough'
        ? (this.payThroughBankFormGroup as FormGroup)
        : (this.bankInfoFormGroup as FormGroup);
    if (formGroupTypes?.get(formField)?.hasError('required')) {
      return `${formFieldLabel} is required`;
    } else if (
      formGroupTypes?.get(formField)?.hasError('pattern') ||
      formGroupTypes?.get(formField)?.hasError('minlength') ||
      formGroupTypes?.get(formField)?.hasError('maxlength')
    ) {
      return `Please enter a valid ${formFieldLabel}`;
    } else if (
      formGroupTypes?.controls['accountNumber']?.hasError('inavalidAccountNo') ||
      formGroupTypes?.controls['payThroughBankAccNumber']?.hasError('inavalidAccountNo')
    ) {
      return `Invalid Account Number/IBAN`;
    } else if (
      formGroupTypes?.controls['matchAccountNumber']?.hasError('noMatch') ||
      formGroupTypes?.controls['payThroughBankReEnterAccNumber']?.hasError('noMatch')
    ) {
      return `Account Numbers/IBANs do not match`;
    }
  }

  getAccountType() {
    this.bankInfoService.getAccountTypes().subscribe(res => {
      if (res) {
        this.accountTypeList = res.values;
      }
    });
  }

  validateAccountNumber(step) {
    if (!this.showAccInfo) {
      return;
    }
    this.spinner.show();
    let reqPayload = {};
    const accountNumber =
      step === 'accInfo'
        ? this.accountInfoFormGroup?.controls['accountNumber']?.value
        : this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.value;
    if (step === 'accInfo') {
      reqPayload = {
        orderRequestId: this.orderRequestId,
        currency: this.bankInfoFormGroup?.controls['currency']?.value,
        country: this.bankInfoFormGroup?.controls['country']?.value,
        accountNumber: this.accountInfoFormGroup?.controls['accountNumber']?.value
      };
    } else {
      reqPayload = {
        orderRequestId: this.orderRequestId,
        currency: this.bankInfoFormGroup?.controls['currency']?.value,
        country: this.payThroughBankFormGroup?.controls['payThroughBankCountry']?.value,
        accountNumber: this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.value
      };
    }
    if (accountNumber) {
      this.bankInfoService.getAccountNumberValidations(reqPayload).subscribe(res => {
        if (!res) {
          if (step === 'accInfo') {
            this.accountInfoFormGroup?.controls['accountNumber']?.setErrors({ inavalidAccountNo: true });
          } else {
            this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.setErrors({ inavalidAccountNo: true });
          }
          this.spinner.hide();
        } else {
          if (step === 'accInfo') {
            this.accountInfoFormGroup?.controls['accountNumber']?.setErrors(null);
            this.verifyAccountNumber();
          } else {
            this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.setErrors(null);
            this.verifyPayThroughAccNumber();
          }
          this.spinner.hide();
        }
      });
    } else {
      if (step === 'accInfo') {
        this.accountInfoFormGroup?.controls['matchAccountNumber']?.setErrors({ noMatch: true });
      } else {
        if (this.payThroughBankFormGroup?.controls['payThroughBankReEnterAccNumber']?.value) {
          this.verifyPayThroughAccNumber();
        } else {
          this.payThroughBankFormGroup?.controls['payThroughBankReEnterAccNumber']?.setErrors(null);
        }
      }
      this.spinner.hide();
    }
  }

  verifyAccountNumber() {
    if (
      this.accountInfoFormGroup?.controls['accountNumber']?.value &&
      this.accountInfoFormGroup?.controls['matchAccountNumber']?.value &&
      this.accountInfoFormGroup?.controls['accountNumber']?.value?.toLowerCase() !=
        this.accountInfoFormGroup?.controls['matchAccountNumber']?.value?.toLowerCase()
    ) {
      this.accountInfoFormGroup?.controls['matchAccountNumber']?.setErrors({ noMatch: true });
    } else {
      this.accountInfoFormGroup?.controls['matchAccountNumber']?.setErrors({ noMatch: null });
      this.accountInfoFormGroup?.controls['matchAccountNumber']?.updateValueAndValidity();
    }
  }

  confirmBankInformation() {
    this.readOnlyBankForm = true;
    this.showBankFormClear = false;
    this.searching = false;
    this.isBankStepComplete = true;
  }

  resetBankDetails() {
    this.bankInfoFormGroup.reset({
      country: '',
      currency: '',
      routingNumber: '',
      swiftBicCode: ''
    });
    this.searching = true;
    this.bankingDetails = {};
    this.readOnlyBankForm = false;
    this.showSwift = false;
    this.showRouting = false;
    this.inValidBank = false;
    this.showBankFormActionButtons = false;
  }

  maskAccountNumber(fieldFlag) {
    fieldFlag === 'isAccNoMasked' ? (this.isAccNoMasked = true) : (this.isMasked = true);
  }

  unmaskAccountNumber(fieldFlag) {
    fieldFlag === 'isAccNoMasked' ? (this.isAccNoMasked = false) : (this.isMasked = false);
  }
  ackCreditCardComplaince(event: any) {
    this.acknowledged = event.checked;
    if (event.checked) {
      this.disableSave = this.payThroughBankFormGroup.invalid ? true : false;
      this.isAccInfoStepComplete = this.accountInfoFormGroup?.valid ? true : false;
    } else {
      this.disableSave = true;
      this.isAccInfoStepComplete = false;
    }
  }

  onPayThoughBankChange(eve) {
    this.isPayThroughBankNeeded = eve;
    this.payThroughBankFormGroup.reset({
      payThroughBankCountry: '',
      payThroughBankRoutingNumber: '',
      payThroughBankSwiftBicCode: ''
    });
    this.searchingPTB = false;
    this.showPtbSummary = false;
    this.inValidPayThroughBank = false;
    this.showAlertMessage = false;
    if (!this.isPayThroughBankNeeded) {
      this.showPayThorughForm = false;
      this.payThroughBankFormGroup?.removeControl('payThroughBankCountry');
      this.payThroughBankFormGroup?.removeControl('payThroughBankRoutingNumber');
      this.payThroughBankFormGroup?.removeControl('payThroughBankSwiftBicCode');
      this.showRoutingPTB = false;
      this.showSwiftPTB = false;
      this.verifyPayThroughBank = false;
    } else {
      this.payThroughBankFormGroup?.addControl('payThroughBankCountry', this.formBuilder.control(''));
      this.payThroughBankFormGroup?.get('payThroughBankCountry').setValidators(Validators.required);
      this.payThroughBankFormGroup?.get('payThroughBankCountry').updateValueAndValidity();
      this.showPayThorughForm = true;
      this.readOnlyPTBForm = false;
      this.showPTBAccDetails = false;
      this.verifyPayThroughBank = true;
    }
  }

  maskPayThroughAccNo(fieldFlag) {
    fieldFlag === 'isPayThrAccNoMasked' ? (this.isPayThrAccNoMasked = true) : (this.isPayThrReAccMasked = true);
  }

  unmaskPayThroughAccNo(fieldFlag) {
    fieldFlag === 'isPayThrAccNoMasked' ? (this.isPayThrAccNoMasked = false) : (this.isPayThrReAccMasked = false);
  }

  confirmPTBInfo() {
    this.showPTBAccDetails = true;
    this.searchingPTB = false;
    this.readOnlyPTBForm = true;
    this.showPTBFormActionButtons = false;
    this.verifyPayThroughBank = false;
    this.disableSave = this.payThroughBankFormGroup.valid && this.showPTBAccDetails ? false : true;
  }

  openPtbHelpDialog() {
    const dialogRef = this.dialog.open(PayThroughBankHelpDialogComponent, {
      data: {},
      width: '52vw',
      disableClose: false
    });
  }

  onPayThroughFormChanges(eve, routingLabel) {
    if (this.showRoutingPTB && this.showSwiftPTB) {
      if (
        eve &&
        this.payThroughBankFormGroup?.get('payThroughBankRoutingNumber')?.value &&
        !this.payThroughBankFormGroup?.get('payThroughBankSwiftBicCode')?.value
      ) {
        // only routingNumber is provided
        this.invalidPayThroughSearch = false;
        this.validateRoutingNumber(routingLabel, 'payThrough');
        this.hideRequiredMarkerPTB = true;
        this.payThroughBankFormGroup?.get('payThroughBankSwiftBicCode')?.removeValidators(Validators.required);
        this.payThroughBankFormGroup.updateValueAndValidity();
      } else if (
        eve &&
        this.payThroughBankFormGroup?.get('payThroughBankSwiftBicCode')?.value &&
        !this.payThroughBankFormGroup?.get('payThroughBankRoutingNumber')?.value
      ) {
        // only swiftBicCode is provided
        const validSwiftPattern = '^[A-Za-z][0-9A-Za-z]+$';
        this.invalidPayThroughSearch = false;
        this.payThroughBankFormGroup
          ?.get('payThroughBankSwiftBicCode')
          ?.setValidators([Validators.required, Validators.pattern(validSwiftPattern), Validators.minLength(8), Validators.maxLength(11)]);
        this.hideRequiredMarkerPTB = true;
        this.payThroughBankFormGroup?.get('payThroughBankRoutingNumber')?.removeValidators(Validators.required);
        this.payThroughBankFormGroup.updateValueAndValidity();
      } else if (
        !this.payThroughBankFormGroup?.get('payThroughBankSwiftBicCode')?.value &&
        !this.payThroughBankFormGroup?.get('payThroughBankRoutingNumber')?.value
      ) {
        // both routingNumber and swiftBicCode are not provided
        this.invalidPayThroughSearch = true;
        this.payThroughBankFormGroup?.get('payThroughBankRoutingNumber')?.setErrors(null);
        this.payThroughBankFormGroup?.get('payThroughBankSwiftBicCode')?.setErrors(null);
        this.payThroughBankFormGroup?.updateValueAndValidity();
      } else {
        // both routingNumber and swiftBicCode are provided
        this.invalidPayThroughSearch = false;
        this.payThroughBankFormGroup?.get('payThroughBankSwiftBicCode')?.removeValidators(Validators.required);
        this.payThroughBankFormGroup?.get('payThroughBankRoutingNumber')?.removeValidators(Validators.required);
        this.payThroughBankFormGroup?.updateValueAndValidity();
      }
    }
  }

  resetPayThroughDetails() {
    this.payThroughBankFormGroup.reset({
      payThroughBankCountry: '',
      payThroughBankRoutingNumber: '',
      payThroughBankSwiftBicCode: ''
    });
    this.searchingPTB = true;
    this.ptbankDetails = {};
    this.readOnlyPTBForm = false;
    this.showSwiftPTB = false;
    this.showRoutingPTB = false;
    this.inValidPayThroughBank = false;
    this.showPTBFormActionButtons = false;
    this.showPtbSummary = false;
    this.showPTBAccDetails = false;
    this.showPayThorughForm = true;
    this.verifyPayThroughBank = true;
  }

  verifyPayThroughAccNumber() {
    this.payThroughBankFormGroup.get('payThroughBankAccNumber').clearValidators();
    this.payThroughBankFormGroup.get('payThroughBankReEnterAccNumber').clearValidators();
    this.payThroughBankFormGroup.get('payThroughBankAccNumber').updateValueAndValidity();
    this.payThroughBankFormGroup.get('payThroughBankReEnterAccNumber').updateValueAndValidity();
    if (
      this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.value &&
      this.payThroughBankFormGroup?.controls['payThroughBankReEnterAccNumber']?.value &&
      this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.value?.toLowerCase() !=
        this.payThroughBankFormGroup?.controls['payThroughBankReEnterAccNumber']?.value?.toLowerCase()
    ) {
      this.payThroughBankFormGroup?.controls['payThroughBankReEnterAccNumber']?.setErrors({ noMatch: true });
    } else {
      if (
        !this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.value &&
        !this.payThroughBankFormGroup?.controls['payThroughBankReEnterAccNumber']?.value
      ) {
        this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.setErrors(null);
        this.payThroughBankFormGroup?.controls['payThroughBankReEnterAccNumber']?.setErrors(null);
        this.payThroughBankFormGroup?.updateValueAndValidity();
      } else if (
        this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.value &&
        !this.payThroughBankFormGroup?.controls['payThroughBankReEnterAccNumber']?.value
      ) {
        this.payThroughBankFormGroup?.controls['payThroughBankReEnterAccNumber']?.setValidators(Validators.required);
        this.payThroughBankFormGroup?.controls['payThroughBankReEnterAccNumber']?.updateValueAndValidity();
      } else if (
        !this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.value &&
        this.payThroughBankFormGroup?.controls['payThroughBankReEnterAccNumber']?.value
      ) {
        this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.setValidators(Validators.required);
        this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.updateValueAndValidity();
      }
    }
  }

  generateReqPayload() {
    const payload = {};
    if (this.bankInfoFormGroup?.controls['routingNumber']?.value && this.defaultRoutingNumberLabel) {
      payload['routingNumber'] = this.bankInfoFormGroup?.controls['routingNumber']?.value;
      payload['routingLabel'] = this.defaultRoutingNumberLabel;
    }
    if (this.bankInfoFormGroup?.controls['currency']?.value) {
      const foundCurrency = this.currencyValueList.find(ele => ele.currency === this.bankInfoFormGroup?.controls['currency']?.value);
      payload['disbursementCurrency'] = foundCurrency.currency;
      payload['disbursementCurrencyCd'] = foundCurrency.currencyCd;
    }
    this.bankingDetails?.bankLocation?.bankName ? (payload['bankName'] = this.bankingDetails?.bankLocation?.bankName) : null;
    if (
      this.bankingDetails?.bankLocation?.country &&
      this.bankingDetails?.bankLocation?.city &&
      this.bankingDetails?.bankLocation?.addressLine1
    ) {
      payload['address'] = {
        streetLine1: this.bankingDetails?.bankLocation?.addressLine1,
        city: this.bankingDetails?.bankLocation?.city,
        stateProvince: this.bankingDetails?.bankLocation?.state,
        country: this.bankingDetails?.bankLocation?.country,
        postal: this.bankingDetails?.bankLocation?.postalCode
      };
      this.bankingDetails?.bankLocation?.countryCd
        ? (payload['address']['countryCd'] = this.bankingDetails?.bankLocation?.countryCd)
        : null;
    }
    this.bankInfoFormGroup?.controls['swiftBicCode']?.value && this.bankInfoFormGroup?.controls['swiftBicCode']?.value !== ''
      ? (payload['swiftBICCode'] = this.bankInfoFormGroup?.controls['swiftBicCode']?.value)
      : this.bankingDetails?.foundSwift
      ? (payload['swiftBICCode'] = this.bankingDetails?.foundSwift)
      : null;
    if (this.bankingDetails?.bankLocation?.contacts?.length) {
      const findPhoneNumber = this.bankingDetails?.bankLocation?.contacts.find(
        contact => contact.contactType === 'Telephone' && contact.contactTypeCd === 1
      );
      findPhoneNumber ? (payload['phoneNumber'] = findPhoneNumber?.contactInfo) : null;
    }
    this.accountInfoFormGroup?.controls['accountHolderName']?.value
      ? (payload['accountHolder'] = this.accountInfoFormGroup?.controls['accountHolderName']?.value)
      : null;
    this.accountInfoFormGroup?.controls['accountNumber']?.value
      ? (payload['accountNumber'] = this.accountInfoFormGroup?.controls['accountNumber']?.value)
      : null;
    this.accountInfoFormGroup?.controls['accountType']?.value
      ? (payload['accountType'] = this.accountInfoFormGroup?.controls['accountType']?.value)
      : null;
    this.accountInfoFormGroup?.controls['bankInstruction']?.value
      ? (payload['specialInstructions'] = this.accountInfoFormGroup?.controls['bankInstruction']?.value)
      : null;
    //PayThrough is required and updating the payload
    if (this.bankingDetails?.payThroughReq && this.bankingDetails?.payThroughLoc) {
      this.verifyPayThroughBank = false;
      payload['payThroughBank'] = {};
      this.bankingDetails?.payThroughLoc?.bankName
        ? (payload['payThroughBank']['bankName'] = this.bankingDetails?.payThroughLoc?.bankName)
        : null;
      this.bankingDetails?.payThroughLoc?.swiftBICCode
        ? (payload['payThroughBank']['swiftBICCode'] = this.bankingDetails?.payThroughLoc?.swiftBICCode)
        : null;
      this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.value
        ? (payload['payThroughBank']['accountNumber'] = this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.value)
        : '';
      if (this.bankingDetails?.payThroughLoc?.contacts?.length) {
        const findPhoneNumber = this.bankingDetails?.payThroughLoc?.contacts.find(
          contact => contact.contactType === 'Telephone' && contact.contactTypeCd === 1
        );
        findPhoneNumber ? (payload['payThroughBank']['phoneNumber'] = findPhoneNumber?.contactInfo) : null;
      }
      if (
        this.bankingDetails?.payThroughLoc?.country &&
        this.bankingDetails?.payThroughLoc?.city &&
        this.bankingDetails?.payThroughLoc?.addressLine1
      ) {
        payload['payThroughBank']['address'] = {
          streetLine1: this.bankingDetails?.payThroughLoc?.addressLine1,
          city: this.bankingDetails?.payThroughLoc?.city,
          stateProvince: this.bankingDetails?.payThroughLoc?.state,
          country: this.bankingDetails?.payThroughLoc?.country,
          postal: this.bankingDetails?.payThroughLoc?.postalCode
        };
        this.bankingDetails?.payThroughLoc?.countryCd
          ? (payload['payThroughBank']['address']['countryCd'] = this.bankingDetails?.payThroughLoc?.countryCd)
          : null;
      }
    } else if (this.isPayThroughBankNeeded && this.payThroughLoc && this.payThroughBankFormGroup.valid) {
      this.verifyPayThroughBank = false;
      //Paythrough is not required user manually turns on the toggle and enter the payThrough details
      payload['payThroughBank'] = {};
      this.payThroughLoc?.bankName ? (payload['payThroughBank']['bankName'] = this.payThroughLoc?.bankName) : null;
      if (this.payThroughLoc?.country && this.payThroughLoc?.city && this.payThroughLoc?.addressLine1) {
        payload['payThroughBank']['address'] = {
          streetLine1: this.payThroughLoc?.addressLine1,
          city: this.payThroughLoc?.city,
          stateProvince: this.payThroughLoc?.state,
          country: this.payThroughLoc?.country,
          postal: this.payThroughLoc?.postalCode
        };
        this.payThroughLoc?.countryCd ? (payload['payThroughBank']['address']['countryCd'] = this.payThroughLoc?.countryCd) : null;
      }
      // SwiftBICCode
      if (this.payThroughLoc?.foundSwift) payload['payThroughBank']['swiftBICCode'] = this.payThroughLoc?.foundSwift;
      else if (this.payThroughLoc?.swiftBICCode) payload['payThroughBank']['swiftBICCode'] = this.payThroughLoc?.swiftBICCode;
      else if (this.payThroughBankFormGroup?.controls['payThroughBankSwiftBicCode']?.value)
        payload['payThroughBank']['swiftBICCode'] = this.payThroughBankFormGroup?.controls['payThroughBankSwiftBicCode']?.value;
      // RoutingNumber
      if (this.defaultPayThroughRoutingNoLabel) {
        if (this.payThroughLoc?.routingNumber) {
          payload['payThroughBank']['routingNumber'] = this.payThroughLoc?.routingNumber;
          payload['payThroughBank']['routingLabel'] = this.defaultPayThroughRoutingNoLabel;
        } else if (this.payThroughBankFormGroup?.controls['payThroughBankRoutingNumber']?.value) {
          payload['payThroughBank']['routingNumber'] = this.payThroughBankFormGroup?.controls['payThroughBankRoutingNumber']?.value;
          payload['payThroughBank']['routingLabel'] = this.defaultPayThroughRoutingNoLabel;
        }
      }

      if (this.payThroughLoc?.contacts?.length) {
        const findPhoneNumber = this.payThroughLoc?.contacts.find(
          contact => contact.contactType === 'Telephone' && contact.contactTypeCd === 1
        );
        findPhoneNumber ? (payload['payThroughBank']['phoneNumber'] = findPhoneNumber?.contactInfo) : null;
      }
      this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.value
        ? (payload['payThroughBank']['accountNumber'] = this.payThroughBankFormGroup?.controls['payThroughBankAccNumber']?.value)
        : '';
    }

    return payload;
  }

  addBankAccount() {
    if (
      !this.bankInfoFormGroup.valid ||
      !this.bankingDetails?.bankLocation ||
      !this.accountInfoFormGroup.valid ||
      !this.payThroughBankFormGroup.valid
    )
      return;
    else {
      const reqPayload = this.generateReqPayload();
      this.spinner.show();
      this.bankInfoService.addBankAccount(reqPayload, this.orderRequestId).subscribe(res => {
        this.spinner.hide();
        if (!res) {
          return;
        } else if (res?.acknowledged && res?.insertedId) {
          this.dialogService.open(this.viewContainerRef, 'success', 'Your bank details have been updated successfully.', false);
          if (!this.isCoBrowsing) {
            this.collaboration.sendMessage({
              data: null,
              event: CollabEvents.BANK_INFORMATION_STATUS_COMPLETE
            });
          }
          setTimeout(() => {
            this.router.navigate(['/lumpsum-dashboard']);
          }, 2000);
        }
      });
    }
  }

  discardAddbankAccount() {
    if (this.showAccInfo) {
      const dialogRef = this.dialog
        .open(CancelAddBankModalComponent, {
          panelClass: 'dialogMainContainer',
          autoFocus: false,
          disableClose: true,
          width: '40%',
          data: {}
        })
        .afterClosed()
        .subscribe(data => {
          if (data) {
            this.resetStepper();
            this.showAccInfo = false;
            this.mytaskComponent.addAccoutInfo.next(false);
            this.mytaskComponent.showAccForm = false;
          }
        });
    }
  }

  onSelectionChangeAccForm(event) {
    this.isAccInfoStepComplete = this.accountInfoFormGroup.valid && this.acknowledged ? true : false;
  }

  openAccountInfo(event) {
    this.resetStepper();
    if (event) {
      this.editAccInfo = true;
      this.openedAccount = event;
      /** Bank Info Step */
      this.bankInfoFormGroup = this.formBuilder.group({
        country: new FormControl(event?.address?.country, Validators.required),
        currency: new FormControl(event?.disbursementCurrency, Validators.required)
      });
      this.bankingDetails = {};
      this.bankingDetails['bankLocation'] = {
        bankName: event?.bankName,
        addressLine1: event?.address?.streetLine1,
        city: event?.address?.city,
        state: event?.address?.stateProvince,
        country: event?.address?.country
      };
      event?.swiftBICCode ? (this.bankingDetails.foundSwift = event?.swiftBICCode) : null;
      if (event?.routingNumber && event?.routingLabel) {
        this.showRouting = true;
        this.routingNumberLabel = event?.routingLabel;
        this.bankInfoFormGroup?.addControl('routingNumber', this.formBuilder.control(''));
        this.bankInfoFormGroup?.get('routingNumber')?.setValue(event?.routingNumber);
        this.bankInfoFormGroup?.get('routingNumber')?.setErrors(null);
      }
      this.readOnlyBankForm = true;

      /** Account Info Step */
      this.isBankStepComplete = true;
      this.acknowledged = true;
      this.accountInfoFormGroup = this.formBuilder.group({
        accountNumber: new FormControl(event?.accountNumber, Validators.required),
        matchAccountNumber: new FormControl(event?.accountNumber, Validators.required),
        accountType: new FormControl(event?.accountType, Validators.required),
        accountHolderName: new FormControl(event?.accountHolder, Validators.required),
        bankInstruction: new FormControl(event?.specialInstructions),
        panNumber: new FormControl(''),
        tanNumber: new FormControl(''),
        federalTaxNumber: new FormControl('')
      });
      if (event?.federalTaxNumber) {
        this.showFederalTaxId = true;
        this.accountInfoFormGroup.controls.federalTaxNumber.patchValue(event?.federalTaxNumber);
      }
      if (event?.panNumber) {
        this.showPanTanFields = true;
        this.accountInfoFormGroup.controls.panNumber.patchValue(event?.panNumber);
      }
      if (event?.tanNumber) {
        this.showPanTanFields = true;
        this.accountInfoFormGroup.controls.tanNumber.patchValue(event?.tanNumber);
      }
      if (event?.specialInstructions) {
        this.showBankInstruction = true;
        this.accountInfoFormGroup.controls.bankInstruction.patchValue(event?.specialInstructions);
      }
      /**PayThrough step */
      if (event?.payThroughBank) {
        this.isAccInfoStepComplete = true;
        this.readOnlyPTBForm = true;
        this.showPayThorughForm = true;
        this.showPtbSummary = true;
        this.payThroughLoc = {
          bankName: event?.payThroughBank?.bankName,
          addressLine1: event?.payThroughBank?.address?.streetLine1,
          city: event?.payThroughBank?.address?.city,
          state: event?.payThroughBank?.address?.stateProvince,
          country: event?.payThroughBank?.address?.country
        };
        event?.payThroughBank?.swiftBICCode ? (this.payThrswiftBicCode = event?.payThroughBank?.swiftBICCode) : null;
        this.payThroughBankFormGroup = this.formBuilder.group({
          payThroughBankCountry: new FormControl(event?.payThroughBank?.address?.country, Validators.required),
          payThroughBankRoutingNumber: new FormControl(''),
          payThroughBankSwiftBicCode: new FormControl(event?.payThroughBank?.swiftBICCode),
          payThroughBankAccNumber: new FormControl(event?.payThroughBank?.accountNumber),
          payThroughBankReEnterAccNumber: new FormControl(event?.payThroughBank?.accountNumber)
        });
      } else {
        this.isPayThroughBankNeeded = event?.payThroughBankRequired;
      }
    }
  }

  //reset the stepper forms
  resetStepper() {
    this.bankInfoFormGroup.reset();
    this.accountInfoFormGroup.reset();
    this.payThroughBankFormGroup.reset();
    this.acknowledged = false;
    this.editAccInfo = false;
    this.searching = false;
    this.routingDetails = {};
    this.routingNumberLabel = 'Routing Number';
    this.swiftBicCodeLabel = 'Swift/BIC Code';
    this.bankingDetails = {};
    this.showSwift = false;
    this.showRouting = false;
    this.invalidSearch = false;
    this.hideRequiredMarker = true;
    this.inValidBank = false;
    this.showBankInstruction = false;
    this.showBankFormActionButtons = false;
    this.readOnlyBankForm = false;
    this.showBankFormClear = true;
    this.showPanTanFields = false;
    this.showFederalTaxId = false;
    /* flag used for masking account number*/
    this.isAccNoMasked = true;
    /* flag used for masking re-enter account number*/
    this.isMasked = true;

    this.disableSave = true;
    this.isBankStepComplete = false;
    this.isPayThroughBankNeeded = true;
    this.isAccInfoStepComplete = false;
    this.inValidPayThroughBank = false;
    this.invalidPayThroughSearch = false;
    this.routingPayThroughDetails = {};
    this.payThroughRoutingNoLabel = 'Routing Number';
    this.payThroughSwiftBicCodeLabel = 'Swift/BIC Code';
    this.showSwiftPTB = false;
    this.showRoutingPTB = false;
    this.hideRequiredMarkerPTB = true;
    this.searchingPTB = false;
    this.payThroughLoc = {};
    this.showPtbSummary = false;
    this.readOnlyPTBForm = false;
    this.showPTBFormActionButtons = false;
    this.showAlertMessage = false;
    this.showPTBAccDetails = false;
    this.ptbankDetails = {};
    this.isPayThrAccNoMasked = true;
    this.isPayThrReAccMasked = true;
    this.payThrswiftBicCode = '';
    this.showPayThorughForm = true;
    this.acknowledged = false;
    /* read-only form to mark as primary */
    this.editAccInfo = false;
    this.openedAccount = {};
    this.markedPrimary = false;
  }

  markAsPrimary() {
    this.spinner.show();
    this.bankInfoService.markBankAsPrimary(this.openedAccount?._id, this.orderRequestId).subscribe(res => {
      this.spinner.hide();
      if (res?.message) {
        this.markedPrimary = true;
        if (!this.isCoBrowsing) {
          this.collaboration.sendMessage({
            data: null,
            event: CollabEvents.BANK_ACCOUNT_INFO_WIDGET_UPDATE
          });
          sessionStorage.setItem('car-ses-oid', this.orderRequestId);
        }
      } else {
        console.log('Error in marking bank account as primary');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.needAssessmentSubscription) {
      this.needAssessmentSubscription.unsubscribe();
    }
  }
}
