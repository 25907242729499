import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from '../../../core/services/language-translation.service';
import { PdfFontsService } from '../../benefits/services/pdf-fonts.service';
import { NeedsAssessmentSharedService } from '../../needs-assessment/services/needs-assessment-shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import pdfMake from 'pdfmake/build/pdfmake';
import { formatDate } from '@angular/common';
import { UploadDocumentService } from '../../../core/services/upload-document.service';
import { BehaviorSubject, catchError, map, of, switchMap, throwError } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { urlType } from 'src/app/core/models/urlType';
import { REPAYMENT_AGREEMENT_CONTENT, getMinimumDuration } from '../../../../assets/repayment-agreement-content';
import { DashboardService } from '../../dashboard/services/dashboard.service.service';
export const UnScuccessfullDocumentUploadMessage = `There is an issue with the document upload. Please try again later.`;
export const REPAYMENT_AGREEMENT = 'Repayment Agreement';

@Injectable({
  providedIn: 'root'
})
export class PdfGenerationService {
  browserLanguage: string;
  orderID: string;
  isRepaymentMilestoneAdded = new BehaviorSubject<boolean>(false);

  repaymentMilestone$ = this.isRepaymentMilestoneAdded.asObservable();
  repaymentBase64Content: string;

  constructor(
    private readonly pdfMakeFonts: PdfFontsService,
    private readonly needAssessmentSharedSvc: NeedsAssessmentSharedService,
    public translate: TranslateService,
    private languageTranslationService: LanguageTranslationService,
    public readonly toastr: MatSnackBar,
    private readonly uploadDocumentService: UploadDocumentService,
    private readonly spinner: NgxSpinnerService,
    private readonly baseClientService: BaseClientService,
    private readonly dashboardService: DashboardService
  ) {
    pdfMakeFonts.defineFonts();
    pdfMakeFonts.assignFonts();
    this.pdfMakeFonts.loadLogos();
    this.browserLanguage = this.languageTranslationService.getSupportedLanguage();
    translate.use(this.browserLanguage);
    this.orderID = sessionStorage.getItem('car-ses-oid');
  }

  generateDocumentDefinition(lumpsumDetails, isUploadRequired, repayDetails?) {
    const documentDefinition = {
      info: { title: 'Repayment Agreement' },
      pageOrientation: 'portrait',
      pageSize: 'LETTER',
      pageMargins: [40, 110, 40, 110],
      header: currentPage => {
        return {
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: [
              [
                {
                  image: this.pdfMakeFonts.cartusLogob64,
                  width: 120,
                  height: 40,
                  alignment: 'right',
                  color: '#FFFFFF',
                  fillColor: '#FFFFFF',
                  border: [false, false, false, false],
                  margin: [0, 35, 20, 0]
                }
              ]
            ]
          }
        };
      },
      footer: (currentPage, pageCount) => {
        const year = new Date().getFullYear();
        return {
          table: {
            headerRows: 1,
            widths: ['60%', '40%'],
            body: [
              [
                {
                  text: `© ${year} ${this.translate.instant('bb.pdfText.footerText')}
                    ${this.translate.instant('bb.pdfText.footerMessage')}`,
                  fontSize: 9,
                  color: '#575A5D',
                  alignment: 'left',
                  border: [false, false, false, false],
                  margin: [30, 30, 0, 0]
                },
                {
                  text: `${this.translate.instant('bb.pdfText.page')} ${currentPage.toString()}`,
                  fontSize: 9,
                  color: '#575A5D',
                  alignment: 'right',
                  border: [false, false, false, false],
                  margin: [0, 30, 30, 0]
                }
              ]
            ]
          }
        };
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 12,
          bold: true
        },
        client: {
          fontSize: 14,
          margin: [0, 0, 0, 10]
        },
        transfereeInfo: {
          fontSize: 12,
          margin: [0, 0, 0, 10]
        },
        date: {
          fontSize: 10,
          margin: [0, 20, 0, 0]
        }
      }
    };
    const docDefinition = JSON.parse(JSON.stringify(documentDefinition));
    docDefinition.footer = documentDefinition.footer;
    docDefinition.header = documentDefinition.header;
    docDefinition.content = this.generateContent(lumpsumDetails, isUploadRequired, repayDetails);
    return docDefinition;
  }

  generateContent(lumpsumDetails: any, isUploadRequired: boolean, repayDetails: any) {
    const {
      nameDetails,
      clientEntityName,
      orderRequestId,
      totalNumberOfRelocatingMembers,
      departureAddr,
      destinationAddr,
      custFileID
    } = this.needAssessmentSharedSvc.transfereeNeedsAssessmentDetails.getValue();

    const departureFrom = departureAddr.city + '/' + departureAddr.state + '/' + departureAddr.country;
    const destinationTo = destinationAddr.city + '/' + destinationAddr.state + '/' + destinationAddr.country;

    const {
      paragraph1,
      paragraph2,
      paragraph3,
      paragraph4,
      paragraph5,
      paragraph6,
      paragraph7,
      paragraph8,
      paragraph9,
      paragraph10,
      paragraph11
    } = REPAYMENT_AGREEMENT_CONTENT;

    const { repay: { repaymentSchedules = [] } = {} } = lumpsumDetails || {};
    const repaymentScheduleString: string = repaymentSchedules
      .map(ele => `${ele.months} month or less ${ele.repaymentPercent}%`)
      .join('\n');

    const legalName = isUploadRequired ? `${repayDetails.legalName}` : `________________________`;
    const dateOfSignature = isUploadRequired ? `${repayDetails.dateOfSignature}` : `________________________`;
    const content = [
      {
        text: 'Lump Sum Relocation Allowance Repayment Agreement',
        style: 'header',
        alignment: 'left'
      },
      {
        text: `${clientEntityName}`,
        alignment: 'left',
        style: 'client'
      },
      {
        text: `${nameDetails.firstName} ${nameDetails.lastName}`,
        alignment: 'left',
        style: 'transfereeInfo'
      },
      {
        text: `${orderRequestId}${custFileID ? ' / ' + custFileID : ''}`,
        alignment: 'left',
        style: 'transfereeInfo'
      },
      {
        text: `${departureFrom} to ${destinationTo}`,
        alignment: 'left',
        style: 'transfereeInfo'
      },
      paragraph1(clientEntityName, totalNumberOfRelocatingMembers),
      `\n`,
      paragraph2(clientEntityName),
      `\n`,
      paragraph3(clientEntityName, getMinimumDuration(repaymentSchedules)),
      `\n`,
      {
        text: 'Repayment Schedule:',
        style: 'subheader'
      },
      `${repaymentScheduleString ? repaymentScheduleString : ''}`,
      `\n`,

      paragraph4(clientEntityName),
      `\n`,

      paragraph5(),
      `\n`,

      paragraph6(),
      `\n`,

      paragraph7(clientEntityName),
      `\n`,

      paragraph8(clientEntityName),
      `\n`,

      paragraph9(),
      `\n`,

      paragraph10(clientEntityName),
      `\n`,

      paragraph11(),
      `\n`,

      {
        table: {
          headerRows: 1,
          widths: ['70%', '30%'],
          body: [
            [
              {
                text: `Enter Your Legal Name
  
              ${legalName}`,
                alignment: 'left',
                border: [false, false, false, false]
              },
              {
                text: `Enter Date
  
              ${dateOfSignature}`,
                alignment: 'left',
                border: [false, false, false, false]
              }
            ]
          ]
        }
      }
    ];

    return content;
  }

  openDownloadToastr(lumpsumDetails) {
    const message = this.translate.instant('bb.confirmedComponent.downloadMessage');
    const toaster = this.toastr.open(message, this.translate.instant('bb.confirmedComponent.download'), {
      duration: 100000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toaster']
    });
    toaster?.onAction().subscribe(_ => this.downloadRepayAgreement(lumpsumDetails));
  }

  getDocDetails() {
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'MMMM_dd_yyyy_HH_mm_ss', 'en-US');
    const docType = REPAYMENT_AGREEMENT;
    const documentDisplayName = `${docType}_${this.orderID}_${formattedDate}.pdf`;
    return {
      documentDisplayName: documentDisplayName,
      docType: docType
    };
  }

  downloadRepayAgreement(lumpsumDetails: any) {
    const { documentDisplayName } = this.getDocDetails();
    const pdfDocGenerator = pdfMake.createPdf(this.generateDocumentDefinition(lumpsumDetails, false));
    pdfDocGenerator.download(documentDisplayName);
  }

  generatePdfAndUpload(lumpsumDetails: any, repayDetails: any) {
    this.spinner.show();
    const isUploadRequired = true;
    const pdfDocGenerator = pdfMake.createPdf(this.generateDocumentDefinition(lumpsumDetails, isUploadRequired, repayDetails));
    pdfDocGenerator.getBase64(data => {
      this.repaymentBase64Content = data;
    });
    pdfDocGenerator.getBlob(content => {
      this.uploadDocument(content);
    });
  }

  uploadDocument(content: Blob) {
    const docDetails = this.getDocDetails();
    this.uploadDocumentService
      .postDMSDetails(this.orderID, docDetails)
      .pipe(switchMap((res: any) => this.handlePostDMSDetailsResponse(res, docDetails, content)))
      .subscribe({
        next: (res: any) => this.handleDocumentUploadSuccess(res),
        error: (err: any) => this.handleDocumentUploadError(err)
      });
  }

  handlePostDMSDetailsResponse(res: any, docDetails: any, content: Blob) {
    if (res?.request) {
      this.storeAuthorizationToken(res.request.headers.Authorization);
      const formData = this.createFormData(res.request.body, docDetails, content);
      return this.uploadDocumentService.uploadDMSFile(formData).pipe(
        catchError((err: any) => {
          this.displayMessage(UnScuccessfullDocumentUploadMessage);
          return throwError(() => new Error(err));
        })
      );
    } else {
      return throwError(() => new Error('Unable to fetch DMS details.'));
    }
  }

  storeAuthorizationToken(token: string) {
    sessionStorage.setItem('car-ses-dms', token);
  }

  createFormData(requestBody: any, docDetails: any, content: Blob) {
    const { guid, docType, keywords } = requestBody;
    const file = this.createFile(content, docDetails.documentDisplayName);
    const formData: any = new FormData();
    formData.append('guid', guid);
    formData.append('docType', docType);
    formData.append('keywords', JSON.stringify(keywords));
    formData.append('File', file);
    return formData;
  }

  createFile(content: Blob, fileName: string) {
    return new File([content], fileName, { type: 'application/pdf' });
  }

  handleDocumentUploadSuccess(res: any) {
    this.spinner.hide();
    if (res) {
      this.updateDocumentListWithUploadedRecord(res);
      const docType = this.extractDocType(res.documentType);
      if (docType === REPAYMENT_AGREEMENT) {
        this.callRepaymentAgreementNotificationEmail();
        this.updateMilestoneRepaymentDoc(res);
      }
    } else {
      this.displayMessage(UnScuccessfullDocumentUploadMessage);
    }
  }

  extractDocType(documentType: string) {
    return documentType.split(' - ').pop();
  }

  handleDocumentUploadError(err: any) {
    this.spinner.hide();
    this.displayMessage(UnScuccessfullDocumentUploadMessage);
    throwError(() => new Error(err));
  }

  displayMessage(message: string) {
    this.toastr.open(message, null, {
      duration: 5000
    });
  }

  updateMilestoneRepaymentDoc(data) {
    const reqbody = {
      Source: 'api-integration',
      DetailType: 'Doc Uploaded',
      Detail: {
        appContext: sessionStorage.getItem('car-ses-con'),
        orderRequestId: sessionStorage.getItem('car-ses-oid'),
        type: 'move',
        docId: data.documentID,
        docType: this.extractDocType(data.documentType)
      }
    };
    this.spinner.show();
    this.uploadDocumentService.updateRepaymentDocMilestone(reqbody).subscribe({
      next: res => {
        if (res) {
          this.spinner.hide();
          this.displayMessage('Repayment milestone updated successfully.');
          this.updateRepayDate(data);
          this.isRepaymentMilestoneAdded.next(true);
        }
      },
      error: () => {
        this.spinner.hide();
        this.displayMessage('Unable to update the repayment milestone.');
      }
    });
  }

  updateDocumentListWithUploadedRecord(uploadedDoc) {
    const newDocument = {
      documentType: this.extractDocType(uploadedDoc.documentType),
      documentID: uploadedDoc.documentID,
      documentDate: uploadedDoc.documentDate
    };
    const docLibrary = this.dashboardService.getDoc.getValue();
    const updatedDocLibrary = {
      ...docLibrary,
      documentList: [...docLibrary.documentList, newDocument],
      count: docLibrary.count + 1
    };
    this.dashboardService.getDoc.next(updatedDocLibrary);
  }

  updateRepayDate(doc) {
    const lumpSumDetails = this.needAssessmentSharedSvc.transfereeRepaymentLumpSumDetails.getValue();
    const updatedRepayDetails = {
      ...lumpSumDetails,
      // Update the repay object with the new date
      repay: {
        ...lumpSumDetails.repay,
        repayAcceptedDate: doc.documentDate
      }
    };
    this.needAssessmentSharedSvc.transfereeRepaymentLumpSumDetails.next(updatedRepayDetails);
  }

  callRepaymentAgreementNotificationEmail() {
    const payload = {
      repaymentPdfContent: this.repaymentBase64Content
    };
    this.dashboardService.callRepaymentAgreementNotificationEmail(payload).subscribe(resp => {
      this.displayMessage(resp.message);
    });
  }
}
