import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';

/**
 * Gets the global API services configuration
 */
@Injectable({
  providedIn: 'root'
})
export class ApiConfigService {
  /** Root URL for API operations */
  rootUrl: string;
  accessManagementRootUrl: string;
  apiIntegrationRootUrl: string;
  documentManagementSystemURL: string;

  /**
   * Initialize the rootURL
   */
  constructor(private readonly config: AppConfigService) {
    const apiConfig: Record<string, string | number> = this.config.getConfig('api');
    this.rootUrl = `${apiConfig.protocol}://${apiConfig.host}`;

    const apiConfig1: Record<string, string | number> = this.config.getConfig('accessManagement');
    this.accessManagementRootUrl = `${apiConfig1.protocol}://${apiConfig1.host}`;

    const apiIntegrationConfig: Record<string, string | number> = this.config.getConfig('integrationApi');
    this.apiIntegrationRootUrl = `${apiIntegrationConfig.protocol}://${apiIntegrationConfig.host}`;

    const dmsurl: Record<string, string | number> = this.config.getConfig('documentManagementSystem');
    this.documentManagementSystemURL = `${dmsurl.protocol}://${dmsurl.host}`;
  }
}
