<mat-card appearance="outlined" class="document-card" fxFlex="100%" tabindex="0" *ngIf="!isLoading">
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <card-title [name]="documentCardTitle" type="documents" imageUri="library_books_black_24dp"></card-title>
      <span class="doc-count">Total {{ docWidth }}</span>
    </div>
    <img
      class="redirect-icon"
      (click)="goToDocumentsPage()"
      src="../../../../../assets/images/cartus-online-icon.svg"
      alt="cartus-online"
    />
  </mat-card-title>
  <mat-card-content class="scroll-content" fxLayout="row" fxLayoutGap="12px">
    <!-- This css class can be added to give different view to newly added doc, presently removing as not required in BB-5191/5192 -->
    <!-- [ngClass]="{'new-doc-card': indx == 0 }" -->
    <div *ngFor="let doc of docs; let indx = index" class="doc" fxLayout="column">
      <div fxLayout="row" fxFlex="100%">
        <div class="left-pane" fxFlex="25%">
          <img width="100%" height="100%" src="../../../../../assets/images/icon_PDF.svg" alt="benefits_builder" role="img" />
          <!-- <img *ngIf="doc.type == 'word'" width="100%" height="100%" src="../../../../../assets/images/icon_Word.svg"
                        alt="benefits_builder" role="img" /> -->
        </div>
        <div class="right-pane" fxFlex="75%">
          <p class="title" (click)="openDownloadToastr(doc)">
            {{ doc.documentType ? doc.documentType : N / A }}
          </p>
          <!-- <div class="ver">
                        <p *ngIf="doc.version>1" class="ver-text">Version: <span class="ver-ed">{{doc.version}}</span><mat-form-field appearance="fill" >
                            <mat-select>
                                <mat-option *ngFor="let version of docVersions" role="listbox" [value]="version.value">
                                    <img src="../../../../../assets/images/material-file-download-blue.svg"
                                        alt="download" />
                                    {{version.date}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field></p>
                        <p *ngIf="doc.version<2" class="ver-text">Version: <span class="ver-ed">{{doc.version}}</span></p>
                    </div> -->
          <div class="ver">
            <p class="ver-text">
              DocId:
              <span class="ver-ed">{{ doc.documentID }}</span>
            </p>
          </div>
        </div>
      </div>
      <p class="date">Date: {{ doc.documentDate | date: 'yyyy-MM-dd' }}</p>
      <!-- <div *ngIf="indx == 0" class="new-doc">New!</div> -->
    </div>
  </mat-card-content>
</mat-card>
