import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimeLine } from '../../../../public/dashboard/services/dashboard.service.service';
import { LanguageTranslationService } from '../../../services/language-translation.service';

@Component({
  selector: 'timeline-div',
  templateUrl: './timeline-div.component.html',
  styleUrls: ['./timeline-div.component.scss']
})
export class TimelineDivComponent implements OnInit {
  @Input() timelineData: TimeLine;
  type: string;
  title: string;
  todayDate = new Date();
  todaysDateWithoutTime = new Date(this.todayDate).setHours(0, 0, 0, 0);

  constructor(translate: TranslateService, private languageTranslationService: LanguageTranslationService) {
    const browserLanguage = this.languageTranslationService.getSupportedLanguage();
    translate.use(browserLanguage);
  }

  ngOnInit() {}

  stateOfEvent() {
    if (this.timelineData.date && new Date(this.timelineData.date).setHours(0, 0, 0, 0) <= this.todaysDateWithoutTime) {
      return 'completed';
    }
    return 'statusNotStarted';
  }
}
