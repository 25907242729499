import { Component, OnInit, Input } from '@angular/core';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { TransfereeNeedsAssessment } from 'src/app/core/models/candidateneeds-assessment.model';
import { CompleteFamilyViewComponent } from '../complete-family-view/complete-family-view.component';
import { Router } from '@angular/router';
import { FamilyMember } from 'src/app/core/models/family-member.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'family-card',
  templateUrl: './family-card.component.html',
  styleUrls: ['./family-card.component.scss']
})
export class FamilyCardComponent implements OnInit {
  @Input() familyData: [];
  /** Current logged in user name */
  username: string;
  /** Total number of family members relocating */
  familyMembersRelocating: number;
  /** Flag to indicate if user has updated family details */
  isFamilyUpdated: boolean;
  /** To store updated family information */
  familyInformation: FamilyMember[] = [];
  /** Flag to check if logged in user is consultant or transferee */
  isConsultant = false;
  relationshipKey = 'bb.relationshipType.';
  browserLanguage: any;
  /** default application language */
  defaultLanguage: string;
  /** */

  @Input() islumpsumDashboard = false;
  queryParamsforRoute;

  /**
   * @param needsAssessmentShared needs assessment shared service
   * @param router inject router
   * @param dialog inject dialog
   */
  constructor(
    private readonly needsAssessmentShared: NeedsAssessmentSharedService,
    private router: Router,
    private dialog: MatDialog,
    private readonly spinner: NgxSpinnerService,
    private readonly cookieService: CookieService,
    translate: TranslateService,
    private readonly languageTranslationService: LanguageTranslationService
  ) {
    this.browserLanguage = this.languageTranslationService.getSupportedLanguage();
    translate.use(this.browserLanguage);
    this.defaultLanguage = this.languageTranslationService.defaultLanguage;
  }

  ngOnInit() {
    this.queryParamsforRoute = this.islumpsumDashboard ? { sourcePageOrigin: 'lumpsum' } : {};
    this.loadNeedsAssessmentData();
    if (this.cookieService.get('transferee-context')) {
      this.isConsultant = true;
    }
  }

  /**
   * To retrieve family information from needs assessment shared service
   */
  loadNeedsAssessmentData() {
    this.spinner.show();

    this.needsAssessmentShared.transfereeNeedsAssessmentDetails.subscribe(data => {
      if (!data) {
        return;
      }
      const needsAssessment: TransfereeNeedsAssessment = data;
      this.username = `${needsAssessment.nameDetails.firstName} ${needsAssessment.nameDetails.lastName}`;
      this.familyMembersRelocating = needsAssessment.totalNumberOfRelocatingMembers;
      /*this.needsAssessmentShared.moveDetails.subscribe(childData => {
                if (childData) {
                    let index = sessionStorage.getItem('car-ses-oid') ?
                            childData.orderRequestDetails.findIndex(ele => ele.orderRequestId === sessionStorage.getItem('car-ses-oid')) : 0;
              */
      //const childNeeedsAssessmentVal = childData.orderRequestDetails[index];
      if (data.children.length > 0) {
        this.isFamilyUpdated = true;
        this.familyInformation = data.children;
        this.familyMembersRelocating <= data.children.length ? (this.familyMembersRelocating = data.children.length + 1) : null;
      } else {
        this.isFamilyUpdated = false;
      }
      //}
      //});

      this.spinner.hide();
    });
  }

  /**
   * Redirect user to update family information page
   */
  updateFamilyMembers() {
    this.router.navigate(['/profile-info', '1'], {
      queryParams: this.queryParamsforRoute
    });
  }

  /**
   * Redirect user to update Profile information page
   */
  updateProfileInfo() {
    this.router.navigate(['/profile-info', '0'], {
      queryParams: this.queryParamsforRoute
    });
  }

  /**
   * To open complete family inforamtion dialog
   * @param familyData family information
   * @param noOfFamilyMembers no of family members relocating
   * @param userName name of current logged in user
   */
  openDialog(familyData, noOfFamilyMembers, userName): void {
    const dialogRef = this.dialog.open(CompleteFamilyViewComponent, {
      width: '641px',
      panelClass: 'complete-family-custom-dialog',
      autoFocus: false,
      data: {
        count: noOfFamilyMembers,
        family: familyData,
        username: userName
      }
    });
  }

  getTranslated(dropdownValue, keyValue) {
    return this.needsAssessmentShared.getTranslated(dropdownValue, keyValue);
  }
}
