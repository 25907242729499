import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputPhoneFormatDirective } from '../directives/input-phone-format.directive';
import { NumberOnlyDirective } from '../directives/number-only.directive';
import { NumCharSpaceDirective } from '../directives/num-char-space.directive';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MultiTypeDirective } from '../directives/multi-type.directive';

@NgModule({
  declarations: [InputPhoneFormatDirective, NumCharSpaceDirective, NumberOnlyDirective, MultiTypeDirective],
  imports: [CommonModule],
  exports: [InputPhoneFormatDirective, NumCharSpaceDirective, NumberOnlyDirective, MultiTypeDirective]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      import: [
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
          }
        })
      ],
      providers: [TranslateService]
    };
  }
}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
