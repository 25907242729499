import { Injectable } from '@angular/core';
import * as moment from 'moment';
import callerPath from 'caller-path';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor(private readonly http: HttpClient, public readonly appConfig: AppConfigService) {}

  log(logData: object) {
    const logStashUrl = String(
      this.appConfig.getConfig('URL_LOGSTASH')
        ? this.appConfig.getConfig('URL_LOGSTASH')
        : 'https://car-alpha-logstash-dev01-982921113.us-west-2.elb.amazonaws.com/'
    );
    if (logStashUrl.length) {
      const url = logStashUrl;
      const body = JSON.stringify(logData);
      this.http.post(`${url}`, body).subscribe(
        success => {
          console.log(success);
        },
        err => {
          console.log(err);
        }
      );
    } else {
      console.log(logData);
    }
  }

  public error(message: string, errorStackTrace?: object, errorSource?: string, additionalErrorLogData?: object): any {
    const erroLogObject = {
      TimeStamp: moment().format(),
      Message: message,
      Error: errorStackTrace,
      ErrorLocation: errorSource || callerPath(),
      AdditionalErrorInfo: additionalErrorLogData
    };
    this.log(erroLogObject);
  }

  public info(message: string, infoLog?: object, infoSource?: string, additionalInfoLogData?: object): any {
    const infoLogObject = {
      TimeStamp: moment().format(),
      Message: message,
      Info: infoLog,
      InfoSource: infoSource || callerPath(),
      AdditionalInfoLogData: additionalInfoLogData
    };
    this.log(infoLogObject);
  }

  public debug(message: string, debugLog?: object, debugSource?: string, additionalInfoLogData?: object): any {
    const debugLogObject = {
      TimeStamp: moment().format(),
      Message: message,
      debug: debugLog,
      DebugSource: debugSource || callerPath(),
      AdditionalInfoLogData: additionalInfoLogData
    };

    this.log(debugLogObject);
  }

  public dataAudit(type: object, userId: string, event: string, newValue?: object, previousValue?: object): any {
    const dataAuditLogObject = {
      Source: 'TRANSFEREE_UI',
      AuditType: type,
      Event: event,
      UserId: userId,
      PreviousValue: previousValue,
      NewValue: newValue,
      TimeStamp: new Date()
    };
    this.log(dataAuditLogObject);
  }

  public activityAudit(type: string, userId: string, event: string, activity?: string): any {
    const activityAuditLogObject = {
      Source: 'TRANSFEREE_UI',
      AuditType: type,
      Event: event,
      UserId: userId,
      Activity: activity,
      TimeStamp: new Date()
    };
    this.log(activityAuditLogObject);
  }
}
