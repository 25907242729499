<ng-container *ngIf="isVisible">
  <ng-container *ngIf="chartData && chartData.length > 0">
    <div id="d-chart" style="display: none;">
      Benefits donught chart showing benefit categories
    </div>
    <svg
      height="160"
      width="160"
      viewBox="0 0 160 160"
      class="donut-chart"
      [attr.aria-label]="amount + ' redeemed'"
      aria-labelledby="d-chart"
      focusable="false"
      alt=""
      title="Donught chart"
      role="img"
    >
      <text style="display: none;">
        Benefits Categories Donught Chart
      </text>
      <desc>Shows the benefits categories in a donught chart</desc>
      <g *ngFor="let value of sortedValues; let i = index">
        <circle
          [attr.cx]="cx"
          [attr.cy]="cy"
          [attr.r]="radius"
          [attr.stroke]="colors[i]"
          [attr.stroke-width]="strokeWidth"
          [attr.stroke-dasharray]="adjustedCircumference()"
          [attr.stroke-dashoffset]="calculateStrokeDashOffset(value)"
          fill="transparent"
          stroke-linecap="round"
          [attr.transform]="returnCircleTransformValue(i)"
          [style.transition]="'stroke-dashoffset 600ms linear 1400ms'"
        />

        <!-- <text text-anchor="middle" dy="3px" x="getTextData(index, 'textX')" y="getTextData(index, 'textY')"></text> -->
      </g>
    </svg>
  </ng-container>
</ng-container>
