import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DashboardService } from '../dashboard/services/dashboard.service.service';
import { NeedsAssessmentSharedService } from '../needs-assessment/services/needs-assessment-shared.service';

@Component({
  selector: 'app-document-library-page',
  templateUrl: './document-library-page.component.html',
  styleUrl: './document-library-page.component.scss'
})
export class DocumentLibraryPageComponent implements OnInit, OnDestroy {
  /*flag is set if it is lumpsum dashboard*/
  isLumpsum = false;
  docVersions2 = [
    { date: '2020-07-28', value: 2 },
    { date: '2020-07-14', value: 1 }
  ];
  docVersions3 = [
    { date: '2020-08-23', value: 2 },
    { date: '2020-07-17', value: 1 },
    { date: '2020-06-09', value: 1 }
  ];
  docs: any[] = [];
  orderRequest: string;
  routeSub: Subscription;

  @Input() docData: any;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private readonly toastr: MatSnackBar,
    private needsAssessmentSharedSvc: NeedsAssessmentSharedService
  ) {}

  ngOnInit() {
    this.getQueryParams();

    this.dashboardService.documentData.subscribe(res => {
      this.docs = res.documentList;
    });
  }

  getQueryParams() {
    this.routeSub = this.activatedRoute.queryParams.subscribe(param => {
      this.orderRequest = param.orderRequest;
    });
  }

  goToDashboard() {
    if (this.isLumpsum) {
      this.router.navigate(['/lumpsum-dashboard']);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  openDownloadToastr(dmsData) {
    const message = 'Downloading files with sensitive information is a security risk, please clear your cache afterwards';
    const toaster = this.toastr.open(message, 'DOWNLOAD', {
      duration: 100000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toaster']
    });
    toaster.onAction().subscribe(_ => this.dashboardService.downloadDMSFile(this.orderRequest, dmsData));
  }

  ngOnDestroy(): void {
    this.routeSub ? this.routeSub.unsubscribe() : null;
  }
}
