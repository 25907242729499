import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { disclaimer } from '../../../models/constants';
import { LanguageTranslationService } from '../../../services/language-translation.service';
// import { Content } from '@angular/compiler/src/render3/r3_ast';
@Component({
  selector: 'video-destination-card',
  templateUrl: './video-destination-card.component.html',
  styleUrls: ['./video-destination-card.component.scss']
})
export class VideoDestinationCardComponent implements OnInit {
  @Input() cardInfo: any;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private languageTranslationService: LanguageTranslationService
  ) {
    const browserLanguage = this.languageTranslationService.getSupportedLanguage();
    translate.use(browserLanguage);
  }

  ngOnInit() {
    this.cardInfo.content.map(ele => {
      ele.translated = this.translate.instant('bb.video.' + ele.imageKey);
    });
  }

  openVideoPopup(i) {
    let relo = false;
    i == 0 ? (relo = true) : null;
    this.dialog.open(VideoPopupComponent, {
      width: '700px',
      height: '650px',
      data: {
        relo: relo
      }
    });
  }
}

@Component({
  selector: 'app-video-popup',
  template: `
    <mat-dialog-content class="dialog-content">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="mat-dialog-title dialog-title">
        <p *ngIf="data.relo">{{ 'bb.video.explore' | translate }}</p>
        <p *ngIf="!data.relo">
          {{ 'bb.video.benefitsBuilderPointers' | translate }}
        </p>
        <button mat-icon-button mat-dialog-close style="font-size: 2em; color: #575A5D;">
          <span class="material-icons close-icon">close</span>
        </button>
      </div>
      <div *ngIf="!data.relo">
        <h5 style="font-style:italic">
          {{ 'bb.video.benefitsTitle' | translate }}
        </h5>
      </div>
      <div>
        <h6 style="font-style:italic">
          {{ 'bb.video.disclmr' | translate }}
        </h6>
      </div>
      <mat-dialog-content class="mat-typography ">
        <iframe frameborder="0" id="iframe" width="100%" height="450px" #qumu title="All Marketing Videos"></iframe>
      </mat-dialog-content>
      <mat-dialog-content> </mat-dialog-content
    ></mat-dialog-content>
  `,
  styleUrls: ['./video-destination-card.component.scss']
})
export class VideoPopupComponent implements AfterViewInit {
  @ViewChild('qumu', { static: false }) qumu: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
  disclmr = disclaimer;

  ngAfterViewInit(): void {
    const url = this.getGeneratedPageURL();
    const iframe: HTMLFrameElement = this.qumu.nativeElement;
    iframe['src'] = url;
  }

  getGeneratedPageURL() {
    const getBlobURL = (code: string, type: string) => {
      const blob = new Blob([code], { type });
      return URL.createObjectURL(blob);
    };

    const source = `
      <html>
        <head>
        <style>
        body {
          display:flex; flex-direction:column; justify-content:center;
          min-height:100vh;
          overflow:hidden;
        }
        body .kv-carousel-body {
            height: auto;
            position: relative;
         }
        body .kv-carousel-container{
            height: auto;
            margin:auto;
            padding: 10px;
        }
        </style>
          <script type="text/javascript" src="https://cartus.qumucloud.com/widgets/application.js"><\/script>
          ${`<script type="text/javascript">KV.widget({"guid": "RFUUDGIiiVf","type": "carousel","size": 20,"info": {"over": ["title"]},
          "sort": [{"field": "created","direction": "asc"}],"packshot": {"width": 250},
          "pageIndicatorsPosition": "bottom","playIcon": {"position": "bottom-left"},
          "hideEmbedButton": true,"showInfoPanel": false,"hideDownloadButton": true,"hideShareButton": true});\<\/script>`}
        </head>
        <body>
        </body>
      </html>
    `;

    const sourceBbPointers = `
      <html>
        <head>
        <style>
        body {
          display:flex; flex-direction:column; justify-content:center;
          min-height:100vh;
          overflow:hidden;
        }
        body .kv-carousel-body {
            height: auto;
            position: relative;
         }
        body .kv-carousel-container{
            height: auto;
            margin:auto;
            padding: 10px;
        }
        </style>
        <script type="text/javascript" src="https://cartus.qumucloud.com/widgets/application.js"><\/script>
        ${`<script type="text/javascript">KV.widget({"guid": "WPksHxVvmcXuu21s9Twahb","type": "carousel","size": 20,"info": {"over": ["title"]},
         "sort": [{"field": "created","direction": "asc"}],"packshot": {"width": 250},
         "pageIndicatorsPosition": "bottom","playIcon": {"position": "bottom-left"},
         "hideEmbedButton": true,"showInfoPanel": false,"hideDownloadButton": true,"hideShareButton": true});\<\/script>`}
        </head>
        <body>
        </body>
      </html>
    `;

    if (this.data.relo) {
      return getBlobURL(source, 'text/html');
    } else {
      return getBlobURL(sourceBbPointers, 'text/html');
    }
  }
}
