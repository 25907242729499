import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { TokenPayload } from '../models/tokenPayload.model';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  /** Variable to store the token  */
  public authClient: OktaAuth;

  constructor(private readonly cookieService: CookieService, public readonly appConfig: AppConfigService) {
    const authOpts: OktaAuthOptions = {
      clientId: `${this.appConfig.getConfig('oktaClientId')}`.toString(),
      issuer: `${this.appConfig.getConfig('oktaUrl')}`.toString(),
      authorizeUrl: `${this.appConfig.getConfig('oktaUrl')}/v1/authorize`,
      redirectUri: `${this.appConfig.getConfig('oktaRedirectUri')}`.toString(),
      postLogoutRedirectUri: `${this.appConfig.getConfig('oktaRedirectUri')}`.toString(),
      tokenManager: {
        storage: 'sessionStorage'
      }
    };
    this.authClient = new OktaAuth(authOpts);
    let idToken, accessToken;
    if (this.cookieService.get('car-ses-tok') && this.cookieService.get('car-token-idtoken')) {
      idToken = this.cookieService.get('car-token-idtoken');
      accessToken = this.cookieService.get('car-ses-tok');
    }
  }

  /** Service call for login */
  async login(user: TokenPayload) {
    try {
      const data = {
        username: user.email,
        password: user.password
      };
      const transaction = await this.authClient.signIn(data);
      const tokens = await this.authClient.token.getWithoutPrompt().then(tokenOrTokens => {
        if (tokenOrTokens) {
          if (tokenOrTokens.tokens && tokenOrTokens.tokens.idToken && tokenOrTokens.tokens.accessToken) {
            this.authClient.tokenManager.setTokens({
              accessToken: tokenOrTokens.tokens.accessToken,
              idToken: tokenOrTokens.tokens.idToken
            });
            localStorage.setItem('token', String(tokenOrTokens.tokens.accessToken));
          }
          return this.authClient;
        }
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async isAuthenticated() {
    // Checks if there is a current accessToken in the TokenManger.
    return this.authClient.tokenManager.get('accessToken');
  }

  /** Okta call for token refresh */
  public refreshSession() {
    return this.authClient.token
      .getWithoutPrompt()
      .then(tokenOrTokens => {
        return tokenOrTokens;
      })
      .catch(err => {
        // console.error(err); // Leave this debug code in place -- commented out due to error being displayed regardless
        return; // Not authenticated
      });
  }
}
/** Credetials to be sent with login service call  */
