<div class="profile-wrapper">
  <div class="tab-group-wrapper">
    <div class="scroll-content">
      <ng-container *ngIf="!isReapyCompleted">
        <mat-card appearance="outlined" class="contact-details">
          <mat-card-title role="heading" aria-level="2">
            <p style="font-size:20px">
              Lump Sum Relocation Allowance Repayment Agreement
            </p>
            <p>
              {{ clientName }}<br />
              {{ firstName }} {{ lastName }}<br />
              {{ moveOrderID }}{{ custFileID ? ' / ' + custFileID : '' }}<br />
              {{ departureFrom }} to {{ destinationTo }}
            </p>
            <p>{{ updatedRepayContent.paragraph1 }}</p>
            <p>{{ updatedRepayContent.paragraph2 }}</p>
            <p>{{ updatedRepayContent.paragraph3 }}</p>
            <p *ngIf="lumpsumDetails?.repay?.repaymentSchedules?.length > 0">
              Repayment Schedule:<br />
              <ng-container *ngFor="let dataItem of lumpsumDetails?.repay?.repaymentSchedules"
                >&nbsp;&nbsp;&nbsp;{{ dataItem.months }} months or less {{ dataItem.repaymentPercent }}%<br
              /></ng-container>
            </p>
            <p>{{ updatedRepayContent.paragraph4 }}</p>
            <p>{{ updatedRepayContent.paragraph5 }}</p>
            <p>{{ updatedRepayContent.paragraph6 }}</p>
            <p>{{ updatedRepayContent.paragraph7 }}</p>
            <p>{{ updatedRepayContent.paragraph8 }}</p>
            <p>{{ updatedRepayContent.paragraph9 }}</p>
            <p>{{ updatedRepayContent.paragraph10 }}</p>
            <p>{{ updatedRepayContent.paragraph11 }}</p>
          </mat-card-title>
        </mat-card>

        <mat-card appearance="outlined" class="contact-details">
          <mat-card-title role="heading" aria-level="2">
            <form [formGroup]="repayFormGroup">
              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
                <mat-form-field class="first-name" fxFlex="45%" fxFlex.xs="50%">
                  <mat-label>Enter Legal Name</mat-label>
                  <input matInput character-only [readonly]="isCoBrowsing" [disabled]="isCoBrowsing" formControlName="legalName" required />
                </mat-form-field>
                <mat-form-field class="first-name" fxFlex="50%" fxFlex.xs="50%">
                  <mat-label>Date of Signature</mat-label>
                  <input matInput readonly datePickerFormat="YYYY-MM-DD" [readonly]="isCoBrowsing" formControlName="dateOfSignature" />
                </mat-form-field>
              </div>
              <div fxLayout="row">
                <div fxFlex="100%" fxFlex.xs="100%">
                  <mat-checkbox [disabled]="isCoBrowsing" formControlName="conscentChecked">
                    <p style="white-space: normal;">
                      By selecting the E-sign “I Confirm” below, you agree that you have read, understand and consent to the terms of this
                      Repayment Agreement.
                    </p>
                  </mat-checkbox>
                </div>
              </div>
            </form>
          </mat-card-title>
        </mat-card>

        <div fxLayout="row" fxLayoutAlign="end center" class="m-t-20">
          <div fxLayout="column">
            <button
              class="mat-button"
              type="button"
              class="accept-btn"
              [disabled]="!repayFormGroup.valid || isCoBrowsing"
              (click)="onRepayAgrementAcceptance()"
            >
              I Confirm
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isReapyCompleted">
        <mat-card appearance="outlined" class="contact-details">
          <mat-card-title>
            <p>
              The Repayment Agreement has already been completed on
              {{ lumpsumDetails?.repay?.repayAcceptedDate | date: 'dd MMM yyyy' }}.
            </p>
            <p>You can access it from Document Library.</p>
            <button class="mat-button outlined-button m-t-20 doc-lib-nav-btn" type="button" (click)="goToDocumentsPage()">
              Go to Document Library
            </button>
          </mat-card-title>
        </mat-card>
      </ng-container>
    </div>
  </div>
</div>
