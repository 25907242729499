import { Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NeedsAssessmentSharedService } from '../../../../public/needs-assessment/services/needs-assessment-shared.service';
import { LanguageTranslationService } from '../../../services/language-translation.service';
import { TransfereeNeedsAssessment } from '../../../models/candidateneeds-assessment.model';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BenefitService } from 'src/app/public/benefits/services/benefit.service';
import { CollaborationService } from 'src/app/core/services/collaboration.service';
import { Subscription } from 'rxjs';
import { CollabEvents, CollabMessage } from 'src/app/core/services/collaboration-events.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'bank-info-widget',
  templateUrl: './bank-info-widget.component.html',
  styleUrls: ['./bank-info-widget.component.scss']
})
export class BankInfoWidgetComponent implements OnInit, OnChanges, OnDestroy {
  @Input() orderRequestId: string;
  /** to store values retrieved from needs assessment */
  needsAssessment: TransfereeNeedsAssessment;
  /** to store values of liveEasy url */
  bankInfo = [];
  accountNumberToShow = '';
  isCoBrowsing = false;
  primaryBankDetails: any;
  @Input() lumpsumDetails: any;
  isClicked = false;
  collabSubscription: Subscription;

  constructor(
    private readonly needsAssessmentShared: NeedsAssessmentSharedService,
    private readonly route: Router,
    private readonly cookieService: CookieService,
    translate: TranslateService,
    private readonly benefitService: BenefitService,
    public readonly collaboration: CollaborationService,
    private languageTranslationService: LanguageTranslationService,
    private readonly spinner: NgxSpinnerService
  ) {
    translate.use(this.languageTranslationService.getSupportedLanguage());
  }

  ngOnInit(): void {
    //this.getBankingInfo();
    this.isCoBrowsing = this.cookieService.get('transferee-context') ? true : false;
    this.observeCollab();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.orderRequestId) {
      this.getBankingInfo(true);
    }
  }

  observeCollab() {
    this.collabSubscription = this.collaboration.socketSubscriber$.subscribe((message: CollabMessage) => {
      if (!message) {
        return;
      }
    });
  }

  getBankingInfo(isBankInfo) {
    return this.benefitService.getBankInfoData(this.orderRequestId).subscribe(res => {
      this.spinner.show();
      if (res) {
        console.log('res bank info service', res);
        // this.needsAssessmentShared.lumpsumStatusData.subscribe(resLumsumData => {
        //   if(!resLumsumData){
        //     return;
        //   }
        //   if(resLumsumData){
        //     return resLumsumData.bankInfo = res.body;
        //   }
        // })

        this.bankInfo = res.body;
        if (this.bankInfo.length > 0) {
          this.primaryBankDetails = this.bankInfo.find(x => x.isPreferred === true);
          this.setBankAccountInfo();
          if (!this.isCoBrowsing && isBankInfo) {
            this.collaboration.sendMessage({
              data: this.bankInfo,
              event: CollabEvents.BANK_ACCOUNT_INFO_WIDGET_UPDATE
            });
            sessionStorage.setItem('car-ses-oid', this.orderRequestId);
          }
        }
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }

  setBankAccountInfo() {
    this.accountNumberToShow =
      'X'.repeat(this.primaryBankDetails.accountNumber.length - 4) +
      this.primaryBankDetails?.accountNumber.slice(this.primaryBankDetails.accountNumber.length - 4);
  }

  mousedown() {
    this.isClicked = true;
    this.accountNumberToShow = this.primaryBankDetails?.accountNumber;
  }

  mouseLeaveEvent() {
    if (this.isClicked) {
      this.isClicked = false;
      this.setBankAccountInfo();
    }
  }

  /** to update bank account info in co-browsing mode*/
  updateBankInfoCollab(bankInfo) {
    if (this.cookieService.get('transferee-context')) {
      this.spinner.show();
      this.bankInfo = bankInfo;
      if (this.bankInfo.length > 0) {
        this.primaryBankDetails = this.bankInfo.find(x => x.isPreferred === true);
        this.setBankAccountInfo();
      }
      this.spinner.hide();
    }
  }

  ngOnDestroy() {
    if (this.collabSubscription) this.collabSubscription.unsubscribe();
  }
}
