import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pay-through-bank-help-dialog',
  templateUrl: './pay-through-bank-help-dialog.component.html',
  styleUrls: ['./pay-through-bank-help-dialog.component.scss']
})
export class PayThroughBankHelpDialogComponent implements OnInit {
  constructor(public ptbHelpDialogRef: MatDialogRef<PayThroughBankHelpDialogComponent>) {}

  ngOnInit(): void {}

  closeDialog() {
    this.ptbHelpDialogRef.close();
  }
}
