import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocationsService } from 'src/app/public/needs-assessment/services/locations.service';
import { BenefitService } from 'src/app/public/benefits/services/benefit.service';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from '../../services/language-translation.service';

@Component({
  selector: 'app-dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.scss']
})
export class DialerComponent implements OnInit, OnDestroy {
  observeNeedsAssessment: Subscription;

  showDialer: boolean;
  moveData: any;
  tempCountryList: any;
  benefits: any;
  confirmedMMUbenefits: any = [];
  /**property for which benefit tab selected or not */
  @Input() isDashBoard: boolean;
  dialerCountOptions = {
    startVal: 0,
    duration: 5,
    useEasing: true
  };

  totalPoints = 0;
  totalAmount = 0;
  totalAmountSelected = 0;
  currency: string;
  isBudgetModel = false;
  lengthOfBudget = 0;
  lengthOfTotalAmount = 0;
  actualSpend = 0;
  actualRemaining = 0;
  totalPointsSelected = 0;
  totalPointsConfirmed = 0;
  totalAmountConfirmed = 0;

  filters: any[] = [];
  colors: any;
  url = '';
  actualCostInformation: any;
  benefitByColors: any;

  actualDialerCountOptions = {
    startVal: 0,
    duration: 5,
    useEasing: true
  };

  constructor(
    private needsAssessmentService: NeedsAssessmentSharedService,
    private benefitService: BenefitService,
    private readonly locationService: LocationsService,
    private router: Router,
    translate: TranslateService,
    private languageTranslationService: LanguageTranslationService
  ) {
    this.actualCostInformation = this.benefitService.actualCostInformation.getValue();
    translate.use(this.languageTranslationService.getSupportedLanguage());
  }

  ngOnInit() {
    let benefits;
    this.benefitService.benefitsData.subscribe(data => {
      if (data) {
        benefits = JSON.parse(JSON.stringify(data));
        if (benefits.benefitsActualsInfo && benefits.benefitsActualsInfo.hasOwnProperty('totalsInfo')) {
          this.actualSpend = benefits.benefitsActualsInfo.totalsInfo.actualSpend;
          this.actualRemaining = benefits.benefitsActualsInfo.totalsInfo.actualRemaining;
          // To add decimals in countUp
          if (this.actualSpend % 1 != 0) {
            const noOFDecimalPoints = String(this.actualSpend).split('.')[1].length;
            this.actualDialerCountOptions['decimalPlaces'] = noOFDecimalPoints > 2 ? 2 : noOFDecimalPoints;
          }
        }
        this.filterCategory(benefits);
      }
    });
    const benefitsData = this.benefitService.benefitsData.getValue();
    if (benefitsData) {
      this.actualCostInformation =
        benefitsData.benefitsActualsInfo && benefitsData.benefitsActualsInfo.totalsInfo
          ? benefitsData.benefitsActualsInfo.totalsInfo
          : null;
      this.filterCategory(JSON.parse(JSON.stringify(benefitsData)));
    }
    this.observePoints();
  }

  filterCategory(data) {
    // this.actualSpend = 0;
    //  this.actualRemaining = this.totalAmount;
    if (data) {
      if (this.isBudgetModel) {
        this.confirmedMMUbenefits = data.confirmedBenefits.filter(benefit => benefit.amount !== 'Guaranteed');
        if (this.actualCostInformation && this.confirmedMMUbenefits) {
          this.actualSpend = this.actualCostInformation.actualSpend;
          this.actualRemaining = this.actualCostInformation.actualRemaining;
          this.actualRemaining < 0 ? (this.actualRemaining = 0) : null;
        }
      }
      this.colors = this.benefitService.colors;
      const filters = this.benefitService.categoryNames;
      this.benefitByColors = this.benefitService.BenefitColorMapping;
      data.availableBenefits = data.availableBenefits.filter(benefit => benefit.points !== 'Guaranteed');
      data.selectedBenefits = data.selectedBenefits.filter(benefit => benefit.points !== 'Guaranteed');
      data.confirmedBenefits = data.confirmedBenefits.filter(benefit => benefit.points !== 'Guaranteed');
      const availableCatagories = data.availableBenefits.map(item => item.category);
      let selectedCatagories = data.selectedBenefits.map(item => item.category);
      const confirmedCatagories = data.confirmedBenefits.map(item => item.category);
      selectedCatagories = [...new Set(selectedCatagories.concat(confirmedCatagories.concat(availableCatagories)))];
      setTimeout(() => {
        this.filters = filters.filter(ele => selectedCatagories.includes(ele.en));
      }, 1000);
    }
  }

  observePoints() {
    this.observeNeedsAssessment = this.needsAssessmentService.transfereeNeedsAssessmentDetails.subscribe(data => {
      this.moveData = data;
      if (!data) {
        return;
      }
      if (data.pointDetails) {
        this.totalPoints = data.pointDetails.points || 0;
        if (data.pointDetails.isCashoutSpecialized) {
          data.pointDetails.selectedPoints = data.pointDetails.points;
        }
        this.totalPointsConfirmed = data.pointDetails.confirmedPoints || 0;
        this.totalPointsSelected = (data.pointDetails.selectedPoints || 0) + (data.pointDetails.confirmedPoints || 0);
      }
      if (data.budgetDetails) {
        this.isBudgetModel = true;
        this.totalAmount = data.budgetDetails.totalAmount || 0;
        if (data.isCashedOut) {
          data.budgetDetails.selectedAmount = data.budgetDetails.totalAmount - (data.budgetDetails.confirmedAmount || 0);
        }
        this.totalAmountSelected = (data.budgetDetails.selectedAmount || 0) + (data.budgetDetails.confirmedAmount || 0);
        this.totalAmountConfirmed = data.budgetDetails.confirmedAmount || 0;
        this.lengthOfBudget = this.totalAmountConfirmed.toString().length;
        this.lengthOfTotalAmount = this.totalAmount.toString().length;
        this.currency = data.budgetDetails.currency;
        this.benefitService.amountRedeemed = this.totalAmountSelected + this.currency;
      }
    });

    this.locationService.countryList.subscribe(countryListData => {
      if (countryListData.length > 0 && this.moveData && this.moveData.departureAddr) {
        this.tempCountryList = countryListData.find(ele => ele.countryName === this.moveData.departureAddr.country);
        this.moveData.departureAddr['countryCode'] = this.tempCountryList.countryiso3CharCode;
      }
      if (countryListData.length > 0 && this.moveData && this.moveData.destinationAddr) {
        this.tempCountryList = countryListData.find(ele => ele.countryName === this.moveData.destinationAddr.country);
        this.moveData.destinationAddr['countryCode'] = this.tempCountryList.countryiso3CharCode;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.observeNeedsAssessment) {
      this.observeNeedsAssessment.unsubscribe();
    }
  }
}
