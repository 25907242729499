<mat-dialog-content>
  <div class="help-message">
    A pay through bank is an intermediary bank between the issuing bank and the receiving bank usually in different countries. They can also
    be used for electronic funds transfer and routing to your designated financial institution when you may not be using a checking account
    for the deposit. If Cartus has a known pay through bank requirement, Cartus will populate these fields during the banking information
    validation process. If you are unsure if this is a requirement for your bank, you may want to check with your bank directly or contact
    the Cartus Banking Team for guidance. The Cartus Banking Team can be reached at 1-800-965-5126 or 1-203-205-6076.
    <button class="mat-button outlined-button primary okay-btn" (click)="closeDialog()" type="button">
      Okay
    </button>
  </div>
</mat-dialog-content>
