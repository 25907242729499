<div fxFlex="100%" class="timeline-wrapper" fxLayoutAlign="start baseline" role="group" *ngIf="timelineData">
  <div fxFlex="10%" class="circle-wrapper" role="none">
    <ng-container *ngIf="stateOfEvent() === 'statusNotStarted'">
      <ng-container *ngTemplateOutlet="statusNotStarted"></ng-container>
    </ng-container>

    <ng-container *ngIf="stateOfEvent() === 'completed'">
      <ng-container *ngTemplateOutlet="completed"></ng-container>
    </ng-container>
  </div>
  <div fxFlex="80%" fxFlex.md="83%" role="none">
    <div fxLayout="column" fxFlex="100%" role="none">
      <div fxLayout="row" class="title-wrapper" fxFlex="100%" fxLayoutAlign="start center" role="none">
        <span class="date" *ngIf="timelineData.date" role="note" aria-label="timelineData.date">{{ timelineData.date }}</span>
        <span class="title" role="note" aria-label="timelineData.source">{{ timelineData.source }}</span>
      </div>
      <p fxLayout="row" fxFlex="100%" class="description" role="note" aria-label="timelineData.description">
        {{ timelineData.description }}
      </p>
    </div>
  </div>
</div>

<!-- Status when not started-->
<ng-template #statusNotStarted>
  <span class="circle empty" role="none"></span>
</ng-template>
<!-- Status when In Progress-->
<ng-template #statusInProgress>
  <span class="circle" role="none"></span>
</ng-template>
<!-- Status when Waiting for approval-->
<ng-template #waitingForApproval>
  <span class="circle orange" role="none">
    <mat-icon aria-label="query_builder icon">query_builder</mat-icon>
  </span>
</ng-template>
<!-- Status when Completed-->
<ng-template #completed>
  <span class="circle" role="none">
    <mat-icon aria-label="done icon">done</mat-icon>
  </span>
</ng-template>
