<ngx-spinner #spinnerLoading type="ball-spin-clockwise-fade" role="alert" aria-live="assertive" size="medium">
  <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>
<div [ngClass]="showNeedsAssessmentBG ? 'main-wrapper with-bg' : 'main-wrapper dashboard-bg'">
  <mat-sidenav-container class="sidenav-wrapper">
    <mat-sidenav #sidenav>
      <div class="left-navigation" fxLayout.sm="column" fxLayout.xs="column">
        <div class="navigation-header" fxLayout="row" fxLayoutAlign="space-between center">
          <div>
            <span class="profile-icon">{{ initials }}</span>
            <span class="username">{{ username }}</span>
          </div>
          <mat-icon aria-hidden="false" aria-label="close icon" class="close-navigation" (click)="sidenav.close()">close</mat-icon>
        </div>
        <div class="navigation-list">
          <mat-action-list [multiple]="false">
            <!--For Future use-->
            <!-- <a mat-list-item>
                          <img  src="../assets/images/my-task.svg" alt='my-task' />
                          <span matListItemLine>My Tasks</span>
                      </a> -->
            <a mat-list-item>
              <img src="../assets/images/select-benefits.svg" matListItemIcon alt="select-benefits" />
              <span matListItemLine (click)="goToBenefits()">Select Benefits</span>
            </a>
            <a mat-list-item>
              <img src="../assets/images/previous-benefit.svg" matListItemIcon alt="previous-benefit" />
              <span matListItemLine (click)="goToReviewSelectedBenefits()">Previous Benefits</span>
            </a>
            <a mat-list-item>
              <img src="../assets/images/schedule-benefit.svg" matListItemIcon alt="schedule-benefit" />
              <span matListItemLine (click)="goToConfirmedBenefits()">Schedule Benefit</span>
            </a>
          </mat-action-list>
          <mat-divider></mat-divider>
          <mat-action-list [multiple]="false" class="user-options">
            <a mat-list-item (click)="navigateTo('/profile-info/0')" [ngClass]="isLinkActive('/profile-info/0') ? 'is-active' : ''">
              <img matListItemIcon src="../assets/images/profile.svg" alt="profile" />
              <span matListItemLine>My Profile</span>
            </a>
            <a mat-list-item (click)="navigateTo('/profile-info/1')" [ngClass]="isLinkActive('/profile-info/1') ? 'is-active' : ''">
              <img matListItemIcon src="../assets/images/family.svg" alt="family" />
              <span matListItemLine>My Family</span>
            </a>
            <!--Future Use-->
            <!-- <a mat-list-item>
                          <img  src="../assets/images/help-faq.svg" alt='help-faq' />
                          <span matListItemLine>Help & FAQs</span>
                      </a> -->
            <a mat-list-item [routerLink]="['/logout']">
              <img matListItemIcon src="../assets/images/logout.svg" alt="logout" />
              <span matListItemLine>Logout</span>
            </a>
          </mat-action-list>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <header role="banner">
        <app-header
          [openNotificationMenu]="openNotificationMenu"
          [notificationList]="notificationList"
          (menuClosed)="notificationClosed()"
          (openSideNav)="sideNavHandle()"
          [isNeedsAssessment]="showNeedsAssessmentBG"
        ></app-header>
      </header>
      <main role="navigation" [ngClass]="{ 'notification-div': displayTaskNotification }">
        <app-notification
          *ngIf="needsAssessmentCompleted"
          (openNotification)="openNotification($event)"
          (displayNotification)="updateNotification($event)"
        ></app-notification>
        <router-outlet></router-outlet>
      </main>
      <footer role="contentinfo">
        <app-footer [isBenefitsActive]="isBenefitsActive" [needsAssessmentCompleted]="needsAssessmentCompleted"></app-footer>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<!-- To indicate Collab Session is Live -->
<div id="collab-session" aria-label="Status" role="status" [ngClass]="{ down: displayTaskNotification }">
  <span class="blinking" role="none"></span>
  <span id="collab-text" role="none"></span>
</div>
