import { Injectable, ApplicationRef, Injector, ComponentRef, EmbeddedViewRef } from '@angular/core';
import { DialogComponent } from './../dialog.component';
import { ViewContainerRef } from '@angular/core';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  dialogComponentRef: ComponentRef<DialogComponent>;
  content = '';
  isbutton: boolean;
  notificationType = '';

  constructor(private appRef: ApplicationRef, private injector: Injector) {}

  appendDialogComponentToBody(viewContainerRef: ViewContainerRef) {
    // Directly use the ViewContainerRef to create the component
    const componentRef = viewContainerRef.createComponent(DialogComponent);
    this.dialogComponentRef = componentRef;

    // Manual DOM append if needed - in most cases, Angular handles DOM integration
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }

  public removeDialogComponentFromBody() {
    if (this.dialogComponentRef && this.appRef) {
      this.appRef.detachView(this.dialogComponentRef?.hostView);
      this.dialogComponentRef?.destroy();
    }
  }

  public open(viewContainerRef: ViewContainerRef, type: string, content: string, isbutton: boolean) {
    this.appendDialogComponentToBody(viewContainerRef);
    this.notificationType = type;
    this.isbutton = isbutton;
    this.content = content;

    // Auto dismisses in 5 sec.
    timer(5000).subscribe(() => {
      this.removeDialogComponentFromBody();
    });
  }
}
