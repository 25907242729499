import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { PdfFontsService } from '../../../../../src/app/public/benefits/services/pdf-fonts.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';

@Injectable({
  providedIn: 'root'
})
export class PropertyDetailsPdfService {
  borderColor = '#AAAAAA';
  labelColor = '#19305A';
  labelFont = 8;
  labelLineHeight = 0.75;
  fieldValueColor = '#22222';
  fieldValueFont = 11;
  sectionHeaderFont = 10;
  lineHeight = 1.5;
  workOrderBorderColor = '#bde1ff';

  constructor(
    private readonly pdfMakeFonts: PdfFontsService,
    public translate: TranslateService,
    private readonly languageTranslationService: LanguageTranslationService
  ) {
    pdfMakeFonts.defineFonts();
    pdfMakeFonts.assignFonts();
    this.pdfMakeFonts.loadLogos();
    translate.use(this.languageTranslationService.getSupportedLanguage());
  }

  generateDocumentDefinition(downloadData) {
    const documentDefinition = {
      info: {
        title: 'Program Details',
        subject: 'Details of programs listed'
      },
      pageOrientation: 'portrait',
      pageSize: 'LETTER',
      pageMargins: [40, 110, 40, 110],
      header: currentPage => {
        return {
          table: {
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [
              [
                {
                  image: this.pdfMakeFonts.bbLogob64,
                  width: 120,
                  height: 40,
                  alignment: 'left',
                  color: '#FFFFFF',
                  fillColor: '#FFFFFF',
                  border: [false, false, false, false],
                  margin: [30, 35, 0, 0]
                },
                {
                  image: this.pdfMakeFonts.cartusLogob64,
                  width: 120,
                  height: 40,
                  alignment: 'right',
                  color: '#FFFFFF',
                  fillColor: '#FFFFFF',
                  border: [false, false, false, false],
                  margin: [0, 35, 20, 0]
                }
              ]
            ]
          }
        };
      },
      footer: (currentPage, pageCount) => {
        const year = new Date().getFullYear();
        return {
          table: {
            headerRows: 1,
            widths: ['60%', '40%'],
            body: [
              [
                {
                  text: `© ${year} Cartus Corporation | All rights reserved. 
               Cartus and the Cartus logo are registered trademarks of Cartus Corporation
               `,
                  fontSize: 9,
                  color: '#575A5D',
                  alignment: 'left',
                  border: [false, false, false, false],
                  margin: [30, 30, 0, 0]
                },
                {
                  text: `Page ${currentPage.toString()}`,
                  fontSize: 9,
                  color: '#575A5D',
                  alignment: 'right',
                  border: [false, false, false, false],
                  margin: [0, 30, 30, 0]
                }
              ]
            ]
          }
        };
      },
      styles: {
        avenirBook: {
          font: 'AvenirBook',
          normal: true
        },
        avenirLight: {
          font: 'AvenirLight',
          fontSize: 18,
          normal: true
        },
        avenirMedium: {
          font: 'AvenirMedium',
          normal: true
        },
        programTable: {
          border: [true, true, true, true],
          borderColor: ['#ff00ff', '#00ffff', '#ff00ff', '#00ffff'],
          fillColor: '#F7F7F7'
        },
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [5, 5, 15, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black'
        }
      }
    };
    const docDefinition = JSON.parse(JSON.stringify(documentDefinition));
    docDefinition.footer = documentDefinition.footer;
    docDefinition.header = documentDefinition.header;
    const content = [this.propertyDetails(downloadData.propertyDetails), this.cardSpacing()];
    docDefinition.content = content;
    if (['open', 'in-progress', 'paused'].includes(downloadData.status)) {
      docDefinition.watermark = {
        text: 'DRAFT',
        opacity: 0.1,
        fontSize: 175,
        angle: 325
      };
    }
    return docDefinition;
  }

  propertyDetails(data) {
    const providerName = data.bookingInfo.providerName ? data.bookingInfo.providerName : ``;
    const propertyName = data.bookingInfo.propertyName ? data.bookingInfo.propertyName : ``;
    const apartmentType = data.bookingInfo.apartmentType ? data.bookingInfo.apartmentType : ``;
    const rent = data.bookingInfo.rentPaidBy ? data.bookingInfo.rentPaidBy : ``;
    const country = data.bookingInfo.address && data.bookingInfo.address.country ? data.bookingInfo.address.country : ``;
    const address = data.bookingInfo.address && data.bookingInfo.address.streetLine1 ? data.bookingInfo.address.streetLine1 : ``;
    const city = data.bookingInfo.address && data.bookingInfo.address.city ? data.bookingInfo.address.city : ``;
    const state = data.bookingInfo.address && data.bookingInfo.address.state ? data.bookingInfo.address.state : ``;
    const postalCode = data.bookingInfo.address && data.bookingInfo.address.postalCode ? data.bookingInfo.address.postalCode : ``;

    const details = {
      table: {
        headerRows: 0,
        widths: ['40%', '30%', '30%'],
        style: 'programTable',
        body: [
          //header
          [
            {
              text: `${this.translate.instant('bb.contacts.propertyDetails')}`,
              border: [true, true, false, false],
              borderColor: [this.borderColor, this.borderColor, '', ''],
              fontSize: this.sectionHeaderFont,
              color: '#000000',
              style: 'avenirMedium',
              lineHeight: this.lineHeight
            },
            {
              text: '',
              border: [false, true, false, false],
              borderColor: ['', this.borderColor, '', ''],
              fontSize: this.sectionHeaderFont,
              color: '#000000',
              style: 'avenirMedium',
              lineHeight: this.lineHeight
            },
            {
              text: '',
              border: [false, true, true, false],
              borderColor: ['', this.borderColor, this.borderColor, ''],
              fontSize: this.sectionHeaderFont,
              color: '#000000',
              style: 'avenirMedium',
              lineHeight: this.lineHeight
            }
          ],
          //row 1
          [
            {
              text: `${this.translate.instant('bb.contacts.providerName')}`,
              border: [true, false, false, false],
              borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            },
            {
              text: `${this.translate.instant('bb.contacts.propertyName')}`,
              border: [false, false, false, false],
              borderColor: ['', '', '', ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            },
            {
              text: '',
              border: [false, false, true, false],
              borderColor: ['', '', this.borderColor, ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            }
          ],
          [
            {
              text: `${providerName}`,
              border: [true, false, false, false],
              borderColor: [this.borderColor, '', '', ''],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            },
            {
              text: `${propertyName}`,
              border: [false, false, false, false],
              borderColor: ['', '', '', ''],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            },
            {
              text: '',
              border: [false, false, true, false],
              borderColor: ['', '', this.borderColor, ''],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            }
          ],
          //row 2
          [
            {
              text: `${this.translate.instant('bb.contacts.apartmentType')}`,
              border: [true, false, false, false],
              borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            },
            {
              text: `${this.translate.instant('bb.contacts.rentPaidBy')}`,
              border: [false, false, false, false],
              borderColor: ['', '', '', ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            },
            {
              text: '',
              border: [false, false, true, false],
              borderColor: ['', '', this.borderColor, ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            }
          ],
          [
            {
              text: `${apartmentType}`,
              border: [true, false, false, false],
              borderColor: [this.borderColor, '', '', ''],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            },
            {
              text: `${rent}`,
              border: [false, false, false, false],
              borderColor: ['', '', '', ''],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            },
            {
              text: '',
              border: [false, false, true, false],
              borderColor: ['', '', this.borderColor, ''],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            }
          ],
          //row 3
          [
            {
              text: `${this.translate.instant('bb.contacts.country')}`,
              border: [true, false, false, false],
              borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            },
            {
              text: '',
              border: [false, false, false, false],
              borderColor: ['', '', '', ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            },
            {
              text: '',
              border: [false, false, true, false],
              borderColor: ['', '', this.borderColor, ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            }
          ],
          [
            {
              text: `${country}`,
              border: [true, false, false, false],
              borderColor: [this.borderColor, '', '', ''],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            },
            {
              text: ``,
              border: [false, false, false, false],
              borderColor: ['', '', '', ''],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            },
            {
              text: '',
              border: [false, false, true, false],
              borderColor: ['', '', this.borderColor, ''],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            }
          ],
          //row 4
          [
            {
              text: `${this.translate.instant('bb.contacts.address')}`,
              border: [true, false, false, false],
              borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            },
            {
              text: '',
              border: [false, false, false, false],
              borderColor: ['', '', '', ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            },
            {
              text: '',
              border: [false, false, true, false],
              borderColor: ['', '', this.borderColor, ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            }
          ],
          [
            {
              text: `${address}`,
              border: [true, false, false, false],
              borderColor: [this.borderColor, '', '', ''],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            },
            {
              text: ``,
              border: [false, false, false, false],
              borderColor: ['', '', '', ''],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            },
            {
              text: ``,
              border: [false, false, true, false],
              borderColor: ['', '', this.borderColor, ''],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            }
          ],
          //row 5
          [
            {
              text: `${this.translate.instant('bb.contacts.city')}`,
              border: [true, false, false, false],
              borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            },
            {
              text: `${this.translate.instant('bb.contacts.state')}`,
              border: [false, false, false, false],
              borderColor: ['', '', '', ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            },
            {
              text: `${this.translate.instant('bb.contacts.postalCode')}`,
              border: [false, false, true, false],
              borderColor: ['', '', this.borderColor, ''],
              color: this.labelColor,
              fontSize: this.labelFont,
              style: 'avenirLight',
              lineHeight: this.labelLineHeight
            }
          ],
          [
            {
              text: `${city}`,
              border: [true, false, false, true],
              borderColor: [this.borderColor, '', '', this.borderColor],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            },
            {
              text: `${state}`,
              border: [false, false, false, true],
              borderColor: ['', '', '', this.borderColor],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            },
            {
              text: `${postalCode}`,
              border: [false, false, true, true],
              borderColor: ['', '', this.borderColor, this.borderColor],
              color: this.fieldValueColor,
              fontSize: this.fieldValueFont,
              style: 'avenirLight',
              lineHeight: this.lineHeight
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function(i, node) {
          return 0.75;
        },
        vLineWidth: function(i, node) {
          return 0.75;
        }
      }
    };
    return details;
  }
  cardSpacing() {
    const space = {
      table: {
        body: [
          [
            {
              text: '',
              margin: [0, 2, 0, 2],
              border: [false, false, false, false]
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function(i, node) {
          return 0.75;
        },
        vLineWidth: function(i, node) {
          return 0.75;
        }
      }
    };
    return space;
  }
}
