<!-- Transferee info tab- To be displayed when total number of relocating members is more than 1 -->
<mat-card
  appearance="outlined"
  class="family-card"
  fxFlex="100%"
  fxLayout.sm="column"
  fxLayout.xs="column"
  *ngIf="familyMembersRelocating > 1"
>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
    <card-title name="My Family" type="family" imageUri="family"></card-title>
    <p
      *ngIf="familyMembersRelocating"
      class="no-people-relocating"
      fxFlex="60%"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxHide.sm="true"
      fxHide.xs="true"
    >
      <span>{{ 'bb.familyCard.totalPeopleRelocating' | translate }}</span>
      <span class="no-relocating"> {{ familyMembersRelocating }}</span>
    </p>
  </mat-card-title>
  <mat-card-content class="family-card-padding">
    <p
      *ngIf="familyMembersRelocating"
      class="no-people-relocating"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxHide="true"
      fxHide.sm="false"
      fxHide.xs="false"
    >
      <span>{{ 'bb.familyCard.totalPeopleRelocating' | translate }}</span>
      <span class="no-relocating">{{ familyMembersRelocating }}</span>
    </p>
    <!--- To be displayed when family details are updated-->
    <div *ngIf="familyInformation && familyInformation.length >= 1" class="family-details">
      <p>
        <span> {{ 'bb.familyCard.you' | translate }}: </span>
        <span>
          {{ username }}
        </span>
      </p>
      <p *ngFor="let familyData of familyInformation | slice: 0:3">
        <span>
          {{
            browserLanguage !== defaultLanguage ? getTranslated(familyData.relationshipType, relationshipKey) : familyData.relationshipType
          }}:
        </span>
        <span>
          {{ familyData.nameDetails.firstName }}
          {{ familyData.nameDetails.lastName }}
        </span>
      </p>
    </div>
    <!--- To be displayed when family details are updated-->

    <!--- To be displayed when family details are not updated-->
    <div class="text-wrapper" *ngIf="!familyInformation || familyInformation.length == 0">
      <p>
        <span>{{ 'bb.familyCard.hi' | translate }}</span>
        <span> {{ this.username }}</span>
      </p>
      <p>{{ 'bb.familyCard.updateFamilyInfo' | translate }}</p>
    </div>
    <!--- To be displayed when family details are not updated-->
    <p class="more" fxLayout [fxLayoutAlign]="familyMembersRelocating && !isFamilyUpdated ? 'end center' : 'space-between center'">
      <button
        class="more-btn"
        *ngIf="familyInformation && familyInformation.length > 3"
        (click)="openDialog(familyInformation, familyMembersRelocating, username)"
        aria-label="complete family details"
      >
        {{ 'bb.familyCard.more' | translate }}..
      </button>
      <span fxLayoutAlign="end center" fxLayoutGap="10px">
        <img
          *ngIf="familyMembersRelocating && !isFamilyUpdated"
          src="../../../../../assets/images/caution-sign.svg"
          alt="caution sign"
          title="Caution sign"
        />
        <button
          matButton
          *ngIf="familyMembersRelocating && isFamilyUpdated"
          (click)="updateFamilyMembers()"
          class="edit-btn"
          aria-label="edit family details"
        >
          {{ 'bb.familyCard.edit' | translate }}
        </button>
        <button
          matButton
          *ngIf="familyMembersRelocating && !isFamilyUpdated"
          (click)="updateFamilyMembers()"
          class="update-btn"
          aria-label="update family details"
        >
          {{ 'bb.updateBtn' | translate }}
        </button>
      </span>
    </p>
  </mat-card-content>
</mat-card>

<!-- Transferee info tab- To be displayed when total number of relocating members is 1 -->
<mat-card
  appearance="outlined"
  fxFlex="46%"
  fxFlex.sm="100%"
  fxFlex.xs="100%"
  fxFlex.md="100%"
  class="transferee-card"
  fxLayout="column"
  *ngIf="familyMembersRelocating === 1"
>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <card-title name="Transferee Info" imageUri="family" type="family"></card-title>
    </div>
  </mat-card-title>
  <mat-card-content
    fxFlex="100%"
    fxLayoutAlign="center center"
    fxLayout="column"
    fxLayoutAlign.md="flex-start flex-start"
    fxLayoutAlign.xs="flex-start flex-start"
    fxLayoutAlign.sm="flex-start flex-start"
  >
    <p class="no-people-relocating" fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{ 'bb.familyCard.totalPeopleRelocating' | translate }}</span>
      <span class="no-relocating"> {{ familyMembersRelocating }} </span>
    </p>
    <a
      tabindex="0"
      href="javascript:void(0)"
      class="add-members"
      (click)="updateFamilyMembers()"
      (keypress)="updateFamilyMembers()"
      fxFlex="100%"
      >{{ 'bb.familyCard.addFamily' | translate }}</a
    >
  </mat-card-content>
  <mat-card-actions
    fxFlex="100%"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayout.sm="row"
    fxLayout.md="row"
    fxLayoutAlign.md="end center"
    fxLayoutAlign.sm="end center"
    fxLayout.xs="row"
    fxLayoutAlign.xs="end center"
  >
    <!-- <button matButton fxFlex="100%">Personal Info</button> -->
    <button matButton (click)="updateProfileInfo()" role="button" aria-label="Update Personal Information">
      {{ 'bb.familyCard.personalInfo' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
