import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { EMPTY, Subject, Observable, timer } from 'rxjs';
import { catchError, map, retryWhen, tap, delayWhen } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { CookieService } from 'ngx-cookie-service';
import { CollabMessage, CollabEvents } from './collaboration-events.service';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';

@Injectable({
  providedIn: 'root'
})
export class CollaborationService {
  /**
   * ----------- Notes --------------
   * > Socket is connected with the orderid of the loaded transferee sent as a { query string }
   * > A new message needs to be sent everytime no matter the socket connection state.
   * 	 The function will decide on its own if it needs to send or not.
   * > Message contains event and data (which is strigified)... and auto parsed on new message received
   * > Always sendMessage to be used to send socket message
   *
   * Socket will reconnect automatically if the connection breaks.
   * But will only reconnect if the rejection or closing connection code is not 4000
   * 4000 code is received only in a particular scenario described below :-
   * Since a room can contain only two participants.
   * Server will automatically reject connections trying to connect to the same room.
   */

  public socket$: WebSocketSubject<any>;
  socketSubscriber$ = new Subject();

  /** Auto Reconnect Interval */
  readonly RECONNECT_INTERVAL = 3000;

  constructor(
    protected config: AppConfigService,
    private cookieService: CookieService,
    private readonly needsAssessmentShared: NeedsAssessmentSharedService
  ) {
    // this.connect();
  }

  public connect(): void {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.getNewWebSocket();
      this.socket$
        .pipe(
          this.reconnect,
          map((message: CollabMessage) => {
            // Extra check to ignore message received by self.
            const isConsultant = this.cookieService.get('transferee-context');
            try {
              if (isConsultant && message.from === 'consultant') {
                message = '' as any;
              }
              if (!isConsultant && message.from === 'transferee') {
                message = '' as any;
              }
              if (message) {
                message.data = JSON.parse(message.data as any);
              }
              console.log('Received Message ', message);
              return message;
            } catch (error) {
              console.log('Received Message ', message);
              return message;
            }
          }),
          catchError(_ => EMPTY)
        )
        .subscribe(
          data => this.socketSubscriber$.next(data),
          err => this.socketSubscriber$.error('[Live component] Error:' + err),
          () => {
            this.socketSubscriber$.complete();
            console.log('[Live component] Connection Closed');
          }
        );
    }
  }

  /**
   * Retry a given observable by a time span
   * @param o the observable to be retried
   */
  public reconnect(o: Observable<any>): Observable<any> {
    return o.pipe(
      retryWhen(errors =>
        errors.pipe(
          tap(val => console.log('[Data Service] Trying to reconnect', val)),
          delayWhen(_ => timer(this.RECONNECT_INTERVAL))
        )
      )
    );
  }

  public getNewWebSocket() {
    const apiUrl: Record<string, string> = this.config.getConfig('api');
    const orderId = sessionStorage.getItem('car-ses-oid');
    const userType = this.cookieService.get('transferee-context') ? 'consultant' : 'transferee';
    return webSocket({
      url: `${apiUrl.protocol === 'https' ? 'wss' : 'ws'}://${apiUrl.host}/v1/ws/echo?oid=${orderId}&usertype=${userType}`,
      openObserver: {
        next: () => {
          console.log('[DataService]: connection ok');
        }
      },
      closeObserver: {
        next: event => {
          console.log('[DataService]: connection closed', event.code);
          this.socket$ = undefined;
          // Reconnect only if the connection is not being closed by server with 4000...
          if (event.code !== 4000) {
            this.connect();
          }
        }
      }
    });
    // return webSocket(`${this.config.rootUrl}:8081`);
  }

  sendMessage(msg: { event: CollabEvents; data: any }) {
    if (!this.socket$ || this.socket$.closed) {
      return;
    }

    const isConsultant = this.cookieService.get('transferee-context');
    let orderId = '';
    this.needsAssessmentShared.moveDetails.subscribe(res => {
      if (res) {
        orderId = res.orderRequestDetails[0].orderRequestId;
      }
    }); //sessionStorage.getItem('car-ses-oid');

    const message = {
      data: JSON.stringify(msg),
      roomId: orderId,
      from: isConsultant ? 'consultant' : 'transferee'
    };

    this.socket$.next(message);
  }

  /**
   * Returns the collab user Type
   */
  userType(): 'consultant' | 'transferee' {
    const isConsultant = this.cookieService.get('transferee-context');
    return isConsultant ? 'consultant' : 'transferee';
  }

  close() {
    if (this.socket$ && !this.socket$.closed) {
      this.socket$.complete();
    }
  }
}
