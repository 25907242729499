import { Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TransfereeNeedsAssessment } from 'src/app/core/models/candidateneeds-assessment.model';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';
import { NeedsAssessmentSharedService } from '../needs-assessment/services/needs-assessment-shared.service';
import { Router } from '@angular/router';
import { DashboardService } from '../dashboard/services/dashboard.service.service';
import { BenefitService } from '../benefits/services/benefit.service';
import * as moment from 'moment';
import { UntypedFormControl } from '@angular/forms';
import { BankInfoService } from './services/bank-info.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lumpsum-dashboard',
  templateUrl: './lumpsum-dashboard.component.html',
  styleUrls: ['./lumpsum-dashboard.component.scss']
})
export class LumpsumDashboardComponent implements OnInit, OnDestroy {
  /** to store velues retrieved from needs assessment */
  needsAssessment: TransfereeNeedsAssessment;
  orderRequestId: string = null;
  browserLanguage: string;
  jobStartDate = '';
  accountNumberToShow = '';
  primaryBankDetails: any;
  familyDetails = [
    {
      relation: 'You',
      name: 'First Last Name'
    },
    {
      relation: 'spouse',
      name: 'First Last Name'
    },
    {
      relation: 'child',
      name: 'First Last Name'
    },
    {
      relation: 'child',
      name: 'First Last Name'
    },
    {
      relation: 'child',
      name: 'First Last Name'
    }
  ];

  documentCapability: any = 'transferee';
  estimatedArrivalDate = null;
  lumpsumDetails: any;
  isCoBrowsing = false;
  lumpSumSubscription: Subscription;

  constructor(
    private readonly needsAssessmentShared: NeedsAssessmentSharedService,
    private readonly cookieService: CookieService,
    private readonly languageTranslationService: LanguageTranslationService,
    private readonly route: Router,
    private dashboardService: DashboardService,
    private readonly benefitService: BenefitService,
    private readonly bankInfoService: BankInfoService,
    private readonly toastr: MatSnackBar
  ) {
    this.browserLanguage = this.languageTranslationService.getSupportedLanguage();
  }

  ngOnInit(): void {
    this.loadOrderRequestId();
    this.getMoveOrderDetails();
    this.checkCapability();
    this.isCoBrowsing = this.cookieService.get('transferee-context') ? true : false;
    this.orderRequestId = sessionStorage.getItem('car-ses-oid');
    this.lumpSumSubscription = this.needsAssessmentShared.transfereeRepaymentLumpSumDetails.subscribe(data => {
      if (data) {
        this.lumpsumDetails = data;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.lumpSumSubscription) {
      this.lumpSumSubscription.unsubscribe();
    }
  }
  loadOrderRequestId() {
    this.needsAssessmentShared.transfereeNeedsAssessmentDetails.subscribe(data => {
      if (!data) {
        return;
      }
      this.needsAssessment = data;
      this.orderRequestId = data.orderRequestId;
      this.setRelocationDatesData();
      if (this.orderRequestId) {
        this.syncLumpsumLegacyBankInfo(this.orderRequestId);
      }
    });
  }

  getMoveOrderDetails() {
    this.needsAssessmentShared.moveDetails.subscribe(data => {
      if (!data) {
        return;
      }
      this.needsAssessment = data;
    });
  }

  checkCapability() {
    if (sessionStorage.getItem('capabilities')) {
      const index = JSON.parse(sessionStorage.getItem('capabilities')).findIndex(
        ele => ele.name == 'BB - Transferee Document Library' && ele.permission == 'allow'
      );
      if (index > -1) {
        this.documentCapability = JSON.parse(sessionStorage.getItem('capabilities'))[index].operation;
      } else {
        this.documentCapability = 'none';
      }
    }
  }

  setRelocationDatesData() {
    if (this.needsAssessment.estimatedArrivalDate) {
      this.estimatedArrivalDate = this.needsAssessment.estimatedArrivalDate;
    } else {
      this.estimatedArrivalDate = null;
    }
    if (this.needsAssessment.jobStartDate) {
      this.jobStartDate = this.needsAssessment.jobStartDate;
    } else {
      this.jobStartDate = '';
    }
  }

  syncLumpsumLegacyBankInfo(orderRequestId) {
    this.bankInfoService.syncLumpsumLegacyBankInfo(orderRequestId).subscribe(res => {
      if (!res) {
        console.log('Error, while syncing legacy bank info', res);
        return;
      }
    });
  }
}
