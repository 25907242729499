import { Component, OnInit, ViewChild, OnDestroy, HostListener, NgZone, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { HeaderComponent } from './core/components/header/header.component';
import { NeedsAssessmentSharedService } from './public/needs-assessment/services/needs-assessment-shared.service';
import { TransfereeNeedsAssessment } from './core/models/candidateneeds-assessment.model';
import { IdleTimeoutService } from './core/services/idle-timeout.service';
import { CollaborationService } from './core/services/collaboration.service';
import { CollabMessage, CollabEvents } from './core/services/collaboration-events.service';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { ToastIdleTimeoutComponent } from './core/components/toast-idle-timeout/toast-idle-timeout.component';
import { Location } from '@angular/common';
import { AuthenticationService } from './core/services/authentication.service';
import { PersonalInfoService } from './core/services/personal-info.service';
import { BenefitService } from './public/benefits/services/benefit.service';
import { LanguageTranslationService } from './core/services/language-translation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CoBrowsingTimerComponent } from './core/components/co-browsing-timer/co-browsing-timer.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  @ViewChild(HeaderComponent, { static: false }) header: HeaderComponent;

  // Mouse Coordinates
  lastY: any;
  lastX: any;

  /** To enable background image */
  showNeedsAssessmentBG: boolean;
  leftNavigation: boolean;
  initials = '';
  username = '';

  /** For footer to hide and show */
  isBenefitsActive: boolean;

  /** Flag to display notification bar for pending tasks */
  displayTaskNotification = false;
  /** Flag to indicate if needs assessment is completed */
  needsAssessmentCompleted: boolean;

  orderRequestId: string = null;
  /** Flag to notify header component to display notification section */
  openNotificationMenu: boolean;
  /** To store the list of notifications */
  notificationList: { header; reference; message }[] = [];

  /** Collaboration Subscription */
  collabSubscription: Subscription;

  /** Interval for token refresh */
  refreshTokenInterval: any;
  browserLanguage: string;

  /** duration (in minutes) to call for token refresh */
  // refreshTokenTimeout = 9; // 3 chances to update in a 30 minnute window
  refreshTokenTimeout = 9; // 3 chances to update in a 30 minnute window

  previousUrl: string = null; //GTM implementation
  currentUrl: string = null; //GTM implementation

  constructor(
    public dialog: MatDialog,
    private benefitService: BenefitService,
    private readonly router: Router,
    private readonly needsAssessmentShared: NeedsAssessmentSharedService,
    private readonly personalInfoService: PersonalInfoService,
    private readonly idleTimeoutService: IdleTimeoutService,
    private readonly collaborationService: CollaborationService,
    private readonly cookieService: CookieService,
    private _snackBar: MatSnackBar,
    private readonly location: Location,
    private cdr: ChangeDetectorRef,
    private _ngZone: NgZone,
    private readonly authsrvc: AuthenticationService,
    private readonly spinner: NgxSpinnerService,
    languageTranslationService: LanguageTranslationService
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('needs-assessment')) {
          this.showNeedsAssessmentBG = true;
          this.needsAssessmentCompleted = false;
        } else {
          this.showNeedsAssessmentBG = false;
          this.needsAssessmentCompleted = true;
        }
        if (event.url.includes('benefits')) {
          this.isBenefitsActive = true;
        } else {
          this.isBenefitsActive = false;
        }
      }
    });
    if (location.path().includes('benefits')) {
      this.isBenefitsActive = true;
    } else {
      this.isBenefitsActive = false;
    }
    this.browserLanguage = languageTranslationService.getSupportedLanguage();
  }

  /** Component Angular initialization lifecycle hook */
  ngOnInit() {
    this.benefitService.getCategoryDisplayNames(this.browserLanguage);
    this.deleteDocumentLoader();
    this.loadNeedsAssessment();
    this.refreshsessionInApp();
    this.collaborationService.connect();
    this.observeCollab();
    this.idleTimeoutService.timeoutData$.pipe().subscribe(response => {
      if (response) {
        this._ngZone.run(() => {
          this._snackBar.openFromComponent(ToastIdleTimeoutComponent, {
            // duration:120000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          });
        });
      } else {
        if (this.idleTimeoutService.isIdleTimeoutWarning) {
          this._snackBar.dismiss();
        }
      }
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const pageTitle = event.urlAfterRedirects.replace(/^\/|\/$/g, ''); //remove slashes from the page title
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        // Google Analytics
        // if ((<any>window).ga) {
        //   (<any>window).ga('set', 'page', window.location.pathname + '#' + event.urlAfterRedirects);
        //   (<any>window).ga('send', 'pageview');
        // }
        //Google Tag Manager

        if ((window as any).dataLayer) {
          (window as any).dataLayer.push({
            event: 'pageview',
            page: {
              path: window.location.pathname + '#' + event.urlAfterRedirects
            }
          });
        }
        // GTM Implementation for page navigation
        (window as any).dataLayer.push({
          event: 'virtualPageview',
          pageUrl: window.location.href,
          pageTitle: pageTitle,
          pageReferrer: this.previousUrl
        });
      }
    });
  }
  /** function to hold interval for token refresh and storing of cookies on response */
  public refreshsessionInApp() {
    this.refreshTokenInterval = setInterval(() => {
      this.refreshToken();
    }, 60000 * this.refreshTokenTimeout);
  }

  refreshToken() {
    this.authsrvc
      .refreshSession()
      .then((freshToken: any) => {
        if (freshToken) {
          this.cookieService.set(
            'car-ses-tok',
            freshToken.tokens.accessToken.accessToken,
            null, // We are relying on Okta session expiration
            '/',
            '.cartus.com',
            true
          );
        }
      })
      .catch(err => {
        console.log('error in freshToken :', err);
      });
  }

  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.sendLogoutMessage();
  }

  /** Listen for mouse events */
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    if (e.pageX !== this.lastX || e.pageY !== this.lastY) {
      this.lastX = e.pageX;
      this.lastY = e.pageY;
      this.idleTimeoutService.refreshIdleCookie();
    }
  }

  /** Listen for keypress events */
  @HostListener('document:keypress', ['$event'])
  onKeyPress() {
    this.idleTimeoutService.refreshIdleCookie();
  }

  observeCollab() {
    this.collabSubscription = this.collaborationService.socketSubscriber$.subscribe((message: CollabMessage) => {
      if (!message) {
        return;
      }

      if (
        message.data &&
        message.data.event &&
        (message.data.event === CollabEvents.USER_JOINED || message.data.event === CollabEvents.USER_LOGGED_OUT)
      ) {
        setTimeout(() => {
          const userType = this.cookieService.get('transferee-context') ? 'Consultant' : 'Transferee';
          if (message.data.event === CollabEvents.USER_JOINED && userType.toLowerCase() !== JSON.parse(message.data.data)) {
            console.log('User online', message.data.data);
            document.getElementById('collab-session').style.display = 'block';
            document.getElementById('collab-text').textContent = `${userType === 'Consultant' ? 'Transferee' : 'Consultant'} is online`;
            const needAssessmentDetails = this.needsAssessmentShared.transfereeNeedsAssessmentDetails.getValue();
            if (userType === 'Consultant' && !needAssessmentDetails.confirmStatus) {
              //terminate consultant in cobrowisng session only
              const loc = window.location.href.split('#')[0];
              location.assign(loc + 'assets/cartus-session-terminated.html');
            } else if (this.router.url.includes('dashboard')) {
              this.spinner.show();
              setTimeout(async () => {
                this.spinner.show();
                this.needsAssessmentShared.transfereeNeedsAssessmentDetails.next(null);
                await this.needsAssessmentShared.getCandidateNeedsAssessmentDetails();
              }, 20000);

              const dialogRef = this.dialog.open(CoBrowsingTimerComponent, {
                panelClass: 'dialogMainContainer',
                autoFocus: false,
                disableClose: true,
                width: '30%',
                height: '25%',
                data: { userType: userType, isDashBoard: true }
              });
              this.spinner.hide();
            } else if (!this.router.url.includes('dashboard') && !this.router.url.includes('needs-assessment')) {
              const dialogRef = this.dialog.open(CoBrowsingTimerComponent, {
                panelClass: 'dialogMainContainer',
                autoFocus: false,
                disableClose: true,
                width: '30%',
                height: '25%',
                data: { userType: userType, isDashBoard: false }
              });
              dialogRef.afterClosed().subscribe(data => {
                if (data) {
                  this.router.navigate(['/dashboard']);
                }
              });
            }
          }
          if (message.data.event === CollabEvents.USER_LOGGED_OUT && userType.toLowerCase() !== JSON.parse(message.data.data)) {
            console.log('User offline', message.data.data);
            document.getElementById('collab-session').style.display = 'none';
          }
        }, 5000);
      }
    });
  }

  deleteDocumentLoader() {
    window.document.getElementById('app_loading').remove();
  }

  sideNavHandle() {
    this.initials = this.header.initials;
    this.username = this.header.username;
    this.sidenav.open();
  }

  logout() {
    this.header.logout();
  }

  isLinkActive(url): boolean {
    return this.router.url.includes(url);
  }

  navigateTo(url) {
    this.router.navigate([url]);
    this.sidenav.close();
  }

  /**
   * To open notification section and display them
   * @param notificationList List of notifications
   */
  openNotification(notificationList) {
    this.notificationList = notificationList;
    this.openNotificationMenu = true;
  }
  /**
   * To close notification section
   */
  notificationClosed() {
    this.openNotificationMenu = false;
  }

  /**
   * To load needs assessment from sessionStorage and update to shared service
   */
  loadNeedsAssessment() {
    const needsAssessmentData: TransfereeNeedsAssessment = JSON.parse(sessionStorage.getItem('needs-assessment'));
    sessionStorage.removeItem('needs-assessment');
    if (needsAssessmentData) {
      this.needsAssessmentShared.updateCandidateNeedsAssesment(needsAssessmentData);
      this.orderRequestId = needsAssessmentData.orderRequestId;
      sessionStorage.setItem('car-ses-oid', this.orderRequestId);
      sessionStorage.setItem('car-ses-con', needsAssessmentData.candidateId);
      if (!needsAssessmentData.confirmStatus && !this.cookieService.get('transferee-context')) {
        this.router.navigate(['/needs-assessment']);
      } else {
        this.router.navigate(['/dashboard']);
      }
    } else {
      this.router.navigate(['/logout']);
    }
  }

  updateNotification(value) {
    if (value && value.length > 0) {
      this.displayTaskNotification = true;
      this.notificationList = value;
    } else {
      this.displayTaskNotification = false;
      this.notificationList = [];
    }
    this.notificationList = this.notificationList ? [].concat(this.notificationList) : [];
  }

  /**
   * Intentionally deleting everything.
   * User will login each time
   */
  sendLogoutMessage() {
    this.collaborationService.sendMessage({
      event: CollabEvents.USER_LOGGED_OUT,
      data: null
    });

    this.collaborationService.close();

    /* this.cookieService.delete('transferee-context', null);
		this.cookieService.delete('transferee-context', null, '.cartus.com'); */
  }

  ngOnDestroy(): void {
    if (this.collabSubscription) {
      this.collabSubscription.unsubscribe();
    }
  }

  /**
   * To redirect user back to benefits screen
   */
  goToBenefits() {
    this.router.navigate(['/benefits/list']);
    this.sidenav.close();
  }

  /**
   * To redirect user to confirmed benefits screen
   */
  goToConfirmedBenefits() {
    this.router.navigate(['/benefits/confirmed']);
    this.sidenav.close();
  }

  /**
   * To redirect user to review selected benefits screen
   */
  goToReviewSelectedBenefits() {
    this.router.navigate(['/benefits/review']);
    this.sidenav.close();
  }
}
