<mat-card
  appearance="outlined"
  class="card-body"
  fxFlex="100%"
  tabindex="0"
  (click)="loadUrl()"
  (keypress)="loadUrl()"
  (keyup.enter)="loadUrl()"
>
  <mat-card-title style="font-size: 32px;" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <card-title [name]="liveEasyCardTitle" type="liveEasy" imageUri="book"></card-title>
    </div>
    <img src="../../../../../assets/images/cartus-online-icon.svg" alt="cartus-online" />
  </mat-card-title>
  <mat-card-content class="card-content" fxLayout="row" fxLayoutGap="12px">
    <div fxLayout="column" fxFlex="50%">
      <img src="../../../../../assets/images/LiveEasy-4C.svg" alt="liveEasy" class="img-size" />
    </div>
    <div fxLayout="column" fxFlex="50%" class="content">
      {{ 'bb.liveEasy.content' | translate }}
    </div>
  </mat-card-content>
</mat-card>
