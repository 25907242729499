<mat-card appearance="outlined" fxFlex="100%" class="task-card" fxLayout="column" *ngIf="taskObj">
  <mat-card-title
    fxLayout="row"
    fxLayoutAlign="space-evenly center"
    fxLayoutAlign.sm="space-between center"
    fxLayoutAlign.xs="space-between center"
  >
    <mat-icon *ngIf="taskObj.tickIcon" fxFlex="6%" class="outline-tick">check_circle_outline</mat-icon>

    <div fxFlex="69%" fxFlex.sm="88%" fxFlex.xs="88%" fxLayout="row" fxLayoutAlign="start center" role="none">
      <img src="../../../../../assets/images/cartusOnline-color .svg" alt="cartus online" aria-labelledby="cartusOnlineText" role="img" />
    </div>

    <!-- <button class="cartus-online-icon" aria-label="Cartus online"></button> -->
  </mat-card-title>
  <mat-card-content fxFlex="61%" fxLayoutAlign="center start">
    <p>
      <span role="note" style="font-size:14px;" id="cartusOnlineText">
        {{ 'bb.cartusOnline' | translate }}
      </span>
    </p>
  </mat-card-content>
  <mat-card-actions
    fxFlex="100%"
    fxLayout="column"
    [fxLayoutAlign]="taskObj.warning ? 'center center' : 'space-evenly center'"
    fxLayout.sm="row"
    fxLayoutAlign.sm="end center"
    fxLayout.xs="row"
    fxLayoutAlign.xs="end center"
  >
    <button
      style="width: fit-content;"
      role="button"
      [ngClass]="hasCustFileId == true ? 'goEnabled' : 'goDisabled'"
      [disabled]="!hasCustFileId || isConsultant"
      attr.aria-label="task"
      (click)="openCartusOnline()"
      (keypress)="openCartusOnline()"
      aria-label="Go"
    >
      {{ 'bb.goBtn' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
