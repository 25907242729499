import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lumpsum-request-completion',
  templateUrl: './lumpsum-request-completion.component.html',
  styleUrls: ['./lumpsum-request-completion.component.scss']
})
export class LumpsumRequestCompletionComponent implements OnInit {
  constructor(public lumpsumRegCompleteDialogRef: MatDialogRef<LumpsumRequestCompletionComponent>) {}

  ngOnInit(): void {}

  closeCompletionDialog() {
    this.lumpsumRegCompleteDialogRef.close();
  }
}
