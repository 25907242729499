<mat-card appearance="outlined" class="family-card" fxFlex="100%" tabindex="0">
  <div fxLayout="row">
    <div fxLayout="column" fxFlex="55%" fxLayoutAlign="start">
      <card-title type="bankInfo" imageUri="credit-card"></card-title>
    </div>

    <p class="no-people-relocating" fxLayout="row" fxLayoutAlign="space-between" fxFlex="45%" *ngIf="bankInfo.length > 0">
      <span>{{ 'Total Bank Accounts:' | translate }}</span>
      <span class="no-relocating">{{ bankInfo.length }}</span>
    </p>

    <img
      *ngIf="!isCoBrowsing"
      style="margin-left: auto;"
      (click)="goToBankInfo()"
      src="../../../../../assets/images/cartus-online-icon.svg"
      alt="cartus-online"
    />
  </div>

  <mat-card-content class="card-content">
    <div class="bankInfo-message" *ngIf="bankInfo.length == 0">
      Please add or review your Bank Account details to expedite your Lump Sum payout.
    </div>

    <table class="family-details" *ngIf="bankInfo.length > 0">
      <tr>
        <td>{{ 'Account Holder Name' | translate }} :</td>
        <td>
          <strong>{{ primaryBankDetails?.accountHolder }}</strong>
        </td>
      </tr>
      <tr>
        <td>{{ 'Account Number' | translate }} :</td>
        <td>
          <strong> {{ accountNumberToShow }} </strong>
        </td>
        <td *ngIf="!isCoBrowsing">
          <mat-icon
            id="hideUnhidebtn"
            [ngStyle]="{ color: 'blue' }"
            (mousedown)="mousedown($event)"
            (mouseup)="mouseLeaveEvent(event)"
            (mouseleave)="mouseLeaveEvent($event)"
            >remove_red_eye</mat-icon
          >
        </td>
      </tr>
      <tr>
        <td>{{ 'Bank Name' | translate }} :</td>
        <td>
          <strong> {{ primaryBankDetails?.bankName }} </strong>
        </td>
      </tr>
    </table>

    <div fxLayout="row" *ngIf="bankInfo.length > 1 && !isCoBrowsing">
      <p class="more" fxLayout fxLayoutAlign="space-between center">
        <span fxLayoutAlign="end center" fxLayoutGap="10px">
          <button matButton (click)="goToBankInfo()" class="edit-btn" aria-label="edit family details">
            {{ 'View More' | translate }}
          </button>
        </span>
      </p>
    </div>
  </mat-card-content>
</mat-card>
