import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LumpsumDashboardComponent } from './lumpsum-dashboard.component';
import { RepaymentAgreementComponent } from './components/repayment-agreement/repayment-agreement.component';
import { MyTasksLumpsumComponent } from './components/my-tasks-lumpsum/my-tasks-lumpsum.component';
import { BankInfoComponent } from './components/bank-info/bank-info.component';

const routes: Routes = [
  { path: '', component: LumpsumDashboardComponent },
  {
    path: 'mytasks-lumpsum',
    component: MyTasksLumpsumComponent,
    children: [
      {
        path: 'repaymentAgreement',
        component: RepaymentAgreementComponent
      },
      { path: 'bankInfo', component: BankInfoComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LumpSumDashboardRoutingModule {}
