<div class="welcome-comp" fxLayoutAlign="space-evenly start" fxLayout="row">
  <div
    class="welcome-content"
    fxLayout="column"
    fxFlex="70%"
    fxFlex.md="70%"
    fxLayoutGap="36px"
    fxLayoutGap.md="24px"
    fxFlex.sm="100%"
    fxLayoutGap.sm="0px"
    fxFlex.xs="100%"
    fxLayoutGap.xs="0px"
  >
    <div
      fxLayout="row"
      fxFlex="80%"
      fxLayoutAlign="flex-start"
      fxLayout.sm="column"
      fxLayout.xs="column"
      fxLayout.md="column"
      fxLayoutGap.md="24px"
    >
      <div *ngIf="transfereeNameDetails && transfereeNameDetails.hasOwnProperty('firstName')">
        <p class="welcome-content-text content-title">
          Welcome
          <strong>{{ transfereeNameDetails.firstName }}</strong
          >,
        </p>

        <p
          *ngIf="
            (!noTasks &&
              lumpsumDetails &&
              lumpsumDetails.hasOwnProperty('amount') &&
              lumpsumDetails?.repay &&
              lumpsumDetails.repay?.taskRequired &&
              !repayComplete &&
              lumpsumDetails?.bankTaskRequired &&
              !bankInfoComplete) ||
            (!noTasks &&
              lumpsumDetails &&
              lumpsumDetails.hasOwnProperty('amount') &&
              lumpsumDetails?.repay &&
              lumpsumDetails.repay?.taskRequired &&
              !repayComplete &&
              lumpsumDetails?.bankTaskRequired &&
              bankInfoComplete) ||
            (!noTasks && lumpsumDetails && lumpsumDetails.hasOwnProperty('amount') && lumpsumDetails?.bankTaskRequired && !bankInfoComplete)
          "
          class="welcome-content-text"
        >
          {{ 'bb.lumpsumDashboard.welcomeText.initialState' | translate }}
          <strong>{{ lumpsumDetails?.amount }} {{ lumpsumDetails?.currencyCode }}</strong
          >.<br />
          {{ 'bb.lumpsumDashboard.welcomeText.middleState' | translate }}
          <strong>{{ lumpsumDetails?.amount }} {{ lumpsumDetails?.currencyCode }}</strong>
          {{ 'bb.lumpsumDashboard.welcomeText.endState' | translate }}
        </p>

        <p
          *ngIf="
            (!paymentRequestInitiatedDate &&
              !requestLumpsum &&
              !noTasks &&
              lumpsumDetails &&
              lumpsumDetails?.repay &&
              lumpsumDetails.repay?.taskRequired &&
              repayComplete &&
              lumpsumDetails?.bankTaskRequired &&
              bankInfoComplete) ||
            (!paymentRequestInitiatedDate &&
              !requestLumpsum &&
              !noTasks &&
              lumpsumDetails &&
              lumpsumDetails?.repay &&
              lumpsumDetails.repay?.taskRequired &&
              repayComplete &&
              !lumpsumDetails?.bankTaskRequired) ||
            (!paymentRequestInitiatedDate &&
              !requestLumpsum &&
              !noTasks &&
              lumpsumDetails &&
              lumpsumDetails?.repay &&
              !lumpsumDetails.repay?.taskRequired &&
              lumpsumDetails?.bankTaskRequired &&
              bankInfoComplete)
          "
          class="welcome-content-text"
        >
          {{ 'bb.lumpsumDashboard.welcomeText.completeState' | translate }}<br />
          {{ 'bb.lumpsumDashboard.welcomeText.middleState' | translate }}
          <strong>{{ lumpsumDetails?.amount }} {{ lumpsumDetails?.currencyCode }}</strong>
          {{ 'bb.lumpsumDashboard.welcomeText.endState' | translate }}
        </p>

        <p
          *ngIf="
            !noTasks &&
            lumpsumDetails &&
            lumpsumDetails?.repay &&
            lumpsumDetails.repay?.taskRequired &&
            !repayComplete &&
            !lumpsumDetails?.bankTaskRequired &&
            clientName &&
            !paymentRequestInitiatedDate
          "
          class="welcome-content-text"
        >
          {{ 'bb.lumpsumDashboard.welcomeText.initialState' | translate }}
          <strong>{{ lumpsumDetails?.amount }} {{ lumpsumDetails?.currencyCode }}</strong
          >.<br />
          {{ 'bb.lumpsumDashboard.welcomeText.repayRequired' | translate }}
          <strong>{{ clientName }}</strong
          >.
        </p>

        <p
          *ngIf="noTasks && lumpsumDetails && lumpsumDetails.hasOwnProperty('amount') && clientName && !paymentRequestInitiatedDate"
          class="welcome-content-text"
        >
          {{ 'bb.lumpsumDashboard.welcomeText.noTasksRequiredInitialState' | translate }}
          <strong>{{ lumpsumDetails?.amount }} {{ lumpsumDetails?.currencyCode }}</strong>
          {{ 'bb.lumpsumDashboard.welcomeText.noTasksRequiredMiddleState' | translate }}<br />
          {{ 'bb.lumpsumDashboard.welcomeText.noTasksRequiredEndState' | translate }}<br />
          {{ 'bb.lumpsumDashboard.welcomeText.noTasksRepayRequired' | translate }}
          <strong>{{ clientName }}</strong
          >.
        </p>

        <p
          class="welcome-content-text"
          *ngIf="lumpsumDetails && lumpsumDetails.hasOwnProperty('payAsOfDate') && !paymentRequestInitiatedDate"
        >
          {{ 'bb.lumpsumDashboard.welcomeText.eligibilityDate' | translate }}
          <strong>{{ lumpsumDetails?.payAsOfDate }}</strong
          ><br />
          <small>{{ 'bb.lumpsumDashboard.welcomeText.payAsofDateNote' | translate }}</small>
        </p>
        <p class="welcome-content-text" *ngIf="paymentRequestInitiatedDate">
          {{ 'bb.lumpsumDashboard.welcomeText.lumpsumRequestCompletionMessage' | translate }}
          <strong>{{ lumpsumDetails?.amount }} {{ lumpsumDetails?.currencyCode }}</strong
          ><br />
        </p>
        <div *ngIf="requestLumpsum && !paymentRequestInitiatedDate && lumpsumDetails.hasOwnProperty('payAsOfDate')">
          <button
            class="req-lumpsum-btn"
            [disabled]="isCoBrowsing"
            (click)="initiateLumpsumPaymentRequest()"
            fxLayout="row"
            fxLayoutAlign="space-evenly center"
            fxLayout.sm="column"
            fxLayoutAlign.sm="center start"
            fxLayout.xs="column"
            fxLayoutAlign.xs="center start"
            role="button"
          >
            <span class="confirmed-benefits-no" role="note">{{ 'bb.lumpsumDashboard.welcomeText.requestLumpsum' | translate }}</span>
          </button>
        </div>
        <div *ngIf="paymentRequestInitiatedDate">
          <button
            class="lumpsum-req-completed"
            fxLayout="row"
            fxLayoutAlign="space-evenly center"
            fxLayout.sm="column"
            fxLayoutAlign.sm="center start"
            fxLayout.xs="column"
            fxLayoutAlign.xs="center start"
            role="button"
          >
            <span class="confirmed-benefits-no" role="note">
              <img width="8%" mat-list-icon src="../assets/images/check white.svg" alt="complete" />
              {{ 'bb.lumpsumDashboard.welcomeText.lumpsumRequested' | translate }}</span
            >
          </button>
          <p class="pay-req-date" *ngIf="paymentRequestInitiatedDate">
            {{ 'bb.lumpsumDashboard.welcomeText.lumpsumRequestMessage' | translate }}
            {{ paymentRequestInitiatedDate }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    class="tasks-section"
    fxLayout="column"
    fxLayoutAlign="flex-end"
    fxFlex="30%"
    fxFlex.md="30%"
    fxLayoutGap="16px"
    fxLayoutGap.md="14px"
    fxFlex.sm="100%"
    fxLayoutGap.sm="0px"
    fxFlex.xs="100%"
    fxLayoutGap.xs="0px"
  >
    <div
      *ngIf="lumpsumDetails && lumpsumDetails?.repay && lumpsumDetails.repay?.taskRequired"
      fxLayout="row"
      fxLayoutAlign="space-evenly stretch"
      fxLayout.sm="column"
      fxLayout.xs="column"
      fxLayout.md="column"
      fxLayoutGap.md="14px"
    >
      <div
        tabindex="0"
        class="lumpsum-tasks-button"
        [ngStyle]="isCoBrowsing ? '{cursor: none !important;}' : ''"
        (click)="goToRepaymentAgreement()"
      >
        <span *ngIf="repayComplete" class="complete-status"
          ><img class="complete-icon" mat-list-icon src="../assets/images/check-white.svg" alt="complete"
        /></span>
        <span class="btn-text"
          ><strong>{{ 'bb.lumpsumDashboard.repaymentAgreemnet' | translate }}</strong>
          <br />
          <span *ngIf="lumpsumDetails.repay?.repayAcceptedDate" class="font-12"
            >{{ 'bb.lumpsumDashboard.completedOn' | translate }}: {{ lumpsumDetails.repay?.repayAcceptedDate | date: 'dd MMM yyyy' }}</span
          >
        </span>
        <span class="nav-icon"><img class="tasks-button-arrow" src="../../../../assets/images/arrow-left.svg" alt="arrow-right"/></span>
      </div>
    </div>
    <div
      *ngIf="lumpsumDetails && lumpsumDetails?.bankTaskRequired"
      fxLayout="row"
      fxLayoutAlign="space-evenly stretch"
      fxLayout.sm="column"
      fxLayout.xs="column"
      fxLayout.md="column"
      fxLayoutGap.md="14px"
    >
      <div
        tabindex="0"
        class="lumpsum-tasks-button"
        [ngStyle]="isCoBrowsing ? '{cursor: none !important;}' : ''"
        (click)="goToBankInfoPage()"
      >
        <span *ngIf="bankInfoComplete" class="complete-status"
          ><img class="complete-icon" mat-list-icon src="../assets/images/check-white.svg" alt="complete"
        /></span>
        <span class="btn-text"
          ><strong>{{ 'bb.lumpsumDashboard.bankInfo' | translate }}</strong>
        </span>
        <span class="nav-icon"><img class="tasks-button-arrow" src="../../../../assets/images/arrow-left.svg" alt="arrow-right"/></span>
      </div>
    </div>
  </div>
</div>
